import React from "react";
import footerLogo from "../../assets/images/footerLogo.png";
import "./JobPostingFooter.css";

const JobPostingFooter = () => {
  return (
    <div className="footer-part-of-page">
      <footer id="footer-4-cols" class="site-footer">
        <div id="footer-container">
          <div id="footer-grid">
            <div id="left-footer-section" class="footer-section">
              <div class="footer-information">
                <span>
                  <img src="https://img.icons8.com/ios-filled/12/999999/marker.png" />
                  JP North, Behind Western Hotel, WEH,Mira Road (E), 401107
                </span>
                <span>
                  <img src="https://img.icons8.com/ios-filled/12/999999/phone.png" />
                  7977584476
                </span>
                <span>
                  <img src="https://img.icons8.com/ios-filled/12/999999/mail.png" />
                  <a href="mailto:info@calidtech.com">info@calidtech.com</a>
                </span>
              </div>
            </div>

            <div id="first-footer-section" class="footer-section">
              <div class="footer-tags">
                <h3>Tags</h3>
                <a href="#" class="footer-tag">
                  Anybus
                </a>
                <a href="#" class="footer-tag">
                  Teltonika
                </a>
                <a href="#" class="footer-tag">
                  Dalnex
                </a>
                <a href="#" class="footer-tag">
                  Mobus
                </a>
                <a href="#" class="footer-tag">
                  MQTT
                </a>
                <a href="#" class="footer-tag">
                  OT Security
                </a>
                <a href="#" class="footer-tag">
                  Protocol Converter
                </a>
                <a href="#" class="footer-tag">
                  Smart Metering
                </a>
                <a href="#" class="footer-tag">
                  IoT Custom Development
                </a>
                <a href="#" class="footer-tag">
                  Dell
                </a>
                <a href="#" class="footer-tag">
                  Lenovo
                </a>
                <a href="#" class="footer-tag">
                  Juniper
                </a>
                <a href="#" class="footer-tag">
                  PA
                </a>
                <a href="#" class="footer-tag">
                  HP
                </a>
                <a href="#" class="footer-tag">
                  Palo Alto
                </a>
                <a href="#" class="footer-tag">
                  Fortigate
                </a>
                <a href="#" class="footer-tag">
                  Mimosa
                </a>
                <a href="#" class="footer-tag">
                  {" "}
                  Cambium
                </a>
                <a href="#" class="footer-tag">
                  Radwin
                </a>
                <a href="#" class="footer-tag">
                  Altai Alto
                </a>
                <a href="#" class="footer-tag">
                  Data Center
                </a>
                <a href="#" class="footer-tag">
                  Wireless Planning
                </a>
                <a href="#" class="footer-tag">
                  Network Security
                </a>
                <a href="#" class="footer-tag">
                  Rujie
                </a>
                <a href="#" class="footer-tag">
                  Edgecore
                </a>
                <a href="#" class="footer-tag">
                  D-link
                </a>
                <a href="#" class="footer-tag">
                  ZTNA
                </a>
                <a href="#" class="footer-tag">
                  Routing & Switching
                </a>
                <a href="#" class="footer-tag">
                  Ubiquiti
                </a>
                <a href="#" class="footer-tag">
                  Firewall
                </a>
                <a href="#" class="footer-tag">
                  Captive Portal
                </a>
                <a href="#" class="footer-tag">
                  AAA
                </a>
                <a href="#" class="footer-tag">
                  Hotspot
                </a>
                <a href="#" class="footer-tag">
                  Enterprise Networks
                </a>
                <a href="#" class="footer-tag">
                  Radius server
                </a>
                <a href="#" class="footer-tag">
                  Servers and Storage
                </a>
              </div>
            </div>
            <div id="second-footer-section" class="footer-section">
              <div class="footer-links">
                <h3>Categories</h3>
                <ul>
                  <li>
                    <a href="#">Enterprise Solutions Products</a>
                  </li>
                  <li>
                    <a href="#">Enterprise Solutions Services</a>
                  </li>
                  <li>
                    <a href="#">Factory Digital Transformation Products</a>
                  </li>
                  <li>
                    <a href="#">Factory Digital Transformation Services</a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="right-footer-section" class="footer-section">
              <div class="footer-links">
                <h3>Solutions we offer:</h3>
                <ul>
                  <li>
                    <a href="#">Enterprise Solutions</a>
                  </li>
                  <li>
                    <a href="#">Factory Digital Transformation</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="copyright-information">
            <div id="footer-logo-section">
              <div id="footer-logo">
                <img src={footerLogo} alt="" />
              </div>
            </div>
            <div id="copyright-text">
              &copy; Calid Technologies. All rights reserved.
            </div>
            <div id="social-buttons"></div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default JobPostingFooter;

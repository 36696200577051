// EnterpriseSophosSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseSophosSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import Sophos from "../../../assets/pdf/Sophos.pdf";
import { Helmet } from 'react-helmet';


const EnterpriseSophosSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Next Gen Firewall",
  // };

  const productDataList1 = [
    //XGS136
    {
      images: [
        require("../../../assets/productImages/XGS136-1.png"),
        require("../../../assets/productImages/XGS136-2.png"),
        require("../../../assets/productImages/XGS136-1.png"),
        require("../../../assets/productImages/XGS136-2.png"),
      ],
      title: "XGS136",
      about:
        "The Sophos Firewall Xstream architecture is engineered to deliver high levels of visibility, protection, and performance to address some of the greatest challenges facing network administrators today.",
      enterpriseFeature: (
        <>
          <li>Firewall: 11,500 Mbps.</li>
          <li>TLS Inspection: 950 Mbps.</li>
          <li>IPS: 4,000 Mbps.</li>
        </>
      ),
      downloadLink: Sophos,
      hyperlinks: [],
    },

    //XGS2100
    {
      images: [
        require("../../../assets/productImages/XGS2100-1.png"),
        require("../../../assets/productImages/XGS2100-2.png"),
        require("../../../assets/productImages/XGS2100-1.png"),
        require("../../../assets/productImages/XGS2100-2.png"),
      ],
      title: "XGS2100",
      about:
        "The Sophos Firewall Xstream architecture is engineered to deliver high levels of visibility, protection, and performance to address some of the greatest challenges facing network administrators today.",
      enterpriseFeature: (
        <>
          <li>Firewall: 30,000 Mbps.</li>
          <li>TLS Inspection: 1,100 Mbps.</li>
          <li>IPS: 6,000 Mbps.</li>
        </>
      ),
      downloadLink: Sophos,
      hyperlinks: [],
    },

    //XGS2300
    {
      images: [
        require("../../../assets/productImages/XGS2300-1.png"),
        require("../../../assets/productImages/XGS2300-2.png"),
        require("../../../assets/productImages/XGS2300-1.png"),
        require("../../../assets/productImages/XGS2300-2.png"),
      ],
      title: "XGS2300",
      about:
        "The Sophos Firewall Xstream architecture is engineered to deliver high levels of visibility, protection, and performance to address some of the greatest challenges facing network administrators today.",
      enterpriseFeature: (
        <>
          <li>Firewall: 47,000 Mbps.</li>
          <li>TLS Inspection: 2,470 Mbps.</li>
          <li>IPS: 10,500 Mbps.</li>
        </>
      ),
      downloadLink: Sophos,
      hyperlinks: [],
    },

    //XGS3100
    {
      images: [
        require("../../../assets/productImages/XGS3100-1.png"),
        require("../../../assets/productImages/XGS3100-2.png"),
        require("../../../assets/productImages/XGS3100-1.png"),
        require("../../../assets/productImages/XGS3100-2.png"),
      ],
      title: "XGS3100",
      about:
        "The Sophos Firewall Xstream architecture is engineered to deliver high levels of visibility, protection, and performance to address some of the greatest challenges facing network administrators today.",
      enterpriseFeature: (
        <>
          <li>Firewall: 11,500 Mbps.</li>
          <li>TLS Inspection: 950 Mbps.</li>
          <li>IPS: 4,000 Mbps.</li>
        </>
      ),
      downloadLink: Sophos,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="sophos-enterprise-page">
      <Helmet>
                <title>CalidTech-Enterprise Sophos</title>
                <meta name="description" content="Your website description here" />
                <meta name="keywords" content="keyword1, keyword2, keyword3" />
                <meta name="author" content="Your Name or Company" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.yourwebsite.com" />
                <meta property="og:site_name" content="Your Website Name" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Your Page Title" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/EnterpriseSophosSubcategoryPage" />
            </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Network and Cybersecurity - Sophos
      </div>
      <div className="sophos-enterprise-subproduct-nav">
        <button
          className={`sophos-enterprise-subproduct-nav-button ${
            currentSubproduct === 1 ? "active-sophos-enterprise-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Next Gen Firewall
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="sophos-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="sophos-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-sophos-enterprise" : ""
                  }
                >
                  <button
                    className="sophos-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseSophosSubcategoryPage;

import React from "react";
import "./ServiceThirdSectionEnt.css";

function ServiceHeader() {
  return (
    <header className="service-header">
      <div className="service-header-text">
        <h1>
          Seamless Solutions for Streamlined Success
          <br />
          <span id="service-photography"></span>
        </h1>
        <p>
          At Calidtech, we specialize in delivering comprehensive connectivity
          and infrastructure solutions tailored to your business needs. From
          structured cabling to strategic wireless planning and advanced data
          center services, we empower organizations to achieve seamless
          connectivity and operational excellence.
        </p>
        <button className="Downloadpdf"> Learn More </button>
      </div>
    </header>
  );
}

function ServiceThirdSection() {
  return (
    <div className="ServiceApp">
      <ServiceHeader />
    </div>
  );
}

export default ServiceThirdSection;

// EnterpriseHPEArubaSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseHPEArubaSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import Aruba2930 from "../../../assets/pdf/Aruba-2930.pdf";
import Aruba3810 from "../../../assets/pdf/Aruba-3810.pdf";
import Aruba6000 from "../../../assets/pdf/Aruba-6000.pdf";
import Aruba6300 from "../../../assets/pdf/Aruba-6300.pdf";
import Aruba6400 from "../../../assets/pdf/Aruba-6400.pdf";
import AP505 from "../../../assets/pdf/AP505.pdf";
import AP515 from "../../../assets/pdf/AP515.pdf";
import AP535 from "../../../assets/pdf/AP535.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseHPEArubaSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Access Switches",
  //   2: "Core and Distribution Switch",
  //   3: "Aruba Wireless",                               
  // };

  const productDataList1 = [
    //Aruba 2930
    {
      images: [
        require("../../../assets/productImages/Aruba-2930-1.png"),
        require("../../../assets/productImages/Aruba-2930-1.png"),
        require("../../../assets/productImages/Aruba-2930-1.png"),
        require("../../../assets/productImages/Aruba-2930-1.png"),
      ],
      title: "Aruba 2930",
      about:
        "The HPE Aruba Networking 2930F Switch Series is designed for customers creating smart digital workplaces that are optimized for mobile users with an integrated wired and wireless approach.",
      enterpriseFeature: (
        <>
          <li>Simplicity built-in</li>
          <li>Security for users and IoT</li>
          <li>Unified cloud-based management</li>
        </>
      ),
      downloadLink: Aruba2930,
      hyperlinks: [],
    },

    //Aruba 3810
    {
      images: [
        require("../../../assets/productImages/Aruba-3810-1.png"),
        require("../../../assets/productImages/Aruba-3810-1.png"),
        require("../../../assets/productImages/Aruba-3810-1.png"),
        require("../../../assets/productImages/Aruba-3810-1.png"),
      ],
      title: "Aruba 3810",
      about:
        "The HPE Aruba Networking 3810 Switch series provides performance and resiliency for enterprises, SMBs, and branch office networks.HPE Aruba Networking ClearPass offers centralized security.",
      enterpriseFeature: (
        <>
          <li>Powerful Layer 3 Ethernet switching</li>
          <li>10-unit stacking</li>
          <li>Multi-gigabit Ethernet and PoE</li>
        </>
      ),
      downloadLink: Aruba3810,
      hyperlinks: [],
    },

    //Aruba 6000
    {
      images: [
        require("../../../assets/productImages/Aruba-6000-1.png"),
        require("../../../assets/productImages/Aruba-6000-1.png"),
        require("../../../assets/productImages/Aruba-6000-1.png"),
        require("../../../assets/productImages/Aruba-6000-1.png"),
      ],
      title: "Aruba 6000",
      about:
        "The HPE Aruba Networking CX 6000 Switch Series is morden family of entry level access switches ideal for branch offices, midsize businesses, and small ebterprise.",
      enterpriseFeature: (
        <>
          <li>Enterprise-class Ethernet Layer 2 connectivity</li>
          <li>PoE and built-in uplinks</li>
          <li>Quiet and compact 12 port</li>
        </>
      ),
      downloadLink: Aruba6000,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //Aruba 6300
    {
      images: [
        require("../../../assets/productImages/Aruba-6300-1.png"),
        require("../../../assets/productImages/Aruba-6300-1.png"),
        require("../../../assets/productImages/Aruba-6300-1.png"),
        require("../../../assets/productImages/Aruba-6300-1.png"),
      ],
      title: "Aruba 6300",
      about:
        "The HPE Aruba Networking CX 6300 Switch Series is a modern, flexible, and intelligent family of stackable switches ideal for enterprise network access, aggregation, core, and data center top of rack deployments.",
      enterpriseFeature: (
        <>
          <li>Speed and power for users and IoT</li>
          <li>Scalable growth made simple</li>
          <li>Flexible management</li>
        </>
      ),
      downloadLink: Aruba6300,
      hyperlinks: [],
    },

    //Aruba 6400
    {
      images: [
        require("../../../assets/productImages/Aruba-6400-1.jpg"),
        require("../../../assets/productImages/Aruba-6400-1.jpg"),
        require("../../../assets/productImages/Aruba-6400-1.jpg"),
        require("../../../assets/productImages/Aruba-6400-1.jpg"),
      ],
      title: "Aruba 6400",
      about:
        "The HPE Aruba Networking CX 6400 Switch Series is a modern, flexible and intelligent family of modular switches ideal for access, aggregation and core in enterprise campus and data center deployments.",
      enterpriseFeature: (
        <>
          <li>Frequency range: 50-60 Hz</li>
          <li>Primary airflow: Front-to-back</li>
          <li>Aruba 6400 4-post Rack Mount Kit (R0X37A)</li>
        </>
      ),
      downloadLink: Aruba6400,
      hyperlinks: [],
    },
  ];

  const productDataList3 = [
    //AP505
    {
      images: [
        require("../../../assets/productImages/AP505-1.png"),
        require("../../../assets/productImages/AP505-2.png"),
        require("../../../assets/productImages/AP505-1.png"),
        require("../../../assets/productImages/AP505-2.png"),
      ],
      title: "AP505",
      about:
        "These affordable Wi-Fi 6 access points provide high-performance connectivity for any organization experiencing growing numbers of mobile, IoT and mobility requirements.",
      enterpriseFeature: (
        <>
          <li>Frequency range: 50-60 Hz</li>
          <li>Primary airflow: Front-to-back</li>
          <li>Aruba 6400 4-post Rack Mount Kit (R0X37A)</li>
        </>
      ),
      downloadLink: AP505,
      hyperlinks: [],
    },

    //AP515
    {
      images: [
        require("../../../assets/productImages/AP515-1.png"),
        require("../../../assets/productImages/AP515-2.png"),
        require("../../../assets/productImages/AP515-1.png"),
        require("../../../assets/productImages/AP515-2.png"),
      ],
      title: "AP515",
      about:
        "HPE Aruba Networking Wi-Fi 6 access points provide high-performance connectivity for any organization experiencing growing numbers of IoT and mobility requirements.",
      enterpriseFeature: (
        <>
          <li>IoT-ready Bluetooth 5 and Zigbee support</li>
          <li>2.69 gigabit per second maximum real-world speed</li>
          <li>Wi-Fi 6 support for OFDMA, MU-MIMO, plus Client Match</li>
        </>
      ),
      downloadLink: AP515,
      hyperlinks: [],
    },

    //AP535
    {
      images: [
        require("../../../assets/productImages/AP535-1.png"),
        require("../../../assets/productImages/AP535-2.png"),
        require("../../../assets/productImages/AP535-1.png"),
        require("../../../assets/productImages/AP535-2.png"),
      ],
      title: "AP535",
      about:
        "HPE Aruba Networking Wi-Fi 6 access points provide high-performance connectivity for any organization experiencing growing numbers of IoT and mobility requirements.",
      enterpriseFeature: (
        <>
          <li>Ultimate efficiency</li>
          <li>IoT and location-ready</li>
          <li>Secure by design</li>
        </>
      ),
      downloadLink: AP535,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      case 3:
        return productDataList3;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="hPEAruba-page">
      <Helmet>
                <title>CalidTech-Enterprise HPE ARUBA</title>
                <meta name="description" content="Your website description here" />
                <meta name="keywords" content="keyword1, keyword2, keyword3" />
                <meta name="author" content="Your Name or Company" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.yourwebsite.com" />
                <meta property="og:site_name" content="Your Website Name" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Your Page Title" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://www.calidtech.com/EnterpriseHPEArubaSubcategoryPage" />
            </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Enterprise LAN and Wireless - HPE Aruba
      </div>
      <div className="hPEAruba-subproduct-nav">
        <button
          className={`hPEAruba-subproduct-nav-button ${
            currentSubproduct === 1 ? "active-hPEAruba-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Access Switches
        </button>
        <button
          className={`hPEAruba-subproduct-nav-button ${
            currentSubproduct === 2 ? "active-hPEAruba-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(2)}
        >
          Core and Distribution Switch
        </button>
        <button
          className={`hPEAruba-subproduct-nav-button ${
            currentSubproduct === 3 ? "active-hPEAruba-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(3)}
        >
          Aruba Wireless
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="hPEAruba-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-hPEAruba-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-hPEAruba" : ""}
                >
                  <button
                    className="hPEAruba-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseHPEArubaSubcategoryPage;

import React from "react";
import { Link } from "react-router-dom";
import "./EnterpriseFeaturebox.css";

function EnterpriseFeaturebox(props) {
  return (
    <div className="feature-box-link">
      <Link to={props.featureLink}>
        <div className="a-box">
          <div className="a-b-img">
            <img src={props.image} alt="" />
          </div>
          <div className="a-b-text">
            <h2>{props.title}</h2>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default EnterpriseFeaturebox;

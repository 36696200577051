// SwitchtoEnterprise.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SwitchtoEnterprise.css";

const SwitchtoEnterprise = () => {
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    const buttonTimer = setTimeout(() => {
      setShowButton((prevState) => !prevState);
    }, 9000);

    return () => clearTimeout(buttonTimer);
  }, [showButton]);

  const handleClick = () => {
    navigate("/EnterpriseLandingPage");
  };

  return (
    <div
      className={`switch-button ${showButton ? "slide-in" : "slide-out"}`}
      onClick={handleClick}
    >
      Explore Enterprise Solutions.
    </div>
  );
};

export default SwitchtoEnterprise;

import React, { useState, useEffect } from "react";
import EnterpriseBackground from "../EntertrpriseComponents/EnterpriseBackground";
import EnterpriseHero from "../EntertrpriseComponents/EnterpriseHero";
import EnterpriseFeature from "../EntertrpriseComponents/EnterpriseFeature";
import Reviews from "../../Common Components/Reviews";
import EnterpriseLandingPageCaseStudies from "../EntertrpriseComponents/EnterpriseLandingPageCaseStudies";
import SwitchtoManufacturing from "../EntertrpriseComponents/SwitchtoManufacturing";
import EnterpriseDividingHeaderPage from "../EntertrpriseComponents/EnterpriseDividingHeaderPage";
import EnterpriseFooter from "../EntertrpriseComponents/EnterpriseFooter";
import Whatsapp from "../../Common Components/Whatsapp";
import ContactUsBot from "../../Common Components/ContactUsBot";
import WhatsapPopup from "../../Common Components/WhatsapPopup";
import { Helmet } from 'react-helmet';


const EnterpriseLandingPage = () => {
  let heroData = [
    { text1: "Lorem Ipsum", text2: " is simply dummy text" },
    { text1: "Lorem Ipsum", text2: " is simply dummy text" },
    { text1: "Lorem Ipsum", text2: " is simply dummy text" },
  ];

  useEffect(() => {
    setInterval(() => {
      setHeroCount((count) => {
        return count === 2 ? 0 : count + 1;
      });
    }, 60000);
  }, []);
  const [heroCount, setHeroCount] = useState(0);
  const [playStatus, setPlayStatus] = useState(false);

  return (
    <div>
      <Helmet>
                <title>CalidTech-Enterprise Home</title>
                <meta name="description" content="Discover comprehensive solutions for Enterprise LAN Wireless, small and medium business LAN and wireless, server and storage solutions, network and cybersecurity, and Ptp and PtMp UBR. Ensure robust, secure, and efficient connectivity and infrastructure for your business with top-tier products and services." />
                <meta name="keywords" content="Lan,Wireless,servers,mimosa,altai" />
                <meta name="author" content="Calid Technologies" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="CalidTech-Enterprise Home" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.calidtech.com" />
                <meta property="og:site_name" content="Calid Technologies" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="CalidTech-Enterprise Home" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="CalidTech-Enterprise Home" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/EnterpriseLandingPage" />
            </Helmet>
      <WhatsapPopup />
      <EnterpriseBackground playStatus={playStatus} heroCount={heroCount} />
      <EnterpriseHero
        setPlayStatus={setPlayStatus}
        heroData={heroData}
        heroCount={heroCount}
        setHeroCount={setHeroCount}
        playStatus={playStatus}
      />
      <EnterpriseFeature />
      <EnterpriseLandingPageCaseStudies />
      <Reviews />
      <EnterpriseDividingHeaderPage />
      <Whatsapp />
      <EnterpriseFooter />
      <SwitchtoManufacturing />
      <ContactUsBot />
    </div>
  );
};
export default EnterpriseLandingPage;

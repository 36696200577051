// EnterpriseAAAHotspotCaptivePortalserverSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseAAAHotspotCaptivePortalserverSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import CiscoISE from "../../../assets/pdf/Cisco-ISE.pdf";
import C1000C2000C3000 from "../../../assets/pdf/C1000-C2000-C3000.pdf";
import U50 from "../../../assets/pdf/U-50.pdf";
import U100 from "../../../assets/pdf/U-100.pdf";
import U200 from "../../../assets/pdf/U-200.pdf";
import U500 from "../../../assets/pdf/U-500.pdf";
import U1000 from "../../../assets/pdf/U-1000.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import Whatsapp from "../../../Common Components/Whatsapp";
import { Helmet } from 'react-helmet';


const EnterpriseAAAHotspotCaptivePortalserverSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Clearpass",
  //   2: "Cisco",
  //   3: "Unibox",
  // };

  const productDataList1 = [
    // Aruba C1000
    {
      images: [
        require("../../../assets/productImages/Aruba-C1000.jpeg"),
        require("../../../assets/productImages/Aruba-C1000.jpeg"),
        require("../../../assets/productImages/Aruba-C1000.jpeg"),
        require("../../../assets/productImages/Aruba-C1000.jpeg"),
      ],
      title: "Aruba C1000",
      about:
        "HPE Aruba Networking’s ClearPass Policy Manager, part of the HPE Aruba Networking 360 Secure Fabric, provides role- and device-based secure network access contro.",
      enterpriseFeature: (
        <>
          <li>
            Intuitive policy configuration templates and visibility
            troubleshooting tools.
          </li>
          <li>FIPS 140-2 and CC certified.</li>
        </>
      ),
      downloadLink: C1000C2000C3000,
      hyperlinks: [],
    },

    //Aruba C2000
    {
      images: [
        require("../../../assets/productImages/Aruba-c2000-1.jpeg"),
        require("../../../assets/productImages/Aruba-c2000-1.jpeg"),
        require("../../../assets/productImages/Aruba-c2000-1.jpeg"),
        require("../../../assets/productImages/Aruba-c2000-1.jpeg"),
      ],
      title: "Aruba C2000",
      about:
        "HPE Aruba Networking's ClearPass Policy Manager, part of the HPE Aruba Networking 360 Secure Fabric, provides role- and device-based secure network access contro.",
      enterpriseFeature: (
        <>
          <li>
            • Guest access with extensive customization, branding and
            sponsor-based approvals.
          </li>
          <li>
            Supports multiple authentication/authorization sources(AD, LDAP,
            SQL).
          </li>
        </>
      ),
      downloadLink: C1000C2000C3000,
      hyperlinks: [],
    },

    //Aruba C3000
    {
      images: [
        require("../../../assets/productImages/Aruba-C3000-1.jpeg"),
        require("../../../assets/productImages/Aruba-C3000-1.jpeg"),
        require("../../../assets/productImages/Aruba-C3000-1.jpeg"),
        require("../../../assets/productImages/Aruba-C3000-1.jpeg"),
      ],
      title: "Aruba C3000",
      about:
        "HPE Aruba Networking’s ClearPass Policy Manager, part of the HPE Aruba Networking 360 Secure Fabric, provides role- and device-based secure network access contro.",
      enterpriseFeature: (
        <>
          <li>
            Single sign-on (SSO) support works with Ping, Okta and other
            identity management tools to improve user experience to SAML
            2.0-based applications.
          </li>
        </>
      ),
      downloadLink: C1000C2000C3000,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //Cisco ISE
    {
      images: [
        require("../../../assets/productImages/Cisco-ISE-1.jpeg"),
        require("../../../assets/productImages/Cisco-ISE-1.jpeg"),
        require("../../../assets/productImages/Cisco-ISE-1.jpeg"),
        require("../../../assets/productImages/Cisco-ISE-1.jpeg"),
      ],
      title: "Cisco ISE",
      about:
        "The Cisco® ISE Passive Identity Connector consolidates multiple sources of authentication data into a single source of truth. It simplifies the installation of Cisco security products.",
      enterpriseFeature: (
        <>
          <li>Harness the power of resilience.</li>
          <li>Cisco ISE 3.3 is ready for your network.</li>
          <li>Think outside the box.</li>
        </>
      ),
      downloadLink: CiscoISE,
      hyperlinks: [],
    },
  ];

  const productDataList3 = [
    //U-50
    {
      images: [
        require("../../../assets/productImages/U-50-1.png"),
        require("../../../assets/productImages/U-50-2.png"),
        require("../../../assets/productImages/U-50-1.png"),
        require("../../../assets/productImages/U-50-2.png"),
      ],
      title: "U-50",
      about:
        "UniBox U-50 comes with a captive portal engine, bandwidth controller, policy manager, URL tracker, reporting system and is designed to work seamless with WiOS Cloud hotspot manager.",
      enterpriseFeature: (
        <>
          <li>Gigabit LAN & WAN Ports.</li>
          <li>DNS Server Multi-WAN .</li>
          <li>Port Forwarding .</li>
        </>
      ),
      downloadLink: U50,
      hyperlinks: [],
    },

    //U-100
    {
      images: [
        require("../../../assets/productImages/U-100-1.png"),
        require("../../../assets/productImages/U-100-1.png"),
        require("../../../assets/productImages/U-100-1.png"),
        require("../../../assets/productImages/U-100-1.png"),
      ],
      title: "U-100",
      about:
        "UniBox U-100 comes with a captive portal engine, bandwidth controller, policy manager, URL tracker, reporting system and is designed to work seamlessly with WiOS Cloud hotspot manager.",
      enterpriseFeature: (
        <>
          <li>Multi-WAN Router.</li>
          <li>Wireless/Wired LAN Controller.</li>
          <li>DNS Server.</li>
        </>
      ),
      downloadLink: U100,
      hyperlinks: [],
    },

    //U-200
    {
      images: [
        require("../../../assets/productImages/U-200-1.png"),
        require("../../../assets/productImages/U-200-2.png"),
        require("../../../assets/productImages/U-200-1.png"),
        require("../../../assets/productImages/U-200-2.png"),
      ],
      title: "U-200",
      about:
        "UniBox U-200 is an all-in-one controller appliance that combines the functions of a wireless LAN controller, hotspot gateway, AAA server, web server, load balancer, web logger in a single box.",
      enterpriseFeature: (
        <>
          <li>Group-based Routing.</li>
          <li>Network Monitoring.</li>
          <li>Gigabit LAN & WAN Ports.</li>
        </>
      ),
      downloadLink: U200,
      hyperlinks: [],
    },

    //U-500
    {
      images: [
        require("../../../assets/productImages/U-500-1.png"),
        require("../../../assets/productImages/U-500-2.png"),
        require("../../../assets/productImages/U-500-1.png"),
        require("../../../assets/productImages/U-500-2.png"),
      ],
      title: "U-500",
      about:
        "UniBox U-500 is an all-in-one controller appliance that combines the functions of a wireless LAN controller, hotspot gateway, AAA server, web server, load balancer and firewall in a single box.",
      enterpriseFeature: (
        <>
          <li>Bandwidth Rate Limiting.</li>
          <li>Dynamic Bandwidth Allocation.</li>
          <li>Group-based Policies.</li>
        </>
      ),
      downloadLink: U500,
      hyperlinks: [],
    },

    //U-1000
    {
      images: [
        require("../../../assets/productImages/U-1000-1.png"),
        require("../../../assets/productImages/U-1000-2.png"),
        require("../../../assets/productImages/U-1000-1.png"),
        require("../../../assets/productImages/U-1000-2.png"),
      ],
      title: "U-1000",
      about:
        "UniBox U-1000 is an all-in-one controller appliance that combines the functions of a wireless LAN controller, hotspot gateway, AAA server, web server, load balancer and firewall in a single box.",
      enterpriseFeature: (
        <>
          <li>Gigabit LAN & WAN Ports.</li>
          <li>Template-based Captive Portals.</li>
          <li>On-board Portal Server.</li>
        </>
      ),
      downloadLink: U1000,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      case 3:
        return productDataList3;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="aAAHotspotCaptivePortalserver-enterprise-page">
      <Helmet>
                <title>CalidTech-Enterprise AAA Hotspot Captive Portal server</title>
                <meta name="description" content="Your website description here" />
                <meta name="keywords" content="keyword1, keyword2, keyword3" />
                <meta name="author" content="Your Name or Company" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.yourwebsite.com" />
                <meta property="og:site_name" content="Your Website Name" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Your Page Title" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/EnterpriseAAAHotspotCaptivePortalserverSubcategoryPage" />
            </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Network and Cybersecurity - Captive Portal Server
      </div>
      <div className="aAAHotspotCaptivePortalserver-enterprise-subproduct-nav">
        <button
          className={`aAAHotspotCaptivePortalserver-enterprise-subproduct-nav-button ${
            currentSubproduct === 1
              ? "active-aAAHotspotCaptivePortalserver-enterprise-subproduct"
              : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Clearpass
        </button>
        <button
          className={`aAAHotspotCaptivePortalserver-enterprise-subproduct-nav-button ${
            currentSubproduct === 2
              ? "active-aAAHotspotCaptivePortalserver-enterprise-subproduct"
              : ""
          }`}
          onClick={() => switchSubproduct(2)}
        >
          Cisco
        </button>
        <button
          className={`aAAHotspotCaptivePortalserver-enterprise-subproduct-nav-button ${
            currentSubproduct === 3
              ? "active-aAAHotspotCaptivePortalserver-enterprise-subproduct"
              : ""
          }`}
          onClick={() => switchSubproduct(3)}
        >
          Unibox
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="aAAHotspotCaptivePortalserver-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="aAAHotspotCaptivePortalserver-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1
                      ? "active-aAAHotspotCaptivePortalserver-enterprise"
                      : ""
                  }
                >
                  <button
                    className="aAAHotspotCaptivePortalserver-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseAAAHotspotCaptivePortalserverSubcategoryPage;

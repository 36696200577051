import React from "react";
import "./EnterpriseBackground.css";
import video from "../../assets/videos/entvideo.mp4";
import EnterpriseLanding from "../../assets/images/EnterpriseLandingPage.jpg";
import EnterpriseLanding2 from "../../assets/images/connection-bond-connect-networking-togetherness-concept.jpg";
import EnterpriseLanding3 from "../../assets/images/saas-concept-collage.jpg";

const EnterpriseBackground = ({ playStatus, heroCount }) => {
  if (playStatus) {
    return (
      <video className="ManufacturingBackground fade-in" autoPlay muted>
        <source src={video} type="video/mp4" />
      </video>
    );
  } else if (heroCount === 0) {
    return (
      <img
        src={EnterpriseLanding}
        className="ManufacturingBackground fade-in"
        alt=""
      />
    );
  } else if (heroCount === 1) {
    return (
      <img
        src={EnterpriseLanding2}
        className="ManufacturingBackground fade-in"
        alt=""
      />
    );
  } else if (heroCount === 2) {
    return (
      <img
        src={EnterpriseLanding3}
        className="ManufacturingBackground fade-in"
        alt=""
      />
    );
  }
};

export default EnterpriseBackground;

import React from "react";
import EntContactComponent from "../EntertrpriseComponents/EntContactComponent";
import EnterpriseHeader from "../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../EntertrpriseComponents/EnterpriseFooter";
import Whatsapp from "../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


function EnterpriseContactUs() {
  return (
    <div>
      <Helmet>
                <title>CalidTech-Contact Us</title>
                <meta name="description" content="Your website description here" />
                <meta name="keywords" content="keyword1, keyword2, keyword3" />
                <meta name="author" content="Your Name or Company" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.yourwebsite.com" />
                <meta property="og:site_name" content="Your Website Name" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Your Page Title" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/EnterpriseContactUs" />
            </Helmet>
      <EnterpriseHeader />
      <EntContactComponent />
      <EnterpriseFooter />
      <SwitchtoManufacturing />
      <Whatsapp />
    </div>
  );
}

export default EnterpriseContactUs;

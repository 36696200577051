import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import ManufacturingBackground from "../ManufacturingComponents/ManufacturingBackground";
import ManufacturingHero from "../ManufacturingComponents/ManufacturingHero";
import Feature from "../ManufacturingComponents/Feature";
import Whatsapp from "../../Common Components/Whatsapp";
import Reviews from "../../Common Components/Reviews";
import LandingPageCaseStudies from "../ManufacturingComponents/LandingPageCaseStudies";
import SwitchtoEnterprise from "../ManufacturingComponents/SwitchtoEnterprise";
import ManufacturingDividingHeaderPage from "../ManufacturingComponents/ManufacturingDividingHeaderPage";
import ManufacturingFooter from "../ManufacturingComponents/ManufacturingFooter";
import ContactUsBot from "../../Common Components/ContactUsBot";
import WhatsapPopup from "../../Common Components/WhatsapPopup";

const ManufacturingLandingPage = () => {
  let heroData = [
    { text1: "Lorem Ipsum", text2: " is simply dummy text" },
    { text1: "Lorem Ipsum", text2: " is simply dummy text" },
    { text1: "Lorem Ipsum", text2: " is simply dummy text" },
  ];

  useEffect(() => {
    setInterval(() => {
      setHeroCount((count) => {
        return count === 2 ? 0 : count + 1;
      });
    }, 3000);
  }, []);
  const [heroCount, setHeroCount] = useState(0);
  const [playStatus, setPlayStatus] = useState(false);

  return (
    <div>
      <Helmet>
        <title>CalidTech - FDT Home</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        {/* <meta property="fb:app_id" content="Your Facebook App ID" /> */}

        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        {/* <meta name="twitter:title" content="Calid Technologies" /> */}
        {/* <meta name="twitter:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." /> */}
        {/* <meta name="twitter:image" content="https://calidtech.com/twitter-image.jpg" /> */}
        {/* <meta name="twitter:site" content="@CalidTech" /> */}
        {/* <meta name="twitter:creator" content="@CalidTech" /> */}

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        {/* <link rel="icon" href={favicon} /> */}
        {/* <link rel="apple-touch-icon" href="/apple-touch-icon.png" /> */}
        {/* <link rel="manifest" href={manifest} /> */}
        <link rel="canonical" href="https://calidtech.com/ManufacturingLandingPage" />
      </Helmet>


      <WhatsapPopup />
      <ManufacturingBackground playStatus={playStatus} heroCount={heroCount} />
      <ManufacturingHero
        setPlayStatus={setPlayStatus}
        heroData={heroData}
        heroCount={heroCount}
        setHeroCount={setHeroCount}
        playStatus={playStatus}
      />
      <Feature />
      <LandingPageCaseStudies />
      <Reviews />
      <ManufacturingDividingHeaderPage />
      <Whatsapp />
      <ManufacturingFooter />
      <SwitchtoEnterprise />
      <ContactUsBot />
    </div>
  );
};
export default ManufacturingLandingPage;

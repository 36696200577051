// EnterpriseLANAndWirelessCategoryPage.js

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./EnterpriseLANAndWirelessCategoryPage.css";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import heroprod from "../../../assets/images/LANW.jpg";
import heroprod1 from "../../../assets/images/LANQR.jpg";
import routersImage from "../../../assets/images/juniper2.png";
import switchesImage from "../../../assets/images/hpe.png";
import accessPointsImage from "../../../assets/images/rukus23.jpg";
import cisco from "../../../assets/images/cisco.jpg";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseSubCategory from "../../EntertrpriseComponents/EnterpriseSubCategory";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseLANAndWirelessCategoryPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const subcategories = [
    {
      name: "Juniper/Mist",
      image: routersImage,
      path: "/EnterpriseJuniperMistSubcategoryPage",
      description: "",
    },
    {
      name: "HPE/Aruba",
      image: switchesImage,
      path: "/EnterpriseHPEArubaSubcategoryPage",
      description: "",
    },
    {
      name: "Ruckus Networks",
      image: accessPointsImage,
      path: "/EnterpriseRuckusSubcategoryPage",
      description: "",
    },
    {
      name: "Cisco",
      image: cisco,
      path: "/EnterpriseCiscoSubcategoryPage",
      description: "",
    },
  ];

  return (
    
    <div className="enterprise-category">
      <Helmet>
                <title>CalidTech-Enterprise LAN and Wireless</title>
                <meta name="description" content="Explore CalidTech's Enterprise LAN and Wireless solutions featuring top brands like Cisco, Ruckus Networks, HPE/Aruba, and Juniper/Mist. Our services include scalable LAN design and advanced wireless networks tailored to enhance your business connectivity and performance" />
                <meta name="keywords" content="Scalable LAN and Wireless Systems,Business Connectivity Solutions,Advanced Wireless Networks,LAN Design and Deployment,Juniper Mist Wireless,HPE Aruba Networking,HPE Aruba Networking,Ruckus Networks Wireless,Cisco LAN Products,Wireless Network Solutions.Enterprise LAN Solutions, Wifi in Mumbai , Wireless network in Mumbai" />
                <meta name="author" content="Calid Technologies"/>
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Enterprise LAN and Wireless" />
                <meta property="og:description" content="Explore CalidTech's Enterprise LAN and Wireless solutions featuring top brands like Cisco, Ruckus Networks, HPE/Aruba, and Juniper/Mist. Our services include scalable LAN design and advanced wireless networks tailored to enhance your business connectivity and performance" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://calidtech.com/" />
                <meta property="og:site_name" content="Calid Technologies" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Enterprise LAN Wireless" />
                <meta name="twitter:description" content="Explore CalidTech's Enterprise LAN and Wireless solutions featuring top brands like Cisco, Ruckus Networks, HPE/Aruba, and Juniper/Mist. Our services include scalable LAN design and advanced wireless networks tailored to enhance your business connectivity and performance" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Enterprise lan and Wireless" />
                <meta itemprop="description" content="Explore CalidTech's Enterprise LAN and Wireless solutions featuring top brands like Cisco, Ruckus Networks, HPE/Aruba, and Juniper/Mist. Our services include scalable LAN design and advanced wireless networks tailored to enhance your business connectivity and performance" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
                <meta name="application-name" content="Calid Technolgies" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/EnterpriseLANAndWirelessCategoryPage" />
            </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Enterprise LAN and Wireless Products
      </div>
      <div className="container-enterprise mt-5">
        <Slider {...settings}>
          <div>
            <img
              src={heroprod}
              alt="Slide 1"
              style={{ width: "100%", display: "block" }}
            />
          </div>
          <div>
            <img
              src={heroprod1}
              alt="Slide 2"
              style={{ width: "100%", display: "block" }}
            />
          </div>
        </Slider>
        <br></br>
        <br></br>
        <h1 className="enterprise-category-heading">
          <div class="main-heading-entire-site">
            Enterprise LAN and Wireless Products
          </div>
        </h1>
        <p className="enterprise-category-information">
          In today's fast-paced business environment, a robust and reliable
          network infrastructure is crucial. Our Enterprise LAN and Wireless
          solutions, featuring top-tier products from Juniper, Aruba, Ruckus,
          and Cisco, are designed to meet the diverse needs of modern
          enterprises, ensuring seamless connectivity, enhanced security, and
          superior performance.
        </p>
        <h2 className="enterprise-subcategory-heading">
          <div class="main-heading-entire-site">
            <div class="main-heading-entire-site">Subcategories</div>
          </div>
        </h2>
        <EnterpriseSubCategory subcategories={subcategories} />
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseLANAndWirelessCategoryPage;

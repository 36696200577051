import React from "react";
import "./ServiceThirdSection.css";

function ServiceHeader() {
  return (
    <header className="service-manu">
      <div className="service-manu-text">
        <h1>
          Seamless Solutions for Streamlined Success
          <br />
          <span id="service-photography"> </span>
        </h1>
        <p>
          At Calidtech, we specialize in revolutionizing business operations
          through innovative technologies. From optimizing warehouse efficiency
          to enhancing wireless connectivity and harnessing the power of IoT, we
          empower businesses to thrive in today's digital landscape.
        </p>
        <button> Learn More </button>
      </div>
    </header>
  );
}

function ServiceThirdSection() {
  return (
    <div className="ServiceApp">
      <ServiceHeader />
    </div>
  );
}

export default ServiceThirdSection;

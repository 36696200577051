import React from "react";
import { Link } from "react-router-dom";
import "./EnterpriseFooter.css";
import footerLogo from "../../assets/images/footerLogo.png";

const EnterpriseFooter = () => {
  return (
    <div>
      <footer id="footer-4-cols" class="site-footer">
        <div id="footer-container">
          <div id="footer-grid">
            <div id="left-footer-section" class="footer-section">
              <div class="footer-information">
                <span>
                  <img src="https://img.icons8.com/ios-filled/12/999999/marker.png" />
                  JP North, Behind Western Hotel, WEH,Mira Road (E), 401107
                </span>
                <span>
                  <img src="https://img.icons8.com/ios-filled/12/999999/phone.png" />
                  7977584476
                </span>
                <span>
                  <img src="https://img.icons8.com/ios-filled/12/999999/mail.png" />
                  <a href="mailto:info@calidtech.com">info@calidtech.com</a>
                </span>
              </div>
            </div>

            <div id="first-footer-section" class="footer-section">
              <div class="footer-tags">
                <h3>Tags</h3>
                <a href="#" class="footer-tag">
                  Dell
                </a>
                <a href="#" class="footer-tag">
                  Lenovo
                </a>
                <a href="#" class="footer-tag">
                  Juniper
                </a>
                <a href="#" class="footer-tag">
                  PA
                </a>
                <a href="#" class="footer-tag">
                  Fortigate
                </a>
                <a href="#" class="footer-tag">
                  Mimosa
                </a>
                <a href="#" class="footer-tag">
                  Cisco
                </a>
                <a href="#" class="footer-tag">
                  {" "}
                  Cisco and Meraki
                </a>
                <a href="#" class="footer-tag">
                  Juniper-Mist
                </a>
                <a href="#" class="footer-tag">
                  {" "}
                  HPE-Aruba
                </a>
                <a href="#" class="footer-tag">
                  Ruckus
                </a>
                <a href="#" class="footer-tag">
                  HPE
                </a>
                <a href="#" class="footer-tag">
                  Palo Alto
                </a>
                <a href="#" class="footer-tag">
                  Fortigate
                </a>
                <a href="#" class="footer-tag">
                  Mimosa
                </a>
                <a href="#" class="footer-tag">
                  {" "}
                  Cambium
                </a>
                <a href="#" class="footer-tag">
                  Radwin
                </a>
                <a href="#" class="footer-tag">
                  Altai Technologies
                </a>
                <a href="#" class="footer-tag">
                  Data Center
                </a>
                <a href="#" class="footer-tag">
                  Wireless Planning
                </a>
                <a href="#" class="footer-tag">
                  Network Security
                </a>
                <a href="#" class="footer-tag">
                  Rujie
                </a>
                <a href="#" class="footer-tag">
                  Edgecore
                </a>
                <a href="#" class="footer-tag">
                  D-link
                </a>
                <a href="#" class="footer-tag">
                  ZTNA
                </a>
                <a href="#" class="footer-tag">
                  Routing & Switching
                </a>
                <a href="#" class="footer-tag">
                  Ubiquiti
                </a>
                <a href="#" class="footer-tag">
                  Firewall
                </a>
                <a href="#" class="footer-tag">
                  Captive Portal
                </a>
                <a href="#" class="footer-tag">
                  AAA
                </a>
                <a href="#" class="footer-tag">
                  Hotspot
                </a>
                <a href="#" class="footer-tag">
                  Enterprise Networks
                </a>
                <a href="#" class="footer-tag">
                  Radius server
                </a>
                <a href="#" class="footer-tag">
                  Servers and Storage
                </a>
              </div>
            </div>
            <div id="second-footer-section" class="footer-section">
              <div class="footer-links">
                <h3>Categories</h3>
                <ul>
                  <li>
                    <a href="#">
                      <Link to="/EnterpriseLANAndWirelessCategoryPage">
                        Enterprise LAN and Wireless
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link to="/EnterpriseServerAndStorageSolutionCategoryPage">
                        Server Storage Solutions
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link to="/EnterpriseNetworkAndCybersecurityCategoryPage">
                        Network and Cyber Security
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link to="/EnterprisePTPAndPtMPUBRCategoryPage">
                        Fixed Wireless Broadband
                      </Link>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="right-footer-section" class="footer-section">
              <div class="footer-links">
                <h3>Information</h3>
                <ul>
                  <li>
                    <a href="#">
                      <Link className="a-link" to="/EnterpriseLandingPage">
                        Home
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link className="a-link" to="/EnterpriseProductPage">
                        Products
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link className="a-link" to="/EnterpriceServicePage">
                        Services
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link className="a-link" to="/EnterpriseCaseStudyPage">
                        Case Studies
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link className="a-link" to="/JobBoard">
                        Job Openings
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link to="/EnterpriseContactUs">Leave a Feedback</Link>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="copyright-information">
            <div id="footer-logo-section">
              <div id="footer-logo">
                <img src={footerLogo} alt="" />
              </div>
            </div>
            <div id="copyright-text">
              &copy; Calid Technologies. All rights reserved.
            </div>
            <div id="social-buttons"></div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default EnterpriseFooter;

// EnterprisePaloAltoSubcategoryPage.js

import React, { useState } from "react";
import "./EnterprisePaloAltoSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import PA220 from "../../../assets/pdf/PA220.pdf";
import PA440 from "../../../assets/pdf/PA440.pdf";
import PA450R from "../../../assets/pdf/PA450R.pdf";
import PA1410 from "../../../assets/pdf/PA1410.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterprisePaloAltoSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Next Gen Firewall",
  // };

  const productDataList1 = [
    //PA-220
    {
      images: [
        require("../../../assets/productImages/PA220-1.png"),
        require("../../../assets/productImages/PA220-1.png"),
        require("../../../assets/productImages/PA220-1.png"),
        require("../../../assets/productImages/PA220-1.png"),
      ],
      title: "PA-220",
      about:
        "Palo Alto Networks Prisma® Access protects hybrid workforces with the superior security of ZTNA 2.0 while providing exceptional user experiences from a simple, unified security product.",
      enterpriseFeature: (
        <>
          <li>Scalable security solutions tailored for smaller networks.</li>
          <li>User-friendly interface.</li>
          <li>Centralized management capabilities.</li>
        </>
      ),
      downloadLink: PA220,
      hyperlinks: [],
    },

    //PA-440
    {
      images: [
        require("../../../assets/productImages/PA440-1.png"),
        require("../../../assets/productImages/PA440-1.png"),
        require("../../../assets/productImages/PA440-1.png"),
        require("../../../assets/productImages/PA440-1.png"),
      ],
      title: "PA-440",
      about:
        "The Palo Alto Networks PA-400 Series Next-Generation Firewalls, bring ML-Powered NGFW capabilities to distributed enterprise branch offices, retail locations, and midsize businesses.",
      enterpriseFeature: (
        <>
          <li>World's first ML-Powered NGFW.</li>
          <li>Offers security in a desktop form factor.</li>
          <li>Prevents Malicious Activity Concealed in Encrypted Traffic.</li>
        </>
      ),
      downloadLink: PA440,
      hyperlinks: [],
    },

    //PA-450R
    {
      images: [
        require("../../../assets/productImages/PA450R-1.png"),
        require("../../../assets/productImages/PA450R-1.png"),
        require("../../../assets/productImages/PA450R-1.png"),
        require("../../../assets/productImages/PA450R-1.png"),
      ],
      title: "PA-450R",
      about:
        "The Palo Alto Networks PA-400R Series consists of ruggedized ML-Powered Next-Generation Firewall that bring next-generation capabilities to industrial applications in harsh environments.",
      enterpriseFeature: (
        <>
          <li>
            Eleven-time Leader in the Gartner Magic Quadrant for Network
            Firewalls.
          </li>
          <li>World’s first ruggedized ML-Powered NGFW.</li>
        </>
      ),
      downloadLink: PA450R,
      hyperlinks: [],
    },

    //PA-1410
    {
      images: [
        require("../../../assets/productImages/PA1410-1.png"),
        require("../../../assets/productImages/PA1410-1.png"),
        require("../../../assets/productImages/PA1410-1.png"),
        require("../../../assets/productImages/PA1410-1.png"),
      ],
      title: "PA-1410",
      about:
        "The controlling element of the PA-1400 Series is PAN-OS®, the same software that runs all Palo Alto Networks NGFWs. PAN-OS natively classifies all traffic, inclusive of applications, threats, and content.",
      enterpriseFeature: (
        <>
          <li>Native Web Proxy Support for the Next-Generation Firewall.</li>
          <li>Offers Centralized Management and Visibility.</li>
          <li>Prevents Malicious Activity Concealed in Encrypted Traffic.</li>
        </>
      ),
      downloadLink: PA1410,
      hyperlinks: [],
    },

    //PA1420
    {
      images: [
        require("../../../assets/productImages/PA1420-1.png"),
        require("../../../assets/productImages/PA1420-1.png"),
        require("../../../assets/productImages/PA1420-1.png"),
        require("../../../assets/productImages/PA1420-1.png"),
      ],
      title: "PA-1420",
      about:
        "The controlling element of the PA-1400 Series is PAN-OS®, the same software that runs all Palo Alto Networks NGFWs. PAN-OS natively classifies all traffic, inclusive of applications, threats, and content.",
      enterpriseFeature: (
        <>
          <li>Native Web Proxy Support for the Next-Generation Firewall.</li>
          <li>Offers Centralized Management and Visibility.</li>
          <li>Prevents Malicious Activity Concealed in Encrypted Traffic.</li>
        </>
      ),
      downloadLink: PA1410,
      hyperlinks: [],
    },

    //PA-460
    {
      images: [
        require("../../../assets/productImages/PA460-1.png"),
        require("../../../assets/productImages/PA460-1.png"),
        require("../../../assets/productImages/PA460-1.png"),
        require("../../../assets/productImages/PA460-1.png"),
      ],
      title: "PA-460",
      about:
        "The Palo Alto Networks PA-400 Series Next-Generation Firewalls, bring ML-Powered NGFW capabilities to distributed enterprise branch offices, retail locations, and midsize businesses.",
      enterpriseFeature: (
        <>
          <li>World's first ML-Powered NGFW.</li>
          <li>Offers security in a desktop form factor.</li>
          <li>Prevents Malicious Activity Concealed in Encrypted Traffic.</li>
        </>
      ),
      downloadLink: PA440,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="paloAltoEnt-enterprise-page">
      <Helmet>
                <title>CalidTech-Enterprise Palo Alto</title>
                <meta name="description" content="Your website description here" />
                <meta name="keywords" content="keyword1, keyword2, keyword3" />
                <meta name="author" content="Your Name or Company" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.yourwebsite.com" />
                <meta property="og:site_name" content="Your Website Name" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Your Page Title" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/EnterprisePaloAltoSubcategoryPage" />
            </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Network and Cybersecurity - Palo Alto
      </div>
      <div className="paloAltoEnt-enterprise-subproduct-nav">
        <button
          className={`paloAltoEnt-enterprise-subproduct-nav-button ${
            currentSubproduct === 1
              ? "active-paloAltoEnt-enterprise-subproduct"
              : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Next Gen Firewall
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="paloAltoEnt-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="paloAltoEnt-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-paloAltoEnt-enterprise" : ""
                  }
                >
                  <button
                    className="paloAltoEnt-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterprisePaloAltoSubcategoryPage;

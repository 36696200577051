import React from "react";
import "./ProductMainEnt.css";
import ICT from "../../assets/pdf/ICT.pdf";

function ProductMain() {
  return (
    <header className="ent-header">
      <div className="service-header-text">
        <h1>
          Cutting Edge Solutions
          <br />
          <span id="service-photography"> </span>
        </h1>
        <p>
          Explore our wide range of enterprise-grade solutions designed to
          optimize your network infrastructure and cybersecurity. From robust
          LAN and wireless technologies to scalable server and storage
          solutions, we provide the tools you need to enhance connectivity,
          streamline data management, and fortify your network against cyber
          threats.
        </p>
        <a
          href={ICT}
          download="ICT.pdf"
          className="prodbut center Downloadpdf"
        >
          Learn More{" "}
        </a>
        {/* <button className="prodbut center">Get PDF</button> */}
      </div>
    </header>
  );
}

function Productmain() {
  return (
    <div className="ServiceApp">
      <ProductMain />
    </div>
  );
}

export default ProductMain;

//ManufacturingWarehouseManagementSubservicePage.js

import React, { useState } from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingWarehouseManagementSubservicePage.css";
import ManufacturingServicePageNavBar from "../ManufacturingServicePageNavBar";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import InventoryTracking from "../../../assets/images/Inventory-Tracking.jfif";
import OrderFulfillment from "../../../assets/images/Order-Fulfillment.jpg";
import SpaceOptimization from "../../../assets/images/Space-Optimization.jpg";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Warehouse from "../../../assets/pdf/InventoryManagementUsingRFIDinWarehouse.docx";
import EnquiryPopup from "../../../Common Components/EnquiryPopup";

function ManufacturingWarehouseManagementSubservicePage() {
  const toggleEnquiryPopup = () => {
    setIsEnquiryPopupOpen(!isEnquiryPopupOpen);
  };
  const [isEnquiryPopupOpen, setIsEnquiryPopupOpen] = useState(false);

  return (
    <div className="manufacturing-service-page">

      <Helmet>
        <title>CalidTech - FDT Warehouse Management</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        {/* <meta property="fb:app_id" content="Your Facebook App ID" /> */}

        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        {/* <meta name="twitter:title" content="Calid Technologies" /> */}
        {/* <meta name="twitter:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." /> */}
        {/* <meta name="twitter:image" content="https://calidtech.com/twitter-image.jpg" /> */}
        {/* <meta name="twitter:site" content="@CalidTech" /> */}
        {/* <meta name="twitter:creator" content="@CalidTech" /> */}

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        {/* <link rel="icon" href={favicon} /> */}
        {/* <link rel="apple-touch-icon" href="/apple-touch-icon.png" /> */}
        {/* <link rel="manifest" href={manifest} /> */}
        <link rel="canonical" href="https://calidtech.com/ManufacturingWarehouseManagementSubservicePage" />
      </Helmet>

      <ManufacturingHeader />
      <ManufacturingServicePageNavBar />
      <header className="subservice-header-warehouse">
        <div className="subheader-text">
          <h1 className="subservice-header-heading">
            <span id="subservice-header-subheading">
              {" "}
              Warehouse Management{" "}
            </span>
          </h1>
          <p className="subservice-header-description">
            Warehouse management involves the systematic planning, organization,
            and control of all aspects related to the efficient operation of a
            warehouse or distribution center.
          </p>
          <div className="button-container">
            <a
              href={Warehouse}
              download="InventoryManagementUsingRFIDinWarehouse.docx"
              className="Downloadpdf"
            >
              Get PDF
            </a>
            <button onClick={toggleEnquiryPopup} className="servicesenquiry">
              Enquiry
            </button>
          </div>
        </div>
      </header>
      {/* header end */}

      <div className="manufacturing-servive-des">
        <h2 className="main-heading-entire-site">
          Inventory Optimization, Process Streamlining, Technology Integration
        </h2>
        <p>
          Implementing strategies and tools to ensure the right amount of stock
          is available to meet customer demand while minimizing holding costs
          and reducing waste.Simplifying and improving business processes to
          enhance efficiency, reduce costs, and eliminate bottlenecks, leading
          to faster and more effective operations.Combining various
          technological systems and software to work seamlessly together,
          improving data flow, enhancing productivity, and enabling better
          decision-making.
        </p>
      </div>

      <div className="subservice-card-div-container">
        <div className="subservice-card-div" style={{ "--clr": "#009688" }}>
          <div className="subservice-card-img-box">
            <img src={InventoryTracking} alt="Inventory Tracking" />
          </div>
          <div className="subservice-card-div-content">
            <h2 className="subservice-card-div-heading">Inventory Tracking</h2>
            <p className="subservice-card-div-description">
              Utilizing technology to monitor the movement and status of goods
              within the warehouse, enabling accurate inventory counts and
              efficient stock management.
            </p>
          </div>
        </div>
        <div className="subservice-card-div" style={{ "--clr": "#FF3E7F" }}>
          <div className="subservice-card-img-box">
            <img src={OrderFulfillment} alt="Order Fulfillment" />
          </div>
          <div className="subservice-card-div-content">
            <h2 className="subservice-card-div-heading">Order Fulfillment</h2>
            <p className="subservice-card-div-description">
              Streamlining the process of picking, packing, and shipping orders
              to ensure timely and accurate delivery to customers, optimizing
              customer satisfaction and loyalty.
            </p>
          </div>
        </div>
        <div className="subservice-card-div" style={{ "--clr": "#03A9F4" }}>
          <div className="subservice-card-img-box">
            <img src={SpaceOptimization} alt="Space Optimization" />
          </div>
          <div className="subservice-card-div-content">
            <h2 className="subservice-card-div-heading">Space Optimization</h2>
            <p className="subservice-card-div-description">
              Maximizing the use of available warehouse space through strategic
              layout design, shelving systems, and storage solutions, minimizing
              wastage and increasing storage capacity.
            </p>
          </div>
        </div>
        <EnquiryPopup
          isOpen={isEnquiryPopupOpen}
          onClose={toggleEnquiryPopup}
        />
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
}

export default ManufacturingWarehouseManagementSubservicePage;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logowithoutbg from "../../assets/images/logo_bgremove.png";
import "./JobPostingHeader.css";

const JobPostingHeader = ({ siteName }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isTop = scrollTop < 100;
      setIsScrolled(!isTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="">
      <div className={`small-header ${isScrolled ? "scrolled" : ""}`}>
        <p>Job Board</p>
      </div>

      <nav className={`custom-header-unique ${isScrolled ? "scrolled" : ""}`}>
        <input type="checkbox" id="nav-toggle-unique" />
        <div className="logo-unique">
          <Link to="/JobBoard">
            <img className="header-logo-withoutbg" src={logowithoutbg} />{" "}
          </Link>
        </div>
        <div className="site-banner">{siteName}</div>
        <ul className="links-unique">
          <li>
            <a href="#home-unique">
              <Link className="a-link" to="/EnterpriselandingPage">
                Enterprise Solutions
              </Link>
            </a>
          </li>
          <li>
            <a href="#work-unique">
              <Link className="a-link" to="/ManufacturinglandingPage">
                Factory Digital Transformation Solutions
              </Link>
            </a>
          </li>
          <li>
            <a>
              <Link className="a-link" to="/JobBoard" id="BackToJobBoard">
                Back to Job Board
              </Link>
            </a>
          </li>
        </ul>

        <label htmlFor="nav-toggle-unique" className="icon-burger-unique">
          <div className="line-unique"></div>
          <div className="line-unique"></div>
          <div className="line-unique"></div>
        </label>
      </nav>

      {/* Header end */}
    </div>
  );
};

export default JobPostingHeader;

import React from "react";
import "./ProductCategory.css";
import { Link } from "react-router-dom";

const ProductCategory = () => {
  return (
    <div className="manuprodcategory">
      <br></br>
      <br></br>
      <h1 className="manuprodheadline" style={{ color: "white" }}>
        <div className="main-heading-entire-site solution-offering">Solutions Offering</div>
      </h1>
      <div className="wrapper">
        <div className="card">
          <h3 className="card-title1">Industrial Edge Connectivity</h3>
          <br></br>
          <p className="card-content">
            Our protocol converter is an essential solution designed to
            facilitate seamless data translation and integration between various
            communication protocols.
          </p>
          <button className="card-btn">
            <Link
              to="/ManufacturingIndustrialEdgeConnectivityCategoryPage"
              style={{ color: "white" }}
            >
              Explore
            </Link>
          </button>
        </div>
        <div className="card">
          <h3 className="card-title1">Industrial Communication</h3>
          <br></br>
          <p className="card-content">
            Our industrial communication and computing solutions offer robust
            connectivity , automation & operational efficiency across diverse.{" "}
          </p>
          <button className="card-btn">
            <Link
              to="/ManufacturingIndustrialCommunicationAndComputingCategoryPage"
              style={{ color: "white" }}
            >
              Explore
            </Link>
          </button>
        </div>
        <div className="card">
          <h3 className="card-title1">Analytics</h3>
          <br></br>
          <p className="card-content">
            Unlock the power of data with our advanced analytics solutions. By
            harnessing sophisticated algorithms and machine learning techniques.
          </p>
          <button className="card-btn">
            <Link
              to="/ManufacturingAnalyticsCategoryPage"
              style={{ color: "white" }}
            >
              Explore
            </Link>
          </button>
        </div>
        <div className="card">
          <h3 className="card-title1">OT Security</h3>
          <br></br>
          <p className="card-content">
            Protect your critical infrastructure and operational assets with our
            comprehensive OT security solutions. Designed for industrial
            environments.
          </p>
          <button className="card-btn" style={{ color: "white" }}>
            <Link
              to="/ManufacturingOTSecurityCategoryPage"
              style={{ color: "white" }}
            >
              Explore
            </Link>
          </button>
        </div>
        <br></br>
      </div>
    </div>
  );
};

export default ProductCategory;

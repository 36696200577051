import React from "react";

const DownloadButton = ({ downloadLink }) => {
  return (
    <a href={downloadLink} className="component-button-download" download>
      Datasheet
    </a>
  );
};

export default DownloadButton;

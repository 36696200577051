import React from "react";
import "./Reviews.css";
import TataCommunications from "../assets/images/TataCommunications.jpg";
import Karkinos from "../assets/images/Karkinos.jpg";
import Axxela from "../assets/images/Axxela.jpg";

const Reviews = () => {
  return (
    <div className="prefix-testimonials">
      <section className="prefix-reviews">
        <div className="prefix-reviews-title">
          <p>
            <div className="main-heading-entire-site">Reviews</div>
          </p>
        </div>
        <div>
          <div className="prefix-reviews-row prefix-row-first">
            <div className="prefix-reviews-card">
              <div className="review-card-image">
                <img
                  className="prefix-card-img tata-image"
                  src={TataCommunications}
                  alt=""
                />
              </div>

              <div className="review-card">
                <div className="prefix-card-text">
                  <div className="prefix-card-title">
                    <p>“Calid: Reliable, professional, collaborative.”</p>
                  </div>
                  <div className="prefix-card-para">
                    <p>
                      We've collaborated closely with Calid Technologies at Tata
                      Communications for over 2 years, spanning software
                      development, project management, and niche industries like
                      IoT.{" "}
                    </p>
                    <p>
                      Their professionalism and clarity on deliverables are
                      exemplary. Excited for more success together.
                    </p>
                  </div>
                  <div className="prefix-card-author">
                    <svg
                      width="9"
                      height="2"
                      viewBox="0 0 9 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.526123 1.13867H8.25949"
                        stroke="black"
                        strokeWidth="0.822209"
                      />
                    </svg>
                    <p>
                      - Rajeev Roy, Associate Director at Tata Communications
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="prefix-reviews-card">
              <img
                className="prefix-card-img karkinos-image"
                src={Karkinos}
                alt=""
              />

              <div className="prefix-card-text">
                <div className="prefix-card-title">
                  <p>“Trusted Partner in Network Infrastructure”</p>
                </div>
                <div className="prefix-card-para">
                  <p>
                    Calid Technologies has been instrumental since our
                    inception, providing comprehensive support for our
                    nationwide network infrastructure.{" "}
                  </p>
                  <p>
                    Their focus on scalability and information security is
                    invaluable for our distributed organization. We commend
                    their technical expertise and professionalism.
                  </p>
                </div>

                <div className="prefix-card-author">
                  <svg
                    width="9"
                    height="2"
                    viewBox="0 0 9 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.526123 1.13867H8.25949"
                      stroke="black"
                      strokeWidth="0.822209"
                    />
                  </svg>
                  <p>
                    - Rakesh Kumar, ICT Services Manager at Karkinos Healthcare
                    Pvt Ltd.
                  </p>
                </div>
              </div>
            </div>

            <div className="prefix-reviews-card">
              <img
                className="prefix-card-img axxela-image"
                src={Axxela}
                alt=""
              />

              <div className="prefix-card-text">
                <div className="prefix-card-title">
                  <p>“Looking forward to long term association”</p>
                </div>
                <div className="prefix-card-para">
                  <p>
                    In the global derivatives market, we trust Calid
                    Technologies for their specialized expertise in secure
                    networks. With their proficiency in data centers,
                    low-latency networks, and enterprise security, they're the
                    perfect partner for us.
                  </p>
                  <p>
                    We've had a great experience with them and anticipate a
                    lasting partnership.
                  </p>
                </div>

                <div className="prefix-card-author">
                  <svg
                    width="9"
                    height="2"
                    viewBox="0 0 9 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.526123 1.13867H8.25949"
                      stroke="black"
                      strokeWidth="0.822209"
                    />
                  </svg>
                  <p>
                    - Aditya Giri, VP of Algorithm Development at Axxela
                    Research and Analytics Pvt Ltd
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Reviews;

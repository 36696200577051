// EnterpriseHPSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseHPSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import HPML30 from "../../../assets/pdf/HP-ML30.pdf";
import HPM350 from "../../../assets/pdf/HP-ML350.pdf";
import HPML110 from "../../../assets/pdf/HP-ML110.pdf";
import DL20 from "../../../assets/pdf/DL20.pdf";
import DL360 from "../../../assets/pdf/DL360.pdf";
import DL380 from "../../../assets/pdf/DL380.pdf";
import DL365 from "../../../assets/pdf/DL365.pdf";
import NAS1660 from "../../../assets/pdf/NAS-storeeasy-1660.pdf";
import HPEMSA2060 from "../../../assets/pdf/HPE-MSA-2060.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseHPSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Tower and Rackmount Servers",
  //   2: "Storage",
  // };

  const productDataList1 = [
    //HP ML30
    {
      images: [
        require("../../../assets/productImages/HP-ML30-1.png"),
        require("../../../assets/productImages/HP-ML30-2.png"),
        require("../../../assets/productImages/HP-ML30-3.png"),
        require("../../../assets/productImages/HP-ML30-4.png"),
      ],
      title: "HP ML30",
      about:
        "The HPE ProLiant ML30 Gen11 Plus server is a powerful yet affordable tower server designed for small offices, remote and branch offices to run on-premises and hybrid cloud solutions.",
      enterpriseFeature: (
        <>
          <li>Supports Intel® Xeon® E processors up to 8 cores 95W.</li>
          <li>Onboard M.2 NVMe SSD support.</li>
          <li>Dedicated HPE iLO port and serial port kit option.</li>
        </>
      ),
      downloadLink: HPML30,
      hyperlinks: [],
    },

    //HP ML350
    {
      images: [
        require("../../../assets/productImages/HP-ML350-1.png"),
        require("../../../assets/productImages/HP-ML350-2.png"),
        require("../../../assets/productImages/HP-ML350-3.png"),
        require("../../../assets/productImages/HP-ML350-4.png"),
      ],
      title: "HP ML350",
      about:
        "HPE ProLiant ML350 Gen10 server delivers a secure dual-socket tower server with performance, expandability, and proven reliability making it the choice for expanding SMBs. ",
      enterpriseFeature: (
        <>
          <li>
            Supports the additional 2nd generation Intel® Xeon Scalable
            Processor offerings delivering exceptional customer value with
            increased performance and industry leading frequency.
          </li>
        </>
      ),
      downloadLink: HPM350,
      hyperlinks: [],
    },

    //HP ML110
    {
      images: [
        require("../../../assets/productImages/HP-ML110-1.png"),
        require("../../../assets/productImages/HP-ML110-2.png"),
        require("../../../assets/productImages/HP-ML110-3.png"),
        require("../../../assets/productImages/HP-ML110-4.png"),
      ],
      title: "HP ML110",
      about:
        "The HPE ProLiant ML110 Gen11 server is an enhanced single processor tower with performance, expansion, and security at an affordable price. It supports the 4th and 5th Gen Intel® Xeon® Scalable processor.",
      enterpriseFeature: (
        <>
          <li>
            Increased memory bandwidth to 5600 MT/s1 , performance and lower
            power requirements with up to 1.5 TB DDR5 memory capacity with 16
            DIMM slots.
          </li>
        </>
      ),
      downloadLink: HPML110,
      hyperlinks: [],
    },

    //DL20
    {
      images: [
        require("../../../assets/productImages/DL20-1.png"),
        require("../../../assets/productImages/DL20-2.png"),
        require("../../../assets/productImages/DL20-3.png"),
        require("../../../assets/productImages/DL20-4.png"),
      ],
      title: "DL20",
      about:
        "The reliable HPE ProLiant DL20 Gen10 Plus server delivers a compact and versatile server with enhanced security at an affordable price. Deploy the portable short-depth rack form factor in small, remote, or branch offices.",
      enterpriseFeature: (
        <>
          <li>
            Supports Intel® Xeon® E-2300 series processors, up to 8 cores.
          </li>
          <li>
            290W non-hot-plug power supply with Platinum power efficiency.
          </li>
        </>
      ),
      downloadLink: DL20,
      hyperlinks: [],
    },

    //DL360
    {
      images: [
        require("../../../assets/productImages/DL360-1.png"),
        require("../../../assets/productImages/DL360-2.png"),
        require("../../../assets/productImages/DL360-3.png"),
        require("../../../assets/productImages/DL360-4.png"),
      ],
      title: "DL360",
      about:
        "The HPE ProLiant DL360 Gen10 server delivers security, agility and flexibility without compromise. It supports the Intel® Xeon® Scalable processor.",
      enterpriseFeature: (
        <>
          <li>
            Support for additional second generation Intel® Xeon® Scalable
            processors with improved price/performance.
          </li>
          <li>Innovative Design for Flexibility and Choice.</li>
        </>
      ),
      downloadLink: DL360,
      hyperlinks: [],
    },

    //DL380
    {
      images: [
        require("../../../assets/productImages/DL380-1.png"),
        require("../../../assets/productImages/DL380-2.png"),
        require("../../../assets/productImages/DL380-3.png"),
        require("../../../assets/productImages/DL380-4.png"),
      ],
      title: "DL380",
      about:
        "The HPE ProLiant DL380 Gen10 Plus server is adaptable for diverse workloads and environments, providing you with the right balance of expandability and scalability.",
      enterpriseFeature: (
        <>
          <li>Supports 3rd Generation Intel® Xeon® Scalable Processors.</li>
          <li>Support for industry-standard OCP 3.0 Network Adapters.</li>
          <li>
            Support for Trimodal (NVMe/SAS/SATA) HPE Smart Array controllers.
          </li>
        </>
      ),
      downloadLink: DL380,
      hyperlinks: [],
    },

    //DL365
    {
      images: [
        require("../../../assets/productImages/DL365-1.png"),
        require("../../../assets/productImages/DL365-2.png"),
        require("../../../assets/productImages/DL365-3.png"),
        require("../../../assets/productImages/DL365-4.png"),
      ],
      title: "DL365",
      about:
        "Equipped with PCIe Gen4 capabilities, the HPE ProLiant DL365 Gen10 Plus server offers improved data transfer rates and higher networking speeds.",
      enterpriseFeature: (
        <>
          <li>Intelligent Automation.</li>
          <li>360 Degree Security.</li>
          <li>Workload Optimization.</li>
        </>
      ),
      downloadLink: DL365,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //NAS 1660
    {
      images: [
        require("../../../assets/productImages/NAS-storeeasy-1660.png"),
        require("../../../assets/productImages/NAS-storeeasy-1660.png"),
        require("../../../assets/productImages/NAS-storeeasy-1660.png"),
        require("../../../assets/productImages/NAS-storeeasy-1660.png"),
      ],
      title: "NAS 1660",
      about:
        "If you need an easy-to-manage centralized space for securely storing documents, images, audio, and video files, look no further than HPE StoreEasy 1000 Storage with the HPE StoreEasy Management Console.",
      enterpriseFeature: (
        <>
          <li>Customized and Tailored for File Storage.</li>
          <li> Efficiency Saves you Time and Money.</li>
          <li>Multi-dimensional Protection for Your Data.</li>
        </>
      ),
      downloadLink: NAS1660,
      hyperlinks: [],
    },

    //HPE MSA 2060
    {
      images: [
        require("../../../assets/productImages/HPE-MSA-2060.png"),
        require("../../../assets/productImages/HPE-MSA-2060.png"),
        require("../../../assets/productImages/HPE-MSA-2060.png"),
        require("../../../assets/productImages/HPE-MSA-2060.png"),
      ],
      title: "HPE MSA 2060",
      about:
        "The HPE MSA 2060 Storage is a flash-ready hybrid storage system designed to deliver hands-free, affordable application acceleration for small and remote office deployments.",
      enterpriseFeature: (
        <>
          <li>
            Leverage Data Protection Features to Keep Your Business Running in
            the Event of a Disaster.
          </li>
          <li> Accelerate Applications Affordably.</li>
        </>
      ),
      downloadLink: HPEMSA2060,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="hp-enterprise-page">
      <Helmet>
                <title>CalidTech-Enterprise HPE</title>
                <meta name="description" content="Your website description here" />
                <meta name="keywords" content="keyword1, keyword2, keyword3" />
                <meta name="author" content="Your Name or Company" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.yourwebsite.com" />
                <meta property="og:site_name" content="Your Website Name" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Your Page Title" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://www.calidtech.com/EnterpriseHPSubcategoryPage" />
            </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Server and Storage Solution - HPE
      </div>
      <div className="hp-enterprise-subproduct-nav">
        <button
          className={`hp-enterprise-subproduct-nav-button ${
            currentSubproduct === 1 ? "active-hp-enterprise-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Tower and Rackmount Servers
        </button>
        <button
          className={`hp-enterprise-subproduct-nav-button ${
            currentSubproduct === 2 ? "active-hp-enterprise-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(2)}
        >
          Storage
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="hp-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="hp-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-hp-enterprise" : ""
                  }
                >
                  <button
                    className="hp-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseHPSubcategoryPage;

import React, { useState, useRef } from "react";
import "./EntContactComponent.css";
import location from "../../assets/svgs/location.svg";
import ContactEmail from "../../assets/svgs/ContactEmail.svg";
import contactPhone from "../../assets/svgs/contactPhone.svg";
import Popup from "./ContactPopup";
import Confetti from "react-confetti";
import ContactUs from "../../assets/images/ContactUs.jpg";

const EntContactComponent = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, phone, message } = formData;

    // Check if any required field is empty
    if (!name || !email || !phone || !message) {
      setPopupMessage("Please fill out all fields before submitting the form.");
      setShowPopup(true);
      return;
    }

    const formUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLSccF9NRjvWNKVMGHKmQpwEPFZsZxWwzmsz2FEmVBGFkCoDl7Q/formResponse"; // Updated Google Form submission URL
    const entryIds = {
      name: "entry.1704858838",
      email: "entry.1014532772",
      phone: "entry.1756162571",
      message: "entry.1858778965",
    };

    // Construct the URL with form data
    const url = `${formUrl}?${entryIds.name}=${encodeURIComponent(name)}&${entryIds.email
      }=${encodeURIComponent(email)}&${entryIds.phone}=${encodeURIComponent(
        phone
      )}&${entryIds.message}=${encodeURIComponent(message)}`;

    // Submit the form
    fetch(url, {
      method: "POST",
      mode: "no-cors",
    })
      .then(() => {
        // Clear form fields
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });

        // Show success popup
        setPopupMessage(
          "We have received your message. We will get in touch with you shortly!"
        );
        setShowPopup(true);
        // setShowConfetti(true); // Optionally trigger confetti animation

        // Automatically hide confetti after 3 seconds (adjust as needed)
        setTimeout(() => {
          setShowConfetti(false);
        }, 10000); // 3000 milliseconds (3 seconds) delay

        // Optionally, perform additional actions after successful submission
        console.log("Form submitted successfully");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        // Show error popup if needed
        setPopupMessage(
          "An error occurred while submitting the form. Please try again later."
        );
        setShowPopup(true);
      });
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  const contactContainerRef = useRef(null);
  const scrollToContact = () => {
    if (contactContainerRef && contactContainerRef.current) {
      contactContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="contact-div">
      {/* <Hero /> */}
      <div
        className="ImageBackgroundWrap Background-fixed"
        style={{
          backgroundImage: `url(${ContactUs})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div class="ImageInnerWrap">
          <div class="TextBackground">
            <div>
              <button className="scroll-button" onClick={scrollToContact}>
                <h2>Click here to contact us</h2>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* MAP */}
      <section>
        <div className="map-container">
          <div className="map_inner">
            <h4 className="main-heading-entire-site">Find Us on Google Map</h4>
            <p>
              We are Calid Technologies. And we are excited to get in touch with
              you. Please leave us a message down below and we will get back to
              you shortly!
            </p>
            <div className="map_bind">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1883.0140083990239!2d72.88234669839476!3d19.2811478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b1423cfdd50f%3A0x2ccc76885f685616!2sJP%20North%20-%20Aviva!5e0!3m2!1sen!2sin!4v1714763814190!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Embedded Map"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      {/* MAP END */}

      {/* Contact Component */}
      <div className="contact-container" ref={contactContainerRef}>
        <span className="big-circle"></span>
        <img src="img/shape.png" className="square" alt="" />
        <div className="contacts-form">
          <div className="contact-info">
            <h3 className="title">Let's get in touch</h3>
            <div className="info">
              <div className="information">
                <img src={location} alt="Location" />
                <p>JP North, Behind Western Hotel, WEH,Mira Road (E), 401107</p>
              </div>
              <div className="information">
                <img src={ContactEmail} alt="Email" />
                <p>info@calidtech.com</p>
              </div>
              <div className="information">
                <img src={contactPhone} alt="Phone" />
                <p>+91-7977584476</p>
              </div>
            </div>
          </div>
          <div className="contact-form">
            <span className="circle one"></span>
            <span className="circle two"></span>
            <form
              className="input-part-form"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <h3 className="title">Contact us</h3>
              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="input"
                />
                <label
                  className={formData.name ? "label-active" : ""}
                  htmlFor=""
                >
                  Name
                </label>
              </div>
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="input"
                />
                <label
                  className={formData.email ? "label-active" : ""}
                  htmlFor=""
                >
                  Email
                </label>
              </div>
              <div className="input-container">
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="input"
                />
                <label
                  className={formData.phone ? "label-active" : ""}
                  htmlFor=""
                >
                  Phone
                </label>
              </div>
              <div className="input-container textarea">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="input"
                ></textarea>
                <label
                  className={formData.message ? "label-active" : ""}
                  htmlFor=""
                >
                  Message
                </label>
              </div>
              <input type="submit" value="Send" className="btn" />
            </form>
          </div>
        </div>
      </div>

      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}

      {showConfetti && <Confetti />}
    </div>
  );
};
export default EntContactComponent;

// ManufacturingBlackbearSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingBlackbearSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import DD9000 from "../../../assets/pdf/DD9000.pdf";
import BIG9000 from "../../../assets/pdf/BIG9000.Pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";

const ManufacturingBlackbearSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Next Gen Firewall",
  // };

  const productDataList1 = [
    //BIG9000
    {
      images: [
        require("../../../assets/productImages/BIG9000-1.png"),
        require("../../../assets/productImages/BIG9000-1.png"),
        require("../../../assets/productImages/BIG9000-1.png"),
        require("../../../assets/productImages/BIG9000-1.png"),
      ],
      title: "BIG9000",
      about:
        "BIG9000 is a unidirectional communication and data transfer gateway that secures your critical operation assets across the Layer-1 network. Its security design  prevents data leakage.",
      manufactiringFeature: (
        <>
          <li>A rugged unidirectional gateway.</li>
          <li> Cyber Security at OSI Layer-1 for your asset.</li>
          <li> No door, no vulnerability.</li>
        </>
      ),
      downloadLink: BIG9000,
      hyperlinks: [],
    },

    //DD9000
    {
      images: [
        require("../../../assets/productImages/DD9000-1.png"),
        require("../../../assets/productImages/DD9000-1.png"),
        require("../../../assets/productImages/DD9000-1.png"),
        require("../../../assets/productImages/DD9000-1.png"),
      ],
      title: "DD9000",
      about:
        " DD9000 is a unidirectional communication and data transfer gateway that secures your critical operation assets across the Layer-1 network.  Its security design  prevents data leakage.",
      manufactiringFeature: (
        <>
          <li>We protect from outside dangers.</li>
          <li>We protect from new threats.</li>
          <li>We deliver last-mile security.</li>
        </>
      ),
      downloadLink: DD9000,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="blackbear-page">

      <Helmet>
        <title>CalidTech - FDT Blackbear</title>
        <meta name="description" content="Secure your operations with OT Security solutions from Blackbear, including DD9000 and BIG9000, at Calidtech. Enhance manufacturing safety and efficiency with advanced monitoring systems. Serving Mumbai, Pune, and across India" />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation,India OT security solutions,Pune industrial solutions,Mumbai manufacturing security,Calidtech Blackbear products,Industrial monitoring,OT monitoring,Manufacturing security solutions,Industrial cybersecurity,Condition monitoring systems,Blackbear BIG9000,Blackbear DD9000,India OT security solutions,Pune industrial solutions,Mumbai manufacturing security" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Secure your operations with OT Security solutions from Blackbear, including DD9000 and BIG9000, at Calidtech. Enhance manufacturing safety and efficiency with advanced monitoring systems. Serving Mumbai, Pune, and across India" />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FDT Blackbear" />
        <meta name="twitter:description" content="Secure your operations with OT Security solutions from Blackbear, including DD9000 and BIG9000, at Calidtech. Enhance manufacturing safety and efficiency with advanced monitoring systems. Serving Mumbai, Pune, and across India" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Secure your operations with OT Security solutions from Blackbear, including DD9000 and BIG9000, at Calidtech. Enhance manufacturing safety and efficiency with advanced monitoring systems. Serving Mumbai, Pune, and across India" />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://www.calidtech.com/ManufacturingBlackbearSubcategoryPage" />
      </Helmet>


      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        OT Security - Blackbear
      </div>
      <div className="blackbear-subproduct-nav">
        <button
          className={`blackbear-subproduct-nav-button ${currentSubproduct === 1 ? "active-blackbear-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Next Gen Firewall
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="blackbear-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-blackbear-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-blackbear" : ""}
                >
                  <button
                    className="blackbear-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingBlackbearSubcategoryPage;

// EnterpriseWirelessSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseWirelessSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import RGAP810 from "../../../assets/pdf/RG-AP810.pdf";
import RGAP820 from "../../../assets/pdf/RG-AP820.pdf";
import RGAP840 from "../../../assets/pdf/RG-AP840.pdf";
import RGAP850 from "../../../assets/pdf/RG-AP850.pdf";
import EAP101 from "../../../assets/pdf/EAP101.pdf";
import EAP102 from "../../../assets/pdf/EAP102.pdf";
import EAP111 from "../../../assets/pdf/EAP111.pdf";
import QNI870 from "../../../assets/pdf/QN-I-870.pdf";
import QNI490 from "../../../assets/pdf/QN-I-490.pdf";
import QNI470 from "../../../assets/pdf/QN-I-470.pdf";
import QNI280 from "../../../assets/pdf/QN-I-280.pdf";
import AP21 from "../../../assets/pdf/AP21.pdf";
import AP22 from "../../../assets/pdf/AP22.pdf";
import AP32 from "../../../assets/pdf/AP32-New.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseWirelessSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Ruijie ",
  //   2: "Edgecore",
  //   3: "Quantum",
  //   4: "Aruba",
  // };

  const productDataList1 = [
    //RG-AP810
    {
      images: [
        require("../../../assets/productImages/RG-AP810-1.png"),
        require("../../../assets/productImages/RG-AP810-2.png"),
        require("../../../assets/productImages/RG-AP810-3.png"),
        require("../../../assets/productImages/RG-AP810-4.png"),
      ],
      title: "RG-AP810",
      about:
        "The RG-AP810-I is a Wi-Fi 6 wireless access point that integrates dual radios, high performance, and enterprise-grade encryption technology.",
      enterpriseFeature: (
        <>
          <li>
            Maximum ROI (Lifetime free cloud management & WIS optimization
            service)
          </li>
          <li>
            HE80 supported for higher throughput with latest Wi-Fi 6 chipsets
          </li>
          <li>Hybrid management (Cloud / Controller / Standalone)</li>
        </>
      ),
      downloadLink: RGAP810,
      hyperlinks: [],
    },

    //RG-AP820
    {
      images: [
        require("../../../assets/productImages/RG-AP820-1.png"),
        require("../../../assets/productImages/RG-AP820-2.png"),
        require("../../../assets/productImages/RG-AP820-3.png"),
        require("../../../assets/productImages/RG-AP820-4.png"),
      ],
      title: "RG-AP820",
      about:
        "The RG-AP820-AR(V3) is a Wi-Fi 6 wireless access point that delivers tri radios, AI Radio design, high performance, and enterprise-grade encryption.",
      enterpriseFeature: (
        <>
          <li>5 Gbps SFP combo port for flexible networking</li>
          <li>Seamless client roaming enabled by AI radio</li>
          <li>Real-Time, full-band scanning for a secure network</li>
        </>
      ),
      downloadLink: RGAP820,
      hyperlinks: [],
    },

    //RG-AP840
    {
      images: [
        require("../../../assets/productImages/RG-AP840-1.png"),
        require("../../../assets/productImages/RG-AP840-2.png"),
        require("../../../assets/productImages/RG-AP840-3.png"),
        require("../../../assets/productImages/RG-AP840-4.png"),
      ],
      title: "RG-AP840",
      about:
        "The RG-AP840-L is an 802.11ax-compliant dual-radio wireless access point (AP) provided by Ruijie Networks for general education, higher education, government, finance, business, and other indoor scenarios.",
      enterpriseFeature: (
        <>
          <li>
            5G optical port and 5G electrical port for flexible networking
          </li>
          <li>Upgraded to Wi-Fi 6, a combined data rate of up to 5.378 Gbps</li>
          <li>
            160 MHz and 6 spatial streams for smoother wireless experiences
          </li>
        </>
      ),
      downloadLink: RGAP840,
      hyperlinks: [],
    },

    //RG-AP850
    {
      images: [
        require("../../../assets/productImages/RG-AP850-1.png"),
        require("../../../assets/productImages/RG-AP850-2.png"),
        require("../../../assets/productImages/RG-AP850-3.png"),
        require("../../../assets/productImages/RG-AP850-4.png"),
      ],
      title: "RG-AP850",
      about:
        "The RG-AP820-AR(V3) is a Wi-Fi 6 wireless access point that delivers tri radios, AI Radio design, high performance, and enterprise-grade encryption.",
      enterpriseFeature: (
        <>
          <li>5 Gbps SFP combo port for flexible networking</li>
          <li>Real-Time, full-band scanning for a secure network</li>
          <li>160 MHz high bandwidth, 6.245 Gbps high speed with Wi-Fi 6</li>
        </>
      ),
      downloadLink: RGAP850,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //EAP101
    {
      images: [
        require("../../../assets/productImages/EAP101-1.png"),
        require("../../../assets/productImages/EAP101-2.png"),
        require("../../../assets/productImages/EAP101-3.png"),
        require("../../../assets/productImages/EAP101-4.png"),
      ],
      title: "EAP101",
      about:
        "EAP101 is an enterprise-grade, concurrent dual-band Wi-Fi 6 indoor access point. EAP101 supports 2x2:2 uplink and downlink MU-MIMO between the AP and multiple clients.",
      enterpriseFeature: (
        <>
          <li>Support BLE/ Zigbee</li>
          <li>1 x 2.5GbE PoE WAN</li>
          <li>2 x GbE LAN</li>
        </>
      ),
      downloadLink: EAP101,
      hyperlinks: [],
    },

    //EAP102
    {
      images: [
        require("../../../assets/productImages/EAP102-1.png"),
        require("../../../assets/productImages/EAP102-2.png"),
        require("../../../assets/productImages/EAP102-3.png"),
        require("../../../assets/productImages/EAP102-4.png"),
      ],
      title: "EAP102",
      about:
        "EAP102 is an enterprise-grade, concurrent dual-band Wi-Fi 6 indoor access point. EAP102 supports 4x4:4 uplink and down link MU-MIMO between the AP and multiple clients.",
      enterpriseFeature: (
        <>
          <li>4x4:4 UL MU-MIMO</li>
          <li>Concurrent Dual-Band 2.4GHz & 5GHz</li>
          <li>Support BLE</li>
        </>
      ),
      downloadLink: EAP102,
      hyperlinks: [],
    },

    //EAP111
    {
      images: [
        require("../../../assets/productImages/EAP111-1.png"),
        require("../../../assets/productImages/EAP111-2.png"),
        require("../../../assets/productImages/EAP111-3.png"),
        require("../../../assets/productImages/EAP111-4.png"),
      ],
      title: "EAP111",
      about:
        "With support for 2x2:2 uplink and downlink MU-MIMO between the access point and multiple clients, EAP111 delivers an impressive aggregated data rate of up to 3Gbps.",
      enterpriseFeature: (
        <>
          <li>Support ecCLOUD</li>
          <li>1 x GbE LAN</li>
          <li>Support BLE/Zigbee</li>
        </>
      ),
      downloadLink: EAP111,
      hyperlinks: [],
    },
  ];

  const productDataList3 = [
    //QN-I-870
    {
      images: [
        require("../../../assets/productImages/QN-I-870-1.png"),
        require("../../../assets/productImages/QN-I-870-1.png"),
        require("../../../assets/productImages/QN-I-870-1.png"),
        require("../../../assets/productImages/QN-I-870-1.png"),
      ],
      title: "QN-I-870",
      about:
        "QN-I-870 is a Wi-Fi 6 Access Point offering high-performance connectivity for any organization experiencing largely growing numbers of IoT and mobility requirements.",
      enterpriseFeature: (
        <>
          <li>Packed with the latest 802.11ax technology.</li>
          <li>Phenomenal Wi-Fi performance.</li>
          <li>Build-next generation guest Wi-Fi networks.</li>
        </>
      ),
      downloadLink: QNI870,
      hyperlinks: [],
    },

    //QN-I-490
    {
      images: [
        require("../../../assets/productImages/QN-I-490-1.png"),
        require("../../../assets/productImages/QN-I-490-1.png"),
        require("../../../assets/productImages/QN-I-490-1.png"),
        require("../../../assets/productImages/QN-I-490-1.png"),
      ],
      title: "QN-I-490",
      about:
        "QN-I-490 is a Wi-Fi 6 access point offering high-performance connectivity for any organization experiencing growing IoT and mobility requirements.",
      enterpriseFeature: (
        <>
          <li>Enhance the performance of the device.</li>
          <li>Theft prevention functionality.</li>
          <li>Exceptional Wi-Fi performance.</li>
        </>
      ),
      downloadLink: QNI490,
      hyperlinks: [],
    },

    //QN-I-470
    {
      images: [
        require("../../../assets/productImages/QN-I-470-1.png"),
        require("../../../assets/productImages/QN-I-470-1.png"),
        require("../../../assets/productImages/QN-I-470-1.png"),
        require("../../../assets/productImages/QN-I-470-1.png"),
      ],
      title: "QN-I-470",
      about:
        "OFDMA technology ensures exceptionally efficient high-speed connectivity, outstanding coverage and seamless performance in densely populated areas such as railway stations, hospitals, etc.",
      enterpriseFeature: (
        <>
          <li>Exceptional Wi-Fi performance.</li>
          <li>Theft prevention functionality.</li>
          <li>Increased device capacity.</li>
        </>
      ),
      downloadLink: QNI470,
      hyperlinks: [],
    },

    //QN-I-280
    {
      images: [
        require("../../../assets/productImages/QN-I-280-1.png"),
        require("../../../assets/productImages/QN-I-280-1.png"),
        require("../../../assets/productImages/QN-I-280-1.png"),
        require("../../../assets/productImages/QN-I-280-1.png"),
      ],
      title: "QN-I-280",
      about:
        "QN-I-280 is a Wi-Fi 6 access point offering high-performance connectivity for any organization experiencing largely growing numbers of IoT and mobility requirements.",
      enterpriseFeature: (
        <>
          <li>Packed with the latest 802.11ax technology.</li>
          <li>Phenomenal Wi-Fi performance.</li>
          <li>Build next-generation guest Wi-Fi networks.</li>
        </>
      ),
      downloadLink: QNI280,
      hyperlinks: [],
    },
  ];

  const productDataList4 = [
    //AP21
    {
      images: [
        require("../../../assets/productImages/AP21-1.png"),
        require("../../../assets/productImages/AP21-1.png"),
        require("../../../assets/productImages/AP21-1.png"),
        require("../../../assets/productImages/AP21-1.png"),
      ],
      title: "AP21",
      about:
        "The HPE Networking Instant On Access Point AP21 brings affordable enterprise-class Wi-Fi CERTIFIED 6™ wireless access to home offices, small restaurants, and conference rooms.",
      enterpriseFeature: (
        <>
          <li>Support for 50 clients.</li>
          <li>Smart Mesh.</li>
          <li>Backward-compatible.</li>
        </>
      ),
      downloadLink: AP21,
      hyperlinks: [],
    },

    //AP22
    {
      images: [
        require("../../../assets/productImages/AP22-1.png"),
        require("../../../assets/productImages/AP22-1.png"),
        require("../../../assets/productImages/AP22-1.png"),
        require("../../../assets/productImages/AP22-1.png"),
      ],
      title: "AP22",
      about:
        "Check out the competitively priced HPE Networking Instant On Access Point AP22 Wi-Fi 6 access point. Delivering blazing-fast performance and greater capacity to handle more devices simultaneously.",
      enterpriseFeature: (
        <>
          <li>Wi-Fi CERTIFIED 6™ Instant On AP22.</li>
          <li>Backward-compliant.</li>
          <li>25% data increase.</li>
        </>
      ),
      downloadLink: AP22,
      hyperlinks: [],
    },

    //AP32
    {
      images: [
        require("../../../assets/productImages/AP32-New-1.png"),
        require("../../../assets/productImages/AP32-New-1.png"),
        require("../../../assets/productImages/AP32-New-1.png"),
        require("../../../assets/productImages/AP32-New-1.png"),
      ],
      title: "AP32",
      about:
        "The HPE Networking Instant On Access Point AP32 offers a 6GHz dedicated wireless band to bring more throughput and productivity to your Wi-Fi 6E-enabled devices.",
      enterpriseFeature: (
        <>
          <li>2.5 gigabit Ethernet uplink port.</li>
          <li>Backward-compatible.</li>
          <li>Increased capacity.</li>
        </>
      ),
      downloadLink: AP32,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      case 3:
        return productDataList3;
      case 4:
        return productDataList4;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="wireless-enterprise-page">
      <Helmet>
                <title>CalidTech-Enterprise Wireless</title>
                <meta name="description" content="Your website description here" />
                <meta name="keywords" content="keyword1, keyword2, keyword3" />
                <meta name="author" content="Your Name or Company" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.yourwebsite.com" />
                <meta property="og:site_name" content="Your Website Name" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Your Page Title" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/EnterpriseWirelessSubcategoryPage" />
            </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Small Medium Business LAN & Wireless - Wireless
      </div>
      <div className="wireless-enterprise-subproduct-nav">
        <button
          className={`wireless-enterprise-subproduct-nav-button ${
            currentSubproduct === 1
              ? "active-wireless-enterprise-subproduct"
              : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Ruijie
        </button>
        <button
          className={`wireless-enterprise-subproduct-nav-button ${
            currentSubproduct === 2
              ? "active-wireless-enterprise-subproduct"
              : ""
          }`}
          onClick={() => switchSubproduct(2)}
        >
          Edgecore
        </button>
        <button
          className={`wireless-enterprise-subproduct-nav-button ${
            currentSubproduct === 3
              ? "active-wireless-enterprise-subproduct"
              : ""
          }`}
          onClick={() => switchSubproduct(3)}
        >
          Quantum
        </button>
        <button
          className={`wireless-enterprise-subproduct-nav-button ${
            currentSubproduct === 4
              ? "active-wireless-enterprise-subproduct"
              : ""
          }`}
          onClick={() => switchSubproduct(4)}
        >
          Aruba
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="wireless-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="wireless-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-wireless-enterprise" : ""
                  }
                >
                  <button
                    className="wireless-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseWirelessSubcategoryPage;

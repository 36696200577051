import React from "react";
import "./ManufacturingBackground.css";
import video from "../../assets/videos/ManufactringPlantsVideo.mp4";
import ManufacturingLanding from "../../assets/images/factoryDigital.jpg";
import ManufacturingLanding2 from "../../assets/images/man-electrical-technician-working-switchboard-with-fuses-uses-tablet.jpg";
import ManufacturingLanding3 from "../../assets/images/fdtLandingImage.jpg";

const ManufacturingBackground = ({ playStatus, heroCount }) => {
  if (playStatus) {
    return (
      <video className="ManufacturingBackground fade-in" autoPlay muted>
        <source src={video} type="video/mp4" />
      </video>
    );
  } else if (heroCount === 0) {
    return (
      <img
        src={ManufacturingLanding}
        className="ManufacturingBackground fade-in"
        alt=""
      />
    );
  } else if (heroCount === 1) {
    return (
      <img
        src={ManufacturingLanding2}
        className="ManufacturingBackground fade-in"
        alt=""
      />
    );
  } else if (heroCount === 2) {
    return (
      <img
        src={ManufacturingLanding3}
        className="ManufacturingBackground fade-in"
        alt=""
      />
    );
  }
};

export default ManufacturingBackground;

import React from "react";

const EnquiryButton = ({ onClick }) => {
  return (
    <button className="component-button-enquiry" onClick={onClick}>
      Enquiry
    </button>
  );
};

export default EnquiryButton;

import React from "react";
import "./EnterpriseCaseStudyCard.css";
class EnterpriseCaseStudyCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 100);
  }

  render() {
    const { loaded } = this.state;
    const { title, description, image, onClick } = this.props;

    return (
      <div
        className={`case-study-card ${loaded ? "loaded" : ""}`}
        onClick={onClick}
      >
        <div className="front card-content">
          <div className="neon-border">
            <img src={image} alt={title} />
          </div>
          <h2 className="card-title animation-font">{title}</h2>
        </div>
        <div className="back card-content">
          <p>{description}</p>
          <a href="#" onClick={onClick}>
            Read More
          </a>
        </div>
      </div>
    );
  }
}

export default EnterpriseCaseStudyCard;

// EnterpriseCategoryPageNavBar.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./EnterpriseCategoryPageNavBar.css";

const EnterpriseCategoryPageNavBar = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubcategory, setActiveSubcategory] = useState(null);

  const categories = [
    {
      name: "Enterprise LAN and Wireless",
      subcategories: ["Juniper/Mist", "HPE/Aruba", "Ruckus Networks", "Cisco"],
    },
    {
      name: "Small Medium Business LAN & Wireless",
      subcategories: ["Switching", "Wireless"],
    },
    {
      name: "Server and Storage Solution",
      subcategories: ["HPE", "Dell", "Lenovo"],
    },
    {
      name: "Network and Cybersecurity",
      subcategories: [
        "Palo Alto",
        "Fortigate",
        "Sophos",
        "Captive Portal server",
      ],
    },
    {
      name: "PTP and PtMP UBR",
      subcategories: ["Cambium", "Radwin", "Mimosa", "Altai"],
    },
  ];

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCategoryClick = (categoryName) => {
    if (activeCategory !== categoryName) {
      setActiveCategory(categoryName);
      setActiveSubcategory(null);
      switch (categoryName) {
        case "Enterprise LAN and Wireless":
          navigate("/EnterpriseLANAndWirelessCategoryPage");
          break;
        case "Server and Storage Solution":
          navigate("/EnterpriseServerAndStorageSolutionCategoryPage");
          break;
        case "Network and Cybersecurity":
          navigate("/EnterpriseNetworkAndCybersecurityCategoryPage");
          break;
        case "PTP and PtMP UBR":
          navigate("/EnterprisePTPAndPtMPUBRCategoryPage");
          break;
        case "Small Medium Business LAN & Wireless":
          navigate("/EnterpriseSmallMediumBusinessLANAndWirelessCategoryPage");
          break;
        // Add more cases for other categories if needed
        default:
          break;
      }
    }
  };

  const handleSubcategoryClick = (subcategoryName) => {
    if (activeSubcategory !== subcategoryName) {
      setActiveSubcategory(subcategoryName);
      switch (subcategoryName) {
        case "Juniper/Mist":
          navigate("/EnterpriseJuniperMistSubcategoryPage");
          break;
        case "Ruckus Networks":
          navigate("/EnterpriseRuckusSubcategoryPage");
          break;
        case "HPE/Aruba":
          navigate("/EnterpriseHPEArubaSubcategoryPage");
          break;
        case "Cisco":
          navigate("/EnterpriseCiscoSubcategoryPage");
          break;
        case "Switching":
          navigate("/EnterpriseSwitchingSubcategoryPage");
          break;
        case "HPE":
          navigate("/EnterpriseHPSubcategoryPage");
          break;
        case "Dell":
          navigate("/EnterpriseDellSubcategoryPage");
          break;
        case "Lenovo":
          navigate("/EnterpriseLenovoSubcategoryPage");
          break;
        case "Palo Alto":
          navigate("/EnterprisePaloAltoSubcategoryPage");
          break;
        case "Fortigate":
          navigate("/EnterpriseFortiGateSubcategoryPage");
          break;
        case "Sophos":
          navigate("/EnterpriseSophosSubcategoryPage");
          break;
        case "Captive Portal server":
          navigate("/EnterpriseAAAHotspotCaptivePortalserverSubcategoryPage");
          break;
        case "Cambium":
          navigate("/EnterpriseCambiumSubcategoryPage");
          break;
        case "Radwin":
          navigate("/EnterpriseRadwinSubcategoryPage");
          break;
        case "Mimosa":
          navigate("/EnterpriseMimosaSubcategoryPage");
          break;
        case "Altai":
          navigate("/EnterpriseAltaiSubcategoryPage");
          break;
        case "Wireless":
          navigate("/EnterpriseWirelessSubcategoryPage");
          break;
        // Add cases for other subcategories if needed
        default:
          break;
      }
    }
  };

  return (
    <nav
      id="enterprise-navbar"
      className={isMobileMenuOpen ? "active-enterprise" : ""}
    >
      <button
        className="enterprise-mobile-menu-icon"
        onClick={toggleMobileMenu}
      >
        ☰
      </button>
      <ul
        className={`enterprise-nav-categories ${
          isMobileMenuOpen ? "mobile-menu" : ""
        }`}
      >
        {categories.map((category) => (
          <li
            key={category.name}
            className={`enterprise-li-navbar ${
              activeCategory === category.name ? "active-enterprise" : ""
            }`}
          >
            <button
              className="enterprise-button-navbar enterprise-navbar-category-name"
              onClick={() => handleCategoryClick(category.name)}
            >
              {category.name}
            </button>
            <ul className="enterprise-subcategories-navbar">
              {category.subcategories.map((subcategory, index) => (
                <li key={index}>
                  <button
                    className="enterprise-subcategory-name-navbar"
                    onClick={() => handleSubcategoryClick(subcategory)}
                  >
                    {subcategory}
                  </button>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default EnterpriseCategoryPageNavBar;

// EnterpriseFortiGateSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseFortiGateSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import FortiGate40F from "../../../assets/pdf/FortiGate-40F.pdf";
import FortiGate60F from "../../../assets/pdf/FortiGate-60F.pdf";
import FortiGate70F from "../../../assets/pdf/FortiGate-70F.pdf";
import FortiGate80F from "../../../assets/pdf/FortiGate-80F.pdf";
import FortiGate100F from "../../../assets/pdf/FortiGate-100F.pdf";
import FortiGate200F from "../../../assets/pdf/FortiGate-200F.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseFortiGateSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Next Gen Firewall",
  // };

  const productDataList1 = [
    //FortiGate 40F
    {
      images: [
        require("../../../assets/productImages/FortiGate-40F-1.png"),
        require("../../../assets/productImages/FortiGate-40F-2.png"),
        require("../../../assets/productImages/FortiGate-40F-1.png"),
        require("../../../assets/productImages/FortiGate-40F-2.png"),
      ],
      title: "FortiGate 40F",
      about:
        "The FortiGate Next-Generation Firewall 40F series is ideal for building security-driven networks at distributed enterprise sites and transforming WAN architecture at any scale.",
      enterpriseFeature: (
        <>
          <li>Network and File Security.</li>
          <li>SaaS and Data Security.</li>
          <li>Zero-Day Threat Prevention.</li>
        </>
      ),
      downloadLink: FortiGate40F,
      hyperlinks: [],
    },

    //FortiGate 60F
    {
      images: [
        require("../../../assets/productImages/FortiGate-60F-1.png"),
        require("../../../assets/productImages/FortiGate-60F-2.png"),
        require("../../../assets/productImages/FortiGate-60F-1.png"),
        require("../../../assets/productImages/FortiGate-60F-2.png"),
      ],
      title: "FortiGate 60F",
      about:
        "The FortiGate Next-Generation Firewall 60F series is ideal for building security-driven networks at distributed enterprise sites and transforming WAN architecture at any scale.",
      enterpriseFeature: (
        <>
          <li>Network and File Security.</li>
          <li>Web / DNS Security.</li>
          <li>OT Security.</li>
        </>
      ),
      downloadLink: FortiGate60F,
      hyperlinks: [],
    },

    //FortiGate 70F
    {
      images: [
        require("../../../assets/productImages/FortiGate-70F-1.png"),
        require("../../../assets/productImages/FortiGate-70F-2.png"),
        require("../../../assets/productImages/FortiGate-70F-1.png"),
        require("../../../assets/productImages/FortiGate-70F-2.png"),
      ],
      title: "FortiGate 70F",
      about:
        "The FortiGate Next-Generation Firewall 70F series is ideal for building security-driven networks at distributed enterpris sites and transforming WAN architecture at any scale.",
      enterpriseFeature: (
        <>
          <li>
            Combines a RISC-based CPU with Fortinet's proprietary Security
            Processing Unit (SPU) content and network processors for unmatched
            performance.
          </li>
        </>
      ),
      downloadLink: FortiGate70F,
      hyperlinks: [],
    },

    //FortiGate 80F
    {
      images: [
        require("../../../assets/productImages/FortiGate-80F-1.png"),
        require("../../../assets/productImages/FortiGate-80F-2.png"),
        require("../../../assets/productImages/FortiGate-80F-3.png"),
        require("../../../assets/productImages/FortiGate-80F-4.png"),
      ],
      title: "FortiGate 80F",
      about:
        "The FortiGate Next-Generation Firewall 80F series is ideal for building security-driven networks at distributed enterprise sites and transforming WAN architecture at any scale.",
      enterpriseFeature: (
        <>
          <li>
            Extends security to access layer to enable SD-Branch transformation
            with accelerated and integrated switch and access point
            connectivity.
          </li>
          <li>OT Security.</li>
        </>
      ),
      downloadLink: FortiGate80F,
      hyperlinks: [],
    },

    //FortiGate 100F
    {
      images: [
        require("../../../assets/productImages/FortiGate-100F-1.png"),
        require("../../../assets/productImages/FortiGate-100F-2.png"),
        require("../../../assets/productImages/FortiGate-100F-1.png"),
        require("../../../assets/productImages/FortiGate-100F-2.png"),
      ],
      title: "FortiGate 100F",
      about:
        "The FortiGate 100F Series NGFW combines AI-powered security and machine learning to deliver Threat Protection at any scale. Get deeper visibility into your network and see applications, users, etc.",
      enterpriseFeature: (
        <>
          <li>
            Reduces environmental footprint by saving on average over 60% in
            power consumption compared to previous generation of FortiGate
            models.
          </li>
        </>
      ),
      downloadLink: FortiGate100F,
      hyperlinks: [],
    },

    //FortiGate 200F
    {
      images: [
        require("../../../assets/productImages/FortiGate-200F-1.png"),
        require("../../../assets/productImages/FortiGate-200F-2.png"),
        require("../../../assets/productImages/FortiGate-200F-1.png"),
        require("../../../assets/productImages/FortiGate-200F-2.png"),
      ],
      title: "FortiGate 200F",
      about:
        "The FortiGate 200F Series NGFW combines AI-powered security and machine learning to deliver Threat Protection at any scale. Get deeper visibility into your network and see applications, users, etc.",
      enterpriseFeature: (
        <>
          <li>
            The FortiGate 200F Series features a dedicated module that hardens
            physical networking appliances by generating, storing, and
            authenticating cryptographic keys.
          </li>
        </>
      ),
      downloadLink: FortiGate200F,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="fortigate-enterprise-page">
      <Helmet>
                <title>CalidTech-Enterprise Forti Gate</title>
                <meta name="description" content="Your website description here" />
                <meta name="keywords" content="keyword1, keyword2, keyword3" />
                <meta name="author" content="Your Name or Company" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.yourwebsite.com" />
                <meta property="og:site_name" content="Your Website Name" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Your Page Title" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/EnterpriseFortiGateSubcategoryPage" />
            </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Network and Cybersecurity - Fortigate
      </div>
      <div className="fortigate-enterprise-subproduct-nav">
        <button
          className={`fortigate-enterprise-subproduct-nav-button ${
            currentSubproduct === 1
              ? "active-fortigate-enterprise-subproduct"
              : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Next Gen Firewall
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="fortigate-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="fortigate-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-fortigate-enterprise" : ""
                  }
                >
                  <button
                    className="fortigate-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseFortiGateSubcategoryPage;

import React from "react";
import "./ProductCategoryEnt.css";
import { Link } from "react-router-dom";

const ProductCategory = () => {
  return (
    <div className="entproudcategory">
      <br></br>
      <h1 style={{ color: "white" }}>
        <div className="main-heading-entire-site">Solution Offering</div>
      </h1>
      <div className="wrapper">
        <div className="cardEnt">
          <h3 className="card-title1">Enterprise Lan and Wireless</h3>
          <br></br>
          <p className="card-content">
            Empower your workforce with our enterprise LAN and wireless
            solutions. Designed for scalability and mobility, our robust
            infrastructure ensures reliable connectivity and optimal
            performance.
          </p>
          <button className="card-btn">
            <Link
              to="/EnterpriseLANAndWirelessCategoryPage"
              style={{ color: "white" }}
            >
              Explore
            </Link>
          </button>
        </div>
        <div className="cardEnt">
          <h3 className="card-title1">
            Small Medium business LAN and Wireless
          </h3>
          <br></br>
          <p className="card-content">
            Empower your small to medium-sized business with our cutting-edge
            LAN and wireless solutions. Designed to deliver high performance,
            reliability, enabling your team to work efficiently.
          </p>
          <button className="card-btn">
            <Link
              to="/EnterpriseSmallMediumBusinessLANAndWirelessCategoryPage"
              style={{ color: "white" }}
            >
              Explore
            </Link>
          </button>
        </div>
        <div className="cardEnt">
          <h3 className="card-title1">Server and Storage</h3>
          <br></br>

          <p className="card-content">
            Optimize data handling with our server and storage solutions. From
            secure data storage to efficient processing, our systems enhance
            productivity and support uninterrupted operations.
          </p>
          <button className="card-btn">
            <Link
              to="/EnterpriseServerAndStorageSolutionCategoryPage"
              style={{ color: "white" }}
            >
              Explore
            </Link>
          </button>
        </div>
        <div className="cardEnt">
          <h3 className="card-title1">Network and Cybersecurity</h3>
          <br></br>
          <p className="card-content">
            Ensure the integrity of your network with our cybersecurity
            solutions. From intrusion detection to threat response, we fortify
            your defenses against evolving cyber threats, safeguarding critical
            assets.
          </p>
          <button className="card-btn">
            <Link
              to="/EnterpriseNetworkAndCybersecurityCategoryPage"
              style={{ color: "white" }}
            >
              Explore
            </Link>
          </button>
        </div>
        <div className="cardEnt">
          <h3 className="card-title1">PTP and PTMP UBR</h3>
          <br></br>
          <p className="card-content">
            Experience ultra-fast broadband with our fixed wireless solutions.
            Whether PTP or PTMP, our UBR technology delivers reliable
            connectivity for diverse environments, enhancing network coverage.
          </p>
          <button className="card-btn">
            <Link
              to="/EnterprisePTPAndPtMPUBRCategoryPage"
              style={{ color: "white" }}
            >
              Explore
            </Link>
          </button>
        </div>
      </div>
      <br></br>
      <br></br>
    </div>
  );
};

export default ProductCategory;

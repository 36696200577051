// EnterpriseCiscoSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseCiscoSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import CBS35024T from "../../../assets/pdf/CBS350-24T-24P.pdf";
import CBS35024P from "../../../assets/pdf/CBS350-24T-24P.pdf";
import CBS22024T from "../../../assets/pdf/CBS220-24T.pdf";
import CBS25024T from "../../../assets/pdf/CBS250-24T.pdf";
import C9200 from "../../../assets/pdf/C9200.pdf";
import C9300 from "../../../assets/pdf/C9300.pdf";
import C9500 from "../../../assets/pdf/C9500.pdf";
import C9115 from "../../../assets/pdf/C9115.pdf";
import C9120 from "../../../assets/pdf/C9120.pdf";
import C9130 from "../../../assets/pdf/C9130.pdf";
import MR36 from "../../../assets/pdf/MR36.pdf";
import MR44 from "../../../assets/pdf/MR44.pdf";
import MR46 from "../../../assets/pdf/MR46.pdf";
import MR56 from "../../../assets/pdf/MR56.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';

const EnterpriseCiscoSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "SMB Access Switches",
  //   2: "Enterprise Access Switches and core switch",
  //   3: "Enterprise Wireless",
  //   4: "Meraki Wireless",
  // };

  const productDataList1 = [
    //CBS350-24T
    {
      id: "C9115",
      images: [
        require("../../../assets/productImages/CBS350-24T-1.png"),
        require("../../../assets/productImages/CBS350-24T-2.png"),
        require("../../../assets/productImages/CBS350-24T-1.png"),
        require("../../../assets/productImages/CBS350-24T-2.png"),
      ],
      title: "CBS350-24T",
      about:
        "The Cisco Business 350 Series Switches, part of the Cisco Business line of network solutions, is a portfolio of affordable managed switches that provides a critical building block for any small office network.",
      enterpriseFeature: (
        <>
          <li>Secure office connectivity.</li>
          <li>Unified communications.</li>
          <li>Highly secure guest connectivity.</li>
        </>
      ),
      downloadLink: CBS35024T,
      hyperlinks: [
        { link: "AxxelaCaseStudyPage", name: "Axxela Case Study" },
        { link: "KarkinosCaseStudyPage", name: "Karkinos Case Study" },
      ],
    },

    //CBS350-24P
    {
      images: [
        require("../../../assets/productImages/CBS350-24T-1.png"),
        require("../../../assets/productImages/CBS350-24T-2.png"),
        require("../../../assets/productImages/CBS350-24T-1.png"),
        require("../../../assets/productImages/CBS350-24T-2.png"),
      ],
      title: "CBS350-24P",
      about:
        "The Cisco Business 350 Series Switches, part of the Cisco Business line of network solutions, is a portfolio of affordable managed switches that provides a critical building block for any small office network.",
      enterpriseFeature: (
        <>
          <li>Secure office connectivity.</li>
          <li>Unified communications.</li>
          <li>Highly secure guest connectivity.</li>
        </>
      ),
      downloadLink: CBS35024P,
      hyperlinks: [
        { link: "KarkinosCaseStudyPage", name: "Karkinos Case Study" },
      ],
    },

    //CBS220-24P
    {
      images: [
        require("../../../assets/productImages/CBS250-24T-1.png"),
        require("../../../assets/productImages/CBS250-24T-2.png"),
        require("../../../assets/productImages/CBS250-24T-1.png"),
        require("../../../assets/productImages/CBS250-24T-2.png"),
      ],
      title: "CBS220-24P",
      about:
        "Cisco announces the end-of-sale Cisco 220 Series Smart Switches. The last day to order the affected product(s) is July 30, 2022. Customers will continue to receive phone support from the Cisco Small Business Support Center.",
      enterpriseFeature: (
        <>
          <li>SF220-24-K9-CN: CBS220 Smart 24-port GE, 4x1G SFP.</li>
          <li>SF220-24-K9-EU: CBS220 Smart 24-port GE, 4x1G SFP.</li>
          <li>SF220-24-K9-UK: CBS220 Smart 24-port GE, 4x1G SFP.</li>
        </>
      ),
      downloadLink: CBS22024T,
      hyperlinks: [],
    },

    //CBS250-24T
    {
      images: [
        require("../../../assets/productImages/CBS250-24T-1.png"),
        require("../../../assets/productImages/CBS250-24T-2.png"),
        require("../../../assets/productImages/CBS250-24T-1.png"),
        require("../../../assets/productImages/CBS250-24T-2.png"),
      ],
      title: "CBS250-24T",
      about:
        "The Cisco Business 250 Series is the next generation of affordable smart switches that combine powerful performance and reliability with a complete suite of the features.",
      enterpriseFeature: (
        <>
          <li>High-speed desktop connectivity.</li>
          <li>Flexible wireless connectivity.</li>
          <li>Unified communications.</li>
        </>
      ),
      downloadLink: CBS25024T,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //C9200
    {
      images: [
        require("../../../assets/productImages/C9200-1.jpg"),
        require("../../../assets/productImages/C9200-2.jpg"),
        require("../../../assets/productImages/C9200-3.jpg"),
        require("../../../assets/productImages/C9200-4.jpg"),
      ],
      title: "C9200",
      about:
        "Cisco® Catalyst® 9200 Series switches extend the power of intent-based networking and Catalyst 9000 hardware and software innovation to a broader set of deployments.",
      enterpriseFeature: (
        <>
          <li>Cloud monitoring for Catalyst on Meraki dashboard.</li>
          <li>ENERGY STAR® certified models (C9200L models).</li>
          <li>Flexible downlink options with data, PoE+, UPOE, UPOE.</li>
        </>
      ),
      downloadLink: C9200,
      hyperlinks: [{ link: "AxxelaCaseStudyPage", name: "Axxela Case Study" }],
    },

    //C9300
    {
      images: [
        require("../../../assets/productImages/C9300-1.jpg"),
        require("../../../assets/productImages/C9300-2.jpg"),
        require("../../../assets/productImages/C9300-3.jpg"),
        require("../../../assets/productImages/C9300-4.jpg"),
      ],
      title: "C9300",
      about:
        "Cisco Catalyst 9300 Series switches are Cisco's lead stackable enterprise access switching platform and as part of the Catalyst 9000 family, are built to transform your network to handle a hybrid world.",
      enterpriseFeature: (
        <>
          <li>Up to 1TB of stacking bandwidth</li>
          <li>Flexible and dense uplink offerings</li>
          <li>Mixed Stacking with Backward Compatibility</li>
        </>
      ),
      downloadLink: C9300,
      hyperlinks: [{ link: "AxxelaCaseStudyPage", name: "Axxela Case Study" }],
    },

    //C9500
    {
      images: [
        require("../../../assets/productImages/C9500-1.jpg"),
        require("../../../assets/productImages/C9500-2.jpg"),
        require("../../../assets/productImages/C9500-3.jpg"),
        require("../../../assets/productImages/C9500-4.jpg"),
      ],
      title: "C9500",
      about:
        "The Catalyst 9500 Series, including the Catalyst 9500X models, continues to shape the future with continued innovation that helps you reimagine connections, reinforce security and redefine the experience.",
      enterpriseFeature: (
        <>
          <li>
            Up to 12.0 Tbps switching capacity with 8 Bpps forwarding rate.
          </li>
          <li>Hardware support for Application Hosting.</li>
          <li>Intel 2.3-GHz x86 CPU with 8 cores and 32-GB of DDR4 memory.</li>
        </>
      ),
      downloadLink: C9500,
      hyperlinks: [],
    },
  ];

  const productDataList3 = [
    //C9115
    {
      images: [
        require("../../../assets/productImages/C9115-1.png"),
        require("../../../assets/productImages/C9115-1.png"),
        require("../../../assets/productImages/C9115-1.png"),
        require("../../../assets/productImages/C9115-1.png"),
      ],
      title: "C9115",
      about:
        "The Cisco Catalyst 9115 Series with Wi-Fi 6 is the next generation of enterprise access points. They are resilient, secure, and intelligent. PHY data rates up to 3.47 Gbps (160 MHz with 5 GHz)",
      enterpriseFeature: (
        <>
          <li>Cisco Wireless LAN Services</li>
          <li>Cisco Catalyst 9800 Series Wireless Controllers</li>
          <li>Maximal Ratio Combining (MRC)</li>
        </>
      ),
      downloadLink: C9115,
      hyperlinks: [],
    },

    //C9120
    {
      images: [
        require("../../../assets/productImages/C9120-1.png"),
        require("../../../assets/productImages/C9120-1.png"),
        require("../../../assets/productImages/C9120-1.png"),
        require("../../../assets/productImages/C9120-1.png"),
      ],
      title: "C9120",
      about:
        "The Cisco Catalyst 9120AX Series Access Points are the next generation of enterprise access points. They are resilient, secure, and intelligent. Available on the 9120AXE and 9120AXP only.",
      enterpriseFeature: (
        <>
          <li>Cisco Catalyst 9800 Series Wireless Controllers.</li>
          <li>Supports Self-Identifiable Antennas (SIA) on one RP-TNC port.</li>
          <li>Compact multi-RF connector with DART interface.</li>
        </>
      ),
      downloadLink: C9120,
      hyperlinks: [],
    },

    //C9130
    {
      images: [
        require("../../../assets/productImages/C9130-1.png"),
        require("../../../assets/productImages/C9130-1.png"),
        require("../../../assets/productImages/C9130-1.png"),
        require("../../../assets/productImages/C9130-1.png"),
      ],
      title: "C9130",
      about:
        "The Cisco Catalyst 9130AX Series Access Points are the next generation of enterprise access points. They are resilient, secure, and intelligent. Application Hosting on Catalyst 9100 Access.",
      enterpriseFeature: (
        <>
          <li>
            Industry first 8x8 external antenna access point with smart antenna
            connector.
          </li>
          <li>Cisco Embedded Wireless Controller (EWC).</li>
        </>
      ),
      downloadLink: C9130,
      hyperlinks: [],
    },
  ];

  const productDataList4 = [
    //MR36
    {
      images: [
        require("../../../assets/productImages/MR36-1.png"),
        require("../../../assets/productImages/MR36-1.png"),
        require("../../../assets/productImages/MR36-1.png"),
        require("../../../assets/productImages/MR36-1.png"),
      ],
      title: "MR36",
      about:
        "The Cisco Meraki MR36 is a cloud-managed 2x2:2 802.11ax access point that raises the bar for wireless performance and efficiency. Designed for next-generation deployments in offices, schools.",
      enterpriseFeature: (
        <>
          <li>2x2:2 UL/DL MU-MIMO 802.11ax</li>
          <li>Up to 1.7 Gbps aggregate frame rate</li>
          <li>One 1 Gbps Ethernet port</li>
        </>
      ),
      downloadLink: MR36,
      hyperlinks: [],
    },

    //MR44
    {
      images: [
        require("../../../assets/productImages/MR44.png"),
        require("../../../assets/productImages/MR44.png"),
        require("../../../assets/productImages/MR44.png"),
        require("../../../assets/productImages/MR44.png"),
      ],
      title: "MR44",
      about:
        "The MR44 provides a maximum of 2.7 Gbps* aggregate frame rate with concurrent 2.4 GHz and 5 GHz radios. A dedicated third radio provides real-time WIDS/WIPS with automated RF optimization.",
      enterpriseFeature: (
        <>
          <li>4-stream UL/DL MU-MIMO 802.11ax (5 GHz)</li>
          <li>2-stream UL/DL MU-MIMO 802.11ax (2.4 GHz)</li>
          <li>One 2.5 Gbps multigigabit Ethernet port</li>
        </>
      ),
      downloadLink: MR44,
      hyperlinks: [],
    },

    //MR46
    {
      images: [
        require("../../../assets/productImages/MR46-1.png"),
        require("../../../assets/productImages/MR46-1.png"),
        require("../../../assets/productImages/MR46-1.png"),
        require("../../../assets/productImages/MR46-1.png"),
      ],
      title: "MR46",
      about:
        "The Cisco Meraki MR46 is a cloud-managed 4x4:4 802.11ax access point that raises the bar for wireless performance and efficiency. Designed for nextgeneration deployments in offices, schools, hospitals.",
      enterpriseFeature: (
        <>
          <li>4-stream UL/DL MU-MIMO 802.11ax</li>
          <li>One 2.5 Gbps multigigabit Ethernet port</li>
          <li>PoE: 802.3at</li>
        </>
      ),
      downloadLink: MR46,
      hyperlinks: [],
    },

    //MR56
    {
      images: [
        require("../../../assets/productImages/MR56-1.png"),
        require("../../../assets/productImages/MR56-1.png"),
        require("../../../assets/productImages/MR56-1.png"),
        require("../../../assets/productImages/MR56-1.png"),
      ],
      title: "MR56",
      about:
        "The Cisco Meraki MR56 is a cloud-managed 8x8:8 802.11ax access point that raises the bar for wireless performance and efficiency. Designed for nextgeneration deployments in offices, schools, hospitals.",
      enterpriseFeature: (
        <>
          <li>4-stream UL/DL MU-MIMO 802.11ax (2.4GHz)</li>
          <li>8-stream UL/DL MU-MIMO 802.11ax (5GHz)</li>
          <li>5.9 Gbps dual aggregate frame rate</li>
        </>
      ),
      downloadLink: MR56,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      case 3:
        return productDataList3;
      case 4:
        return productDataList4;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="cisco-enterprise-page">
      <Helmet>
                <title>CalidTech-Enterprise Cisco </title>
                <meta name="description" content="Your website description here" />
                <meta name="keywords" content="keyword1, keyword2, keyword3" />
                <meta name="author" content="Your Name or Company" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.yourwebsite.com" />
                <meta property="og:site_name" content="Your Website Name" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Your Page Title" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/EnterpriseCiscoSubcategoryPage" />
            </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Enterprise LAN and Wireless - Cisco
      </div>
      <div className="cisco-enterprise-subproduct-nav">
        <button
          className={`cisco-enterprise-subproduct-nav-button ${
            currentSubproduct === 1 ? "active-cisco-enterprise-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          SMB Access Switches
        </button>
        <button
          className={`cisco-enterprise-subproduct-nav-button ${
            currentSubproduct === 2 ? "active-cisco-enterprise-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(2)}
        >
          Enterprise Access Switches and core switch
        </button>
        <button
          className={`cisco-enterprise-subproduct-nav-button ${
            currentSubproduct === 3 ? "active-cisco-enterprise-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(3)}
        >
          Enterprise Wireless
        </button>
        <button
          className={`cisco-enterprise-subproduct-nav-button ${
            currentSubproduct === 4 ? "active-cisco-enterprise-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(4)}
        >
          Meraki Wireless
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="cisco-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="cisco-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-cisco-enterprise" : ""
                  }
                >
                  <button
                    className="cisco-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseCiscoSubcategoryPage;

import React from "react";
import "./ServiceSecondSection.css";
import { Link } from "react-router-dom";
// import iot from "../../assets/videos/iot_gif-ezgif.com-crop.gif";

const ServiceSecondSection = () => {
  return (
    <div className="servicesecond">
      <div className="service-section">
        <h2 className="section-title" style={{ color: "white" }}>
          <div className="main-heading-entire-site">Service Categories</div>
        </h2>

        <div className="service-cards-list">
          <div className="service-card1 service-card2">
            <div className="service-card_image">
              <img
                src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExOHUzdGFxeTZ3ZThhbW9rNHJ6M2d6dTk5d3g1OWdubnl1M3k3OTViNyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/EE9p6E0aLyBWkYc0Fo/giphy.gif"
                alt="Wireless Planning"
              />
            </div>
            <div className="service-card_title">
              <button
                className="service-button"
                style={{ border: "2px solid #007BFF", color: "white" }}
              >
                <Link
                  to="/ManufacturingWarehouseManagementSubservicePage"
                  style={{ textDecoration: "none", color: "#007BFF" }}
                >
                  Warehouse Management
                </Link>
              </button>
            </div>
          </div>

          <div className="service-card1 service-card2">
            <div className="service-card_image">
              <img
                src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExYmdzcTZkcXdqbWNpdDJleXBuZDJwZGZuMHJvb2NvcTlicG04NTh0MSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/MaXOUjkV73aO4/giphy.gif"
                alt="Wireless Planning"
              />
            </div>
            <div className="service-card_title">
              <button
                className="service-button"
                style={{ border: "2px solid #007BFF", color: "white" }}
              >
                <Link
                  to="/ManufacturingWirelessPlanningSubservicePage"
                  style={{ textDecoration: "none", color: "#007BFF" }}
                >
                  Wireless Planning
                </Link>
              </button>
            </div>
          </div>

          <div className="service-card1 service-card3">
            <div className="service-card_image">
            <img 
             src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExcTBsOHB0dHA2MTFhaWRiYzUyanNwZjV1aXdwejJjd2ptc21jb3UwYyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/26ufhc6irF9vEyoKI/giphy.gif" 
             alt="IOT"/>
            
            </div>
            <div className="service-card_title">
              <button
                className="service-button"
                style={{ border: "2px solid #007BFF", color: "white" }}
              >
                <Link
                  to="/ManufacturingDevOpsSubservicePage"
                  style={{ textDecoration: "none", color: "#007BFF" }}
                >
                  Iot
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSecondSection;

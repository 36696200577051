import React, { useState, useEffect } from "react";
import "./ManufacturingHeader.css";
import { Link } from "react-router-dom";
import logowithoutbg from "../../assets/images/logo_bgremove.png";

const ManufacturingHeader = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isTop = scrollTop < 100;
      setIsScrolled(!isTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className={`small-header ${isScrolled ? "scrolled" : ""}`}>
        <marquee className="blink">
          <p>Factory Digital Transformation</p>
        </marquee>
      </div>
      <nav className={`custom-header-unique ${isScrolled ? "scrolled" : ""}`}>
        <input type="checkbox" id="nav-toggle-unique" />
        <div className="logo-unique">
          <Link to="/ManufacturinglandingPage">
            <img
              className="header-logo-withoutbg"
              alt="Header Logo"
              src={logowithoutbg}
            />
          </Link>
        </div>

        <ul className="links-unique">
          <li>
            <a href="#home-unique">
              <Link className="a-link" to="/ManufacturingLandingPage">
                Home
              </Link>
            </a>
          </li>
          <li>
            <a href="#projects-unique">
              <Link className="a-link" to="/ManufacturingProductPage">
                Products
              </Link>
            </a>
          </li>
          <li>
            <a href="#projects-unique">
              <Link className="a-link" to="/ManufacturingServicePage">
                Services
              </Link>
            </a>
          </li>
          <li>
            <a href="#work-unique">
              <Link className="a-link" to="/ManufacturingCaseStudyPage">
                Case Studies
              </Link>
            </a>
          </li>
          <li>
            <a href="#projects-unique">
              <Link className="a-link" to="/JobBoard">
                Job Openings
              </Link>
            </a>
          </li>
          <li>
            <a href="#contact-unique">
              <Link className="a-link" to="/ManufacturingContactUs">
                Contact
              </Link>
            </a>
          </li>
        </ul>
        <label htmlFor="nav-toggle-unique" className="icon-burger-unique">
          <div className="line-unique"></div>
          <div className="line-unique"></div>
          <div className="line-unique"></div>
        </label>
      </nav>
    </div>
  );
};

export default ManufacturingHeader;

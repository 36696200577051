import React from "react";
import { Link } from "react-router-dom";
import "./LandingPageCaseStudies.css";

const LandingPageCaseStudies = () => {
  return (
    <div className="LandingPageCaseStudiesDiv">
      <div className="caseStudydiv">
        <h1 className="custom-category-name">
          <div className="main-heading-entire-site">Recent Case studies</div>
        </h1>{" "}
        <br />
        <div className="custom-card-category-1">
          <div className="custom-basic-card  custom-basic-card-dark">
            <div className="custom-card-content">
              <span className="custom-card-title">Tata Communications</span>
              <p className="custom-card-text">
                Smart cities have proliferated around the globe in recent years,
                providing new solutions to the problems of a modern city.{" "}
              </p>
            </div>
            <div className="custom-card-link">
              <a title="Read Full">
                <span>
                  <Link to="/TataCommunicationsCaseStudyPage">Read Full</Link>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageCaseStudies;

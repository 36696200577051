// EnterpriseSubCategory.js

import React from "react";
import { Link } from "react-router-dom";

const SubCategory = ({ subcategories }) => {
  const renderCards = (cards, rowStyle) => {
    return cards.map((subcategory, index) => (
      <div key={index} className="card-container-enterprise" style={rowStyle}>
        <div className="card-main-enterprise">
          <img
            src={subcategory.image}
            className="card-img-top-enterprise"
            alt={subcategory.name}
          />
          <div className="card-body-enterprise">
            <h5 className="card-title-enterprise">{subcategory.name}</h5>
            <p className="card-text-enterprise">{subcategory.description}</p>
            <div className="enterprise-subcategory-button">
              <Link to={subcategory.path} className="btn btn-primary">
                View  Products
              </Link>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const getRows = (subcategories) => {
    const numSubcategories = subcategories.length;
    let firstRow, secondRow, rowStyle;

    if (numSubcategories >= 6) {
      firstRow = subcategories.slice(0, 3);
      secondRow = subcategories.slice(3, 6);
      rowStyle = {};
    } else if (numSubcategories === 5) {
      firstRow = subcategories.slice(0, 3);
      secondRow = subcategories.slice(3, 5);
      rowStyle = {};
    } else if (numSubcategories === 4) {
      firstRow = subcategories.slice(0, 2);
      secondRow = subcategories.slice(2, 4);
      rowStyle = { margin: "0 20px" };
    } else if (numSubcategories === 3) {
      firstRow = subcategories.slice(0, 3);
      secondRow = subcategories.slice(3, 4);
      rowStyle = {};
    } else if (numSubcategories === 2) {
      firstRow = subcategories.slice(0, 2);
      secondRow = subcategories.slice(2, 4);
      rowStyle = { margin: "0 20px" };
    } else {
      firstRow = subcategories.slice(0, Math.ceil(numSubcategories / 2));
      secondRow = subcategories.slice(Math.ceil(numSubcategories / 2));
      rowStyle = {};
    }

    return { firstRow, secondRow, rowStyle };
  };

  const { firstRow, secondRow, rowStyle } = getRows(subcategories);

  return (
    <div className="subcategory-container-enterprise">
      <div className="enterprise-products-row">
        {renderCards(firstRow, rowStyle)}
      </div>
      <div className="enterprise-products-row">
        {renderCards(secondRow, rowStyle)}
      </div>
    </div>
  );
};

export default SubCategory;

// ManufacturingCiscoSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingCiscoSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import CiscoISA3000 from "../../../assets/pdf/Cisco-ISA3000.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";

const ManufacturingCiscoSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Next Gen Firewall",
  // };

  const productDataList1 = [
    // Cisco ISA3000
    {
      images: [
        require("../../../assets/productImages/Cisco-ISA3000-1.png"),
        require("../../../assets/productImages/Cisco-ISA3000-2.png"),
        require("../../../assets/productImages/Cisco-ISA3000-1.png"),
        require("../../../assets/productImages/Cisco-ISA3000-2.png"),
      ],
      title: "Cisco ISA3000",
      about:
        "The Cisco® Secure Firewall ISA3000 is a true industrial firewall that provides OT-targeted protection based on proven enterprise-class security.",
      manufactiringFeature: (
        <>
          <li>Enforce security policies in IoT/OT environment</li>
          <li>Certified for deployment in the most demanding industries</li>
          <li>Flexible and secure enterprise-class remote access</li>
        </>
      ),
      downloadLink: CiscoISA3000,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="cisco-page">

      <Helmet>
        <title>CalidTech - FDT Cisco</title>
        <meta name="description" content="Protect your operations with OT Security solutions from Cisco, featuring the Cisco ISA3000 at Calidtech. Enhance your manufacturing security with advanced industrial cybersecurity solutions. Serving Mumbai, Pune, and across India." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation, Cisco security products,India OT monitoring,Pune industrial cybersecurity,Mumbai OT security,Calidtech Cisco products,Industrial security solutions,Cisco ISA3000 security,Manufacturing security systems,Cisco ISA3000 security,OT Security Cisco,Cisco ISA3000,Industrial cybersecurity," />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Protect your operations with OT Security solutions from Cisco, featuring the Cisco ISA3000 at Calidtech. Enhance your manufacturing security with advanced industrial cybersecurity solutions. Serving Mumbai, Pune, and across India." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FDT Cisco" />
        <meta name="twitter:description" content="Protect your operations with OT Security solutions from Cisco, featuring the Cisco ISA3000 at Calidtech. Enhance your manufacturing security with advanced industrial cybersecurity solutions. Serving Mumbai, Pune, and across India." />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://www.calidtech.com/ManufacturingCiscoSubcategoryPage" />
      </Helmet>


      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        OT Security - Cisco
      </div>
      <div className="cisco-subproduct-nav">
        <button
          className={`cisco-subproduct-nav-button ${currentSubproduct === 1 ? "active-cisco-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Next Gen Firewall
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="cisco-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-cisco-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-cisco" : ""}
                >
                  <button
                    className="cisco-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingCiscoSubcategoryPage;

// EnterpriseWirelessPlanningServicesSubservicePage.js

import React, { useState } from "react";
import "./EnterpriseWirelessPlanningServicesSubservicePage.css";
import EnterpriseServicePageNavBar from "../EnterpriseServiceNavbarPage";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import SiteSurveysAndAnalysis from "../../../assets/images/SiteSurveysAndAnalysis.png";
import CapacityPlanning from "../../../assets/images/CapacityPlanning.jpg";
import InterferenceMitigationStrategies from "../../../assets/images/InterferenceMitigationStrategies.jpg";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import EnquiryPopup from "../../../Common Components/EnquiryPopup";
import { Helmet } from 'react-helmet';


function EnterpriseWirelessPlanningServicesSubservicePage() {
  const toggleEnquiryPopup = () => {
    setIsEnquiryPopupOpen(!isEnquiryPopupOpen);
  };
  const [isEnquiryPopupOpen, setIsEnquiryPopupOpen] = useState(false);
  // const handleScrollTo = (elementId) => {
  //   const element = document.getElementById(elementId);
  //   if (element) {
  //     if (!("scrollBehavior" in document.documentElement.style)) {
  //       smoothScrollTo(element.offsetTop, 600);
  //     } else {
  //       element.scrollIntoView({ behavior: "smooth", block: "start" });
  //     }
  //   }
  // };

  // Smooth scroll function

  // const smoothScrollTo = (endX, duration) => {
  //   const startX = window.scrollY || window.pageYOffset;
  //   const distanceX = endX - startX;
  //   const startTime = new Date().getTime();

  //   const easeInOutQuart = (time, from, distance, duration) => {
  //     if ((time /= duration / 2) < 1)
  //       return (distance / 2) * time * time * time * time + from;
  //     return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
  //   };

  //   const timer = setInterval(() => {
  //     const time = new Date().getTime() - startTime;
  //     const newX = easeInOutQuart(time, startX, distanceX, duration);
  //     if (time >= duration) {
  //       clearInterval(timer);
  //     }
  //     window.scroll(0, newX);
  //   }, 1000 / 10); // 60 fps
  // };

  return (
    <div className="enterprise-service-page">
      <Helmet>
                <title>CalidTech-Enterprise Wireless Planning Services</title>
                <meta name="description" content="Your website description here" />
                <meta name="keywords" content="keyword1, keyword2, keyword3" />
                <meta name="author" content="Your Name or Company" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.yourwebsite.com" />
                <meta property="og:site_name" content="Your Website Name" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Your Page Title" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/EnterpriseWirelessPlanningServicesSubservicePage" />
            </Helmet>
      <EnterpriseHeader />
      <EnterpriseServicePageNavBar />
      <header className="enterprise-subservice-header-wireless">
        <div className="enterprise-subheader-text">
          <h1 className="enterprise-subservice-header-heading">
            <span id="enterprise-subservice-header-subheading">
              {" "}
              Wireless Planning Services{" "}
            </span>
          </h1>
          <p className="enterprise-subservice-header-description">
            Wireless planning services involve the strategic design,
            implementation, and optimization of wireless communication networks
            to ensure optimal coverage, performance, and reliability.
          </p>
          <div className="button-container">
            <button className="Downloadpdf">Get PDF</button>
            <button onClick={toggleEnquiryPopup} className="servicesenquiry">
              Enquiry
            </button>
          </div>
        </div>
      </header>
      {/* header end */}

      <div className="enterprise-servive-des">
        <h2 className="main-heading-entire-site">
          Strategic Wireless Solutions, Network Optimization Services,
          Customized Connectivity Solutions
        </h2>
        <p>
          Tailored planning and deployment strategies for seamless wireless
          connectivity across diverse environments.Tailored consulting services
          for designing and optimizing wireless networks to meet specific
          business needs and objectives.Expert guidance and implementation to
          maximize wireless network performance and reliability.
        </p>
      </div>

      <div className="enterprise-subservice-card-div-container">
        <div
          className="enterprise-subservice-card-div"
          style={{ "--clr": "#009688" }}
        >
          <div className="enterprise-subservice-card-img-box">
            <img src={SiteSurveysAndAnalysis} alt="Site Surveys and Analysis" />
          </div>
          <div className="enterprise-subservice-card-div-content">
            <h2 className="enterprise-subservice-card-div-heading">
              Site Surveys and Analysis
            </h2>
            <p className="enterprise-subservice-card-div-description">
              Conducting thorough site surveys and spectrum analysis to assess
              environmental factors and identify potential sources of
              interference.
            </p>
          </div>
        </div>
        <div
          className="enterprise-subservice-card-div"
          style={{ "--clr": "#FF3E7F" }}
        >
          <div className="enterprise-subservice-card-img-box">
            <img src={CapacityPlanning} alt="Capacity Planning" />
          </div>
          <div className="enterprise-subservice-card-div-content">
            <h2 className="enterprise-subservice-card-div-heading">
              Capacity Planning
            </h2>
            <p className="enterprise-subservice-card-div-description">
              Determining the optimal allocation of network resources, including
              bandwidth, channels, and access points, to support current and
              future user demands without sacrificing performance or
              reliability.
            </p>
          </div>
        </div>
        <div
          className="enterprise-subservice-card-div"
          style={{ "--clr": "#03A9F4" }}
        >
          <div className="enterprise-subservice-card-img-box">
            <img
              src={InterferenceMitigationStrategies}
              alt="Interference Mitigation Strategies"
            />
          </div>
          <div className="enterprise-subservice-card-div-content">
            <h2 className="enterprise-subservice-card-div-heading">
              Optimization
            </h2>
            <p className="enterprise-subservice-card-div-description">
              Implementing advanced techniques and technologies to mitigate
              interference from neighboring networks, electronic devices, and
              physical obstructions.
            </p>
          </div>
        </div>
        <EnquiryPopup
          isOpen={isEnquiryPopupOpen}
          onClose={toggleEnquiryPopup}
        />
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
}

export default EnterpriseWirelessPlanningServicesSubservicePage;

import React from "react";
import EnterpriseFeaturebox from "../EntertrpriseComponents/EnterpriseFeaturebox";
import fimage1 from "../../assets/images/EnterpriseLANandWireless.png";
import fimage2 from "../../assets/images/ServerandStorage.png";
import fimage3 from "../../assets/images/NetworkSecurity.png";
import fimage4 from "../../assets/images//FixedWirelessBroadband.png";
import "./EnterpriseFeaturebox.css";

function EnterpriseFeature() {
  return (
    <div id="features">
      <h1>
        <div className="main-heading-entire-site">
          Enterprise Solutions Categories{" "}
        </div>
      </h1>

      <div className="a-container">
        <EnterpriseFeaturebox
          image={fimage1}
          title="Enterprise LAN and Wireless"
          featureLink="/EnterpriseLANAndWirelessCategoryPage"
        />
        <EnterpriseFeaturebox
          image={fimage1}
          title="SMB LAN & Wireless"
          featureLink="/EnterpriseSmallMediumBusinessLANAndWirelessCategoryPage"
        />
        <EnterpriseFeaturebox
          image={fimage2}
          title="Server and Storage Solutions"
          featureLink="/EnterpriseServerAndStorageSolutionCategoryPage"
        />
        <EnterpriseFeaturebox
          image={fimage3}
          title="Network and Cyber Security"
          featureLink="/EnterpriseNetworkAndCybersecurityCategoryPage"
        />
        <EnterpriseFeaturebox
          image={fimage4}
          title="Fixed Wireless Broadband"
          featureLink="/EnterprisePTPAndPtMPUBRCategoryPage"
        />
      </div>
    </div>
  );
}

export default EnterpriseFeature;

import React from "react";
import "./ManuDividingIcons.css";
import DataAnalytics from "./assets/images/DataAnalytics.png";
import OTSecurity from "./assets/images/OTSecurity.png";
import IndustrialCommunicationAndCmputing from "./assets/images/IndustrialCommunicationAndCmputing.png";
import IoT from "./assets/images/IoT.jpg";
import { Link } from "react-router-dom";

const ManuDividingIcons = () => {
  return (
    <div className="ManuDividingIcons-DividingIcons">
      <div className="ManuDividingIcons-icons-Enterprise">
        <a href="#">
          <div className="ManuDividingIcons-DividingIcons-btn ManuDividingIcons-color-telegram">
            <img src={DataAnalytics} alt="Telegram" />
            <Link to="/ManufacturingAnalyticsCategoryPage">
              <p className="ManuDividingIcons-order-1 ManuDividingIcons-google-font ManuDividingIcons-margin-telgram">
                Data Analytics
              </p>
            </Link>
          </div>
        </a>
        <a href="#">
          <div className="ManuDividingIcons-DividingIcons-btn ManuDividingIcons-color-telegram">
            <img src={OTSecurity} alt="Instagram" />
            <Link to="/ManufacturingOTSecurityCategoryPage">
              <p className="ManuDividingIcons-order-1 ManuDividingIcons-google-font ManuDividingIcons-margin-instagram">
                OT Security
              </p>
            </Link>
          </div>
        </a>
        <a href="#">
          <div className="ManuDividingIcons-DividingIcons-btn ManuDividingIcons-color-telegram">
            <img src={IndustrialCommunicationAndCmputing} alt="WhatsApp" />
            <Link to="/ManufacturingIndustrialCommunicationAndComputingCategoryPage">
              <p className="ManuDividingIcons-order-1 ManuDividingIcons-google-font ManuDividingIcons-margin-instagram">
                Industrial Communication and Computing
              </p>
            </Link>
          </div>
        </a>
        <a href="#">
          <div className="ManuDividingIcons-DividingIcons-btn ManuDividingIcons-color-telegram">
            <img src={IoT} alt="WhatsApp" />
            <Link to="/ManufacturingDevOpsSubservicePage">
              <p className="ManuDividingIcons-order-1 ManuDividingIcons-google-font ManuDividingIcons-margin-instagram">
                Internet of Things
              </p>
            </Link>
          </div>
        </a>
      </div>
    </div>
  );
};

export default ManuDividingIcons;

import React, { useState } from 'react';
import './EnquiryPopup.css';

const EnquiryPopup = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [submitted, setSubmitted] = useState(false);

    const handleUserInput = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleUserSubmit = async (e) => {
        e.preventDefault();

        const formUrl = "https://docs.google.com/forms/d/e/1FAIpQLSccF9NRjvWNKVMGHKmQpwEPFZsZxWwzmsz2FEmVBGFkCoDl7Q/formResponse";
        const formDataToSend = {
            "entry.1704858838": formData.name,
            "entry.1014532772": formData.email,
            "entry.1756162571": formData.phone,
            "entry.1858778965": formData.message
        };

        try {
            await fetch(formUrl, {
                method: "POST",
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: new URLSearchParams(formDataToSend).toString()
            });
            setSubmitted(true);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        isOpen && (
            <div className="enquiry-popup-overlay" onClick={onClose}>
                <div className="enquiry-popup" onClick={e => e.stopPropagation()}>
                    <div className="enquiry-header">
                        <div className="enquiry-title">Enquiry Form</div>
                        <button className="close-button" onClick={onClose}>×</button>
                    </div>
                    <div className="enquiry-body">
                        {submitted ? (
                            <div className="message bot">
                                Thank you for your query! We will get back to you shortly with an answer!
                            </div>
                        ) : (
                            <form onSubmit={handleUserSubmit} className="message-form open">
                                <label htmlFor="name">Please give us your name:</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleUserInput}
                                    placeholder="Your Name"
                                    required
                                />
                                <label htmlFor="email">Give us an email where we can contact you:</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleUserInput}
                                    placeholder="Your Email"
                                    required
                                />
                                <label htmlFor="phone">Give us a phone number where we can contact you:</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleUserInput}
                                    placeholder="Your Phone"
                                    required
                                />
                                <label htmlFor="message">Lastly, let us know what is your question:</label>
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleUserInput}
                                    placeholder="Your Message"
                                    required
                                ></textarea>
                                <button className="submit-button" type="submit">Submit</button>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

export default EnquiryPopup;

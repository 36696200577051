// ManufacturingFortiGateSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingFortiGateSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import FGR60F from "../../../assets/pdf/FortiGate-Rugged-60F.pdf";
import FGR60F3G4G from "../../../assets/pdf/FGR-60F-3G4G.pdf";
import FGR70F from "../../../assets/pdf/FGR-70F.pdf";
import FGR70F3G4G from "../../../assets/pdf/FGR-70F-3G4G.pdf";
import FGR70G5G from "../../../assets/pdf/FGR-70G-5G.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";

const ManufacturingFortiGateSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Next Gen Firewall",
  // };

  const productDataList1 = [
    //FGR-60F
    {
      images: [
        require("../../../assets/productImages/FGR-60F-1.jpeg"),
        require("../../../assets/productImages/FGR-60F-2.png"),
        require("../../../assets/productImages/FGR-60F-1.jpeg"),
        require("../../../assets/productImages/FGR-60F-2.png"),
      ],
      title: "FGR-60F",
      about:
        "Ruggedized, indoor, IP20, 4x GE RJ45 ports, 2x shared media ports (supports, 2x GE RJ45 ports or 2x SFP slots), 1x GE RJ45 bypass port pair, 1x RJ45 serial port.",
      manufactiringFeature: (
        <>
          <li>IP Rating: IP20</li>
          <li>Modules: TPM</li>
          <li>Threat Protection: 500 Mbps</li>
        </>
      ),
      downloadLink: FGR60F,
      hyperlinks: [],
    },

    //FGR-60F-3G4G
    {
      images: [
        require("../../../assets/productImages/FGR-60F-3G4G-1.jpeg"),
        require("../../../assets/productImages/FGR-60F-3G4G-1.jpeg"),
        require("../../../assets/productImages/FGR-60F-3G4G-1.jpeg"),
        require("../../../assets/productImages/FGR-60F-3G4G-1.jpeg"),
      ],
      title: "FGR-60F-3G4G",
      about:
        "Ruggedized, indoor, IP20, 4x GE RJ45 ports, 2x shared media ports, 1x GE RJ45 bypass port pair, 1x RJ45 serial port, 1x DB9 serial port, 1x USB port, embedded 3G/4G LTE wireless WAN module.",
      manufactiringFeature: (
        <>
          <li>IP Rating: IP20</li>
          <li>Modules: GPS, TPM</li>
          <li>Threat Protection: 500 Mbps</li>
        </>
      ),
      downloadLink: FGR60F3G4G,
      hyperlinks: [],
    },

    //FGR-70F
    {
      images: [
        require("../../../assets/productImages/FGR-70F-1.jpeg"),
        require("../../../assets/productImages/FGR-70F-2.png"),
        require("../../../assets/productImages/FGR-70F-1.jpeg"),
        require("../../../assets/productImages/FGR-70F-2.png"),
      ],
      title: "FGR-70F",
      about:
        "Ruggedized, indoor, IP40, 4x GE RJ45 LAN ports, 1x GE RJ45 bypass port pair (between PORT3 and PORT4), 2x GE RJ45 WAN ports, 2x SFP slots, 1x RJ45 serial port (data), 1x RJ45 serial port (console).",
      manufactiringFeature: (
        <>
          <li>IP Rating: IP40</li>
          <li>Modules: BLE, DIO, TPM</li>
          <li>Threat Protection: 581 Mbps</li>
        </>
      ),
      downloadLink: FGR70F,
      hyperlinks: [],
    },

    //FGR-70F-3G4G
    {
      images: [
        require("../../../assets/productImages/FGR-70F-3G4G-1.jpeg"),
        require("../../../assets/productImages/FGR-70F-3G4G-1.jpeg"),
        require("../../../assets/productImages/FGR-70F-3G4G-1.jpeg"),
        require("../../../assets/productImages/FGR-70F-3G4G-1.jpeg"),
      ],
      title: "FGR-70F-3G4G",
      about:
        "Ruggedized, indoor, IP40, 4x GE RJ45 LAN ports, 1x GE RJ45 bypass port pair, 2x GE RJ45 WAN ports, 2x SFP slots, 1x RJ45 serial port, 1x RJ45 serial port, 1x USB port, 1x MicroSD card slot.",
      manufactiringFeature: (
        <>
          <li>IP Rating: IP40</li>
          <li>Modules: BLE, DIO, GPS, TPM</li>
          <li>Threat Protection: 581 Mbps</li>
        </>
      ),
      downloadLink: FGR70F3G4G,
      hyperlinks: [],
    },

    //FGR-70G 5G
    {
      images: [
        require("../../../assets/productImages/FGR-70G-5G-1.png"),
        require("../../../assets/productImages/FGR-70G-5G-1.png"),
        require("../../../assets/productImages/FGR-70G-5G-1.png"),
        require("../../../assets/productImages/FGR-70G-5G-1.png"),
      ],
      title: "FGR-70G 5G",
      about:
        "FortiGate Rugged 70G-5G-Dual NGFW ensures highperformance security in industrial and OT environments. Designed for critical infrastructures, it integrates Fortinet's latest security processor.",
      manufactiringFeature: (
        <>
          <li>IP Rating: IP40</li>
          <li>Modules: BLE, DIO, GPS, TPM</li>
          <li>Threat Protection: 1.1 Gbps</li>
        </>
      ),
      downloadLink: FGR70G5G,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="fortiGate-page">

      <Helmet>
        <title>CalidTech - FDT Fortigate</title>
        <meta name="description" content="Enhance your OT security with FortiGate solutions at Calidtech. Explore our range of products including FGR-70G 5G, FGR-70F-3G4G, FGR-70F, FGR-60F-3G4G, and FGR-60F for robust industrial protection. Serving Mumbai, Pune, and across India." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation,India FortiGate solutions,Pune industrial security,OT Security FortiGate,FortiGate FGR-70G 5G,FortiGate FGR-70F-3G4G,FortiGate security products,Industrial cybersecurity solutions,FortiGate FGR-60F,FortiGate FGR-60F-3G4G,FortiGate FGR-70F,FortiGate FGR-70F-3G4G,FortiGate FGR-70G 5G" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Enhance your OT security with FortiGate solutions at Calidtech. Explore our range of products including FGR-70G 5G, FGR-70F-3G4G, FGR-70F, FGR-60F-3G4G, and FGR-60F for robust industrial protection. Serving Mumbai, Pune, and across India." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FDT Fortigate" />
        <meta name="twitter:description" content="Enhance your OT security with FortiGate solutions at Calidtech. Explore our range of products including FGR-70G 5G, FGR-70F-3G4G, FGR-70F, FGR-60F-3G4G, and FGR-60F for robust industrial protection. Serving Mumbai, Pune, and across India." />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Enhance your OT security with FortiGate solutions at Calidtech. Explore our range of products including FGR-70G 5G, FGR-70F-3G4G, FGR-70F, FGR-60F-3G4G, and FGR-60F for robust industrial protection. Serving Mumbai, Pune, and across India." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/ManufacturingFortiGateSubcategoryPage" />
      </Helmet>



      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        OT Security - FortiGate
      </div>
      <div className="fortiGate-subproduct-nav">
        <button
          className={`fortiGate-subproduct-nav-button ${currentSubproduct === 1 ? "active-fortiGate-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Next Gen Firewall
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="fortiGate-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-fortiGate-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-fortiGate" : ""}
                >
                  <button
                    className="fortiGate-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingFortiGateSubcategoryPage;

import React from "react";
import "./ServiceSecondSectionEnt.css";
import { Link } from "react-router-dom";

const ServiceSecondSection = () => {
  return (
    <div className="servicesecond">
      <h2 className="servicecate" style={{ color: "white" }}>
        <div className="main-heading-entire-site">Service Categories</div>
      </h2>

      <div className="service-cards-list">
        <div className="service-card1 service-card2">
          <div className="service-card_image">
            <img
              src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExZG13ZWtkMWVmNnZndmJ5Ym5xMHIyc2pjOXd4MzJpaTZjeTIxa2k2bCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/f74WQIhbzBKAusL2v1/giphy-downsized-large.gif"
              alt="Wireless Planning"
            />
          </div>
          <div className="service-card_title">
            <button
              className="service-button"
              style={{ border: "2px solid #007BFF", color: "white" }}
            >
              <Link
                to="/EnterpriseDataCenterConsultingSubServicePage"
                style={{ textDecoration: "none", color: "white" }}
              >
                Data Center consulting
              </Link>
            </button>
          </div>
        </div>

        <div className="service-card1 service-card2">
          <div className="service-card_image">
            <img
              src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExYmdzcTZkcXdqbWNpdDJleXBuZDJwZGZuMHJvb2NvcTlicG04NTh0MSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/MaXOUjkV73aO4/giphy.gif"
              alt="Wireless Planning"
            />
          </div>
          <div className="service-card_title">
            <button
              className="service-button"
              style={{ border: "2px solid #007BFF", color: "white" }}
            >
              <Link
                to="/EnterpriseWirelessPlanningServicesSubservicePage"
                style={{ textDecoration: "none", color: "white" }}
              >
                Wireless Planning
              </Link>
            </button>
          </div>
        </div>

        <div className="service-card1 service-card3">
          <div className="service-card_image">
            <img
              src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbGR6c3UxMHNpdmg5dGZramQ2M3lvZXFnNTdhOTA2NHZ5N3MzbXkzZiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/l41YvpiA9uMWw5AMU/giphy.gif"
              alt="DevOps"
            />
          </div>
          <div className="service-card_title">
            <button
              className="service-button"
              style={{ border: "2px solid #007BFF", color: "white" }}
            >
              <Link
                to="/EnterpriseStructuredCablingSubservicePage"
                style={{ textDecoration: "none", color: "white" }}
              >
                Structured Cabling
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSecondSection;

import React from 'react';
import './ProductsLinked.css';

const ProductsLinked = ({ products }) => {
    // const productsRef = useRef(null);

    // const handleArrowClick = (link) => {
    //     const element = document.getElementById(link);
    //     if (element) {
    //         window.scrollTo({
    //             top: element.offsetTop - 100,
    //             behavior: 'smooth'
    //         });
    //     }
    // };

    // useEffect(() => {
    //     const arrow = document.querySelector('.arrow-popup');
    //     let position = 0;
    //     let direction = 1;
    //     const bobbingAnimation = setInterval(() => {
    //         if (position >= 10 || position <= 0) {
    //             direction *= -1;
    //         }
    //         position += direction;
    //         arrow.style.transform = `translate(0, ${position}px)`;
    //     }, 50);

    //     const popInAnimation = setInterval(() => {
    //         arrow.classList.add('pop-in');
    //         setTimeout(() => {
    //             arrow.classList.remove('pop-in');
    //         }, 3000);
    //     }, 100000);

    //     return () => {
    //         clearInterval(bobbingAnimation);
    //         clearInterval(popInAnimation);
    //     };
    // }, []);

    return (
        <div>
            {/* <div className="arrow-popup" onClick={() => handleArrowClick(products[0].name)}>
                &darr; See the products linked to this case study
            </div> */}
            {/* <div className="products-linked-container" ref={productsRef}> */}
            <div className="products-linked-container">
                <h2>Products linked to this case study:</h2>
                <div className="products-grid">
                    {products.map((product) => (
                        <div key={product.id} className="product-card">
                            {/* <img src={product.image} alt={product.name} className="product-image" /> */}
                            <p className="product-name">{product.name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductsLinked;
// EnterpriseServicePageNavBar.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./EnterpriseServicePageNavBar.css";

const EnterpriseServicePageNavBar = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  // const [activeSubcategory, setActiveSubcategory] = useState(null);

  const categories = [
    { name: "Data Center Consulting" },
    { name: "Wireless Planning Services" },
    { name: "Structured Cabling" },
    { name: "" },
  ];

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCategoryClick = (categoryName) => {
    if (activeCategory !== categoryName) {
      setActiveCategory(categoryName);

      // setActiveSubcategory(null); // Reset active subcategory when category changes
      switch (categoryName) {
        case "Data Center Consulting":
          navigate("/EnterpriseDataCenterConsultingSubServicePage");
          break;
        case "Wireless Planning Services":
          navigate("/EnterpriseWirelessPlanningServicesSubservicePage");
          break;
        case "Structured Cabling":
          navigate("/EnterpriseStructuredCablingSubservicePage");
          break;
        // case 'OT Security':
        //   navigate('/ManufacturingOTSecurityCategoryPage');
        //   break;
        // Add more cases for other categories if needed
        default:
          break;
      }
    }
  };

  // const handleSubcategoryClick = (subcategoryName) => {
  //   if (activeSubcategory !== subcategoryName) {
  //     setActiveSubcategory(subcategoryName);
  //     switch (subcategoryName) {
  //       case 'Hilscher':
  //         navigate('/ManufacturingHilscherSubcategoryPage');
  //         break;
  //       case 'Anybus':
  //         navigate('/ManufacturingAnybusSubcategoryPage');
  //         break;
  //       case 'Moxa':
  //         navigate('/ManufacturingMoxaSubcategoryPage');
  //         break;
  //       case 'Teltonika':
  //         navigate('/ManufacturingTeltonikaSubcategoryPage');
  //         break;
  //       case 'Dalnex':
  //         navigate('/ManufacturingDalnexSubcategoryPage');
  //         break;
  //       case 'Ewon':
  //         navigate('/ManufacturingEwonSubcategoryPage');
  //         break;
  //       case 'Dalnex':
  //         navigate('/ManufacturingDalnexSubcategoryPage');
  //         break;
  //       case 'Dalnex':
  //         navigate('/ManufacturingDalnexSubcategoryPage');
  //         break;
  //       case 'Dalnex':
  //         navigate('/ManufacturingDalnexSubcategoryPage');
  //       break;
  //       case 'P A':
  //         navigate('/ManufacturingPASubcategoryPage');
  //         break;
  //       case 'FortiGate':
  //         navigate('/ManufacturingFortiGateSubcategoryPage');
  //         break;
  //       case 'FortiGate':
  //         navigate('/ManufacturingFortiGateSubcategoryPage');
  //         break;
  //       // Add cases for other subcategories if needed
  //       default:
  //         break;
  //     }
  //   }
  // };

  return (
    <nav
      id="enterprise-service-navbar"
      className={isMobileMenuOpen ? "active-enterprise-service" : ""}
    >
      <button
        className="enterprise-mobile-menu-icon-service"
        onClick={toggleMobileMenu}
      >
        ☰
      </button>
      <ul
        className={`enterprise-nav-categories-service ${
          isMobileMenuOpen ? "mobile-menu" : ""
        }`}
      >
        {categories.map((category) => (
          <li
            key={category.name}
            className={`enterprise-li-navbar ${
              activeCategory === category.name
                ? "active-enterprise-service"
                : ""
            }`}
          >
            <button
              className="enterprise-button-navbar-service enterprise-navbar-category-name"
              onClick={() => handleCategoryClick(category.name)}
            >
              {category.name}
            </button>
            {/* <ul className="manufacturing-subcategories-navbar">
              {category.subcategories.map((subcategory, index) => (
                <li key={index}>
                  <button className="manufacturing-subcategory-name-navbar" onClick={() => handleSubcategoryClick(subcategory)}>
                    {subcategory}
                  </button>
                </li>
              ))}
            </ul> */}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default EnterpriseServicePageNavBar;

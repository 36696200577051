// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SplashScreen from './SplashScreen';
import DividingPage from './DividingPage';
import './App.css';
import ScrollToTop from './Common Components/ScrollToTop';

// Job Imports
import JobBoard from './JobBoard';
import ICCCJob from './JobPages/ICCCJob';
import IoTEngineer from './JobPages/IoTEngineer';
import TechnicalBusinessDevelopmentExecutive from './JobPages/TechnicalBusinessDevelopmentExecutive';

//Enterprise Imports 
import EnterpriceServicePage from './Enterprise/EnterprisePages/EnterpriseServicePage';
import EnterpriseProductPage from './Enterprise/EnterprisePages/EnterpriseProductPage';
import EnterpriseLandingPage from './Enterprise/EnterprisePages/EnterpriseLandingPage';
import EnterpriseLANAndWirelessCategoryPage from './Enterprise/EnterprisePages/EnterpriseCategoryPage/EnterpriseLANAndWirelessCategoryPage';
import EnterpriseServerAndStorageSolutionCategoryPage from './Enterprise/EnterprisePages/EnterpriseCategoryPage/EnterpriseServerAndStorageSolutionCategoryPage';
import EnterpriseNetworkAndCybersecurityCategoryPage from './Enterprise/EnterprisePages/EnterpriseCategoryPage/EnterpriseNetworkAndCybersecurityCategoryPage';
import EnterprisePTPAndPtMPUBRCategoryPage from './Enterprise/EnterprisePages/EnterpriseCategoryPage/EnterprisePTPAndPtMPUBRCategoryPage';
import EnterpriseRuckusSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseRuckusSubcategoryPage';
import EnterpriseJuniperMistSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseJuniperMistSubcategoryPage';
import EnterpriseHPEArubaSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseHPEArubaSubcategoryPage';
import EnterpriseHPSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseHPSubcategoryPage';
import EnterpriseDellSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseDellSubcategoryPage';
import EnterpriseLenovoSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseLenovoSubcategoryPage';
import EnterprisePaloAltoSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterprisePaloAltoSubcategoryPage';
import EnterpriseFortiGateSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseFortiGateSubcategoryPage';
import EnterpriseSophosSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseSophosSubcategoryPage';
import EnterpriseAAAHotspotCaptivePortalserverSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseAAAHotspotCaptivePortalserverSubcategoryPage';
import EnterpriseCiscoSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseCiscoSubcategoryPage';
import EnterpriseCambiumSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseCambiumSubcategoryPage';
import EnterpriseRadwinSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseRadwinSubcategoryPage';
import EnterpriseMimosaSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseMimosaSubcategoryPage';
import EnterpriseAltaiSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseAltaiSubcategoryPage';
import EnterpriceProductComponent from './Enterprise/EntertrpriseComponents/EnterpriceProductComponent';
import EnterpriseCategoryPageNavBar from './Enterprise/EnterprisePages/EnterpriseCategoryPageNavBar';
import EnterpriseContactUs from './Enterprise/EnterprisePages/EnterpriseContactUs';
import ComingSoon from './Enterprise/EntertrpriseComponents/ComingSoon';
import EnterpriseCaseStudyPage from './Enterprise/EnterprisePages/EnterpriseCaseStudyPage';
import AxxelaCaseStudyPage from './Enterprise/EnterprisePages/EnterpriseCaseStudies/AxxelaCaseStudyPage'
import IciciCaseStudyPage from './Enterprise/EnterprisePages/EnterpriseCaseStudies/IciciCaseStudyPage'
import KarkinosCaseStudyPage from './Enterprise/EnterprisePages/EnterpriseCaseStudies/KarkinosCaseStudyPage'
import EdelweissCaseStudyPage from './Enterprise/EnterprisePages/EnterpriseCaseStudies/EdelweissCaseStudyPage'
import EnterpriseSmallMediumBusinessLANAndWirelessCategoryPage from './Enterprise/EnterprisePages/EnterpriseCategoryPage/EnterpriseSmallMediumBusinessLANAndWirelessCategoryPage';
import EnterpriseSwitchingSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseSwitchingSubcategoryPage';
import EnterpriseWirelessSubcategoryPage from './Enterprise/EnterprisePages/EnterpriseSubcategoryPage/EnterpriseWirelessSubcategoryPage';

// Manufacturing Imports
import ManufacturingLandingPage from './Manufacturing/ManufacturingPages/ManufacturingLandingPage';
import ManufacturingIndustrialEdgeConnectivityCategoryPage from './Manufacturing/ManufacturingPages/ManufacturingCategoryPage/ManufacturingIndustrialEdgeConnectivityCategoryPage';
import ManufacturingIndustrialCommunicationAndComputingCategoryPage from './Manufacturing/ManufacturingPages/ManufacturingCategoryPage/ManufacturingIndustrialCommunicationAndComputingCategoryPage';
import ManufacturingAnalyticsCategoryPage from './Manufacturing/ManufacturingPages/ManufacturingCategoryPage/ManufacturingAnalyticsCategoryPage';
import ManufacturingOTSecurityCategoryPage from './Manufacturing/ManufacturingPages/ManufacturingCategoryPage/ManufacturingOTSecurityCategoryPage';
import ManufacturingHelmholzSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingHelmholzSubcategoryPage';
import ManufacturingAnybusSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingAnybusSubcategoryPage';
import ManufacturingMoxaSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingMoxaSubcategoryPage';
import ManufacturingDalnexSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingDalnexSubcategoryPage';
import ManufacturingIndustrialSwitchesAndRoutersSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingIndustrialSwitchesAndRoutersSubcategoryPage';
import ManufacturingSmartIoT4GGatewaySubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingSmartIoT4GGatewaySubcategoryPage';
import ManufacturingIndustrialWiFiSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingIndustrialWiFiSubcategoryPage';
import ManufacturingPaloAltoSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingPaloAltoSubcategoryPage';
import ManufacturingFortiGateSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingFortiGateSubcategoryPage';
import ManufacturingProductPage from './Manufacturing/ManufacturingPages/ManufacturingProductPage';
import ManufacturingCiscoSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingCiscoSubcategoryPage';
import ManufacturingContactUs from './Manufacturing/ManufacturingPages/ManufacturingContactUs';
import ManufacturingTeltonikaSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingTeltonikaSubcategoryPage';
import ManufacturingBlackbearSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingBlackbearSubcategoryPage';
import ManufacturingZscalerSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingZscalerSubcategoryPage';
import ManufacturingCaseStudyPage from './Manufacturing/ManufacturingPages/ManufacturingCaseStudyPage';
import TataCommunicationsCaseStudyPage from './Manufacturing/ManufacturingPages/ManufacturingCaseStudies/TataCommunicationsCaseStudyPage';

// Service Page Imports
import ManufacturingWarehouseManagementSubservicePage from './Manufacturing/ManufacturingPages/ManufacturingSubservicePage/ManufacturingWarehouseManagementSubservicePage';
import ManufacturingServicePageNavBar from './Manufacturing/ManufacturingPages/ManufacturingServicePageNavBar';
import ManufacturingServicePage from './Manufacturing/ManufacturingPages/ManufacturingServicePage';
import ManufacturingDevOpsSubservicePage from './Manufacturing/ManufacturingPages/ManufacturingSubservicePage/ManufacturingDevOpsSubservicePage';
import ManufacturingWirelessPlanningSubservicePage from './Manufacturing/ManufacturingPages/ManufacturingSubservicePage/ManufacturingWirelessPlanningSubservicePage';
import ManufacturingSmartEnergyMetersSubcategoryPage from './Manufacturing/ManufacturingPages/ManufacturingSubcategoryPage/ManufacturingSmartEnergyMetersSubcategoryPage';
import EnterpriseServicePage from './Enterprise/EnterprisePages/EnterpriseServicePage';
import EnterpriseServicePageNavBar from './Enterprise/EnterprisePages/EnterpriseServiceNavbarPage';
import EnterpriseDataCenterConsultingSubServicePage from './Enterprise/EnterprisePages/EnterpriseSubServicePage/EnterpriseDataCenterConsultingSubServicePage';
import EnterpriseStructuredCablingSubservicePage from './Enterprise/EnterprisePages/EnterpriseSubServicePage/EnterpriseStructuredCablingSubservicePage';
import EnterpriseWirelessPlanningServicesSubservicePage from './Enterprise/EnterprisePages/EnterpriseSubServicePage/EnterpriseWirelessPlanningServicesSubservicePage';


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2500);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <Router>
      <ScrollToTop />
      <Routes>

        <Route path="/" element={<DividingPage />} />
        <Route path="/" element={<DividingPage />} />
        <Route path="/DividingPage" element={<DividingPage />} />

        {/* Job Links */}
        <Route path="/JobBoard" element={<JobBoard />} />
        <Route path="/ICCCJob" element={<ICCCJob />} />
        <Route path="/IoTEngineer" element={<IoTEngineer />} />
        <Route path="/TechnicalBusinessDevelopmentExecutive" element={<TechnicalBusinessDevelopmentExecutive />} />

        {/* Enterprise pages */}
        <Route path="/EnterpriseLandingPage" element={<EnterpriseLandingPage />} />
        <Route path="/EnterpriceServicePage" element={<EnterpriceServicePage />} />
        <Route path="/EnterpriseProductPage" element={<EnterpriseProductPage />} />

        <Route path="/EnterpriseLANAndWirelessCategoryPage" element={<EnterpriseLANAndWirelessCategoryPage />} />
        <Route path="/EnterpriseServerAndStorageSolutionCategoryPage" element={<EnterpriseServerAndStorageSolutionCategoryPage />} />
        <Route path="/EnterpriseNetworkAndCybersecurityCategoryPage" element={<EnterpriseNetworkAndCybersecurityCategoryPage />} />
        <Route path="/EnterprisePTPAndPtMPUBRCategoryPage" element={<EnterprisePTPAndPtMPUBRCategoryPage />} />
        <Route path="/EnterpriseJuniperMistSubcategoryPage" element={<EnterpriseJuniperMistSubcategoryPage />} />
        <Route path="/EnterpriseRuckusSubcategoryPage" element={<EnterpriseRuckusSubcategoryPage />} />
        <Route path="/EnterpriseHPEArubaSubcategoryPage" element={<EnterpriseHPEArubaSubcategoryPage />} />
        <Route path="/EnterpriseHPSubcategoryPage" element={<EnterpriseHPSubcategoryPage />} />
        <Route path="/EnterpriseDellSubcategoryPage" element={<EnterpriseDellSubcategoryPage />} />
        <Route path="/EnterpriseLenovoSubcategoryPage" element={<EnterpriseLenovoSubcategoryPage />} />
        <Route path="/EnterprisePaloAltoSubcategoryPage" element={<EnterprisePaloAltoSubcategoryPage />} />
        <Route path="/EnterpriseFortiGateSubcategoryPage" element={<EnterpriseFortiGateSubcategoryPage />} />
        <Route path="/EnterpriseSophosSubcategoryPage" element={<EnterpriseSophosSubcategoryPage />} />
        <Route path="/EnterpriseAAAHotspotCaptivePortalserverSubcategoryPage" element={<EnterpriseAAAHotspotCaptivePortalserverSubcategoryPage />} />
        <Route path="/EnterpriseCambiumSubcategoryPage" element={<EnterpriseCambiumSubcategoryPage />} />
        <Route path="/EnterpriseCiscoSubcategoryPage" element={<EnterpriseCiscoSubcategoryPage />} />
        <Route path="/EnterpriceProductComponent" element={<EnterpriceProductComponent />} />
        <Route path="/EnterpriseProductPage" element={<EnterpriseProductPage />} />
        <Route path="/EnterpriseRadwinSubcategoryPage" element={<EnterpriseRadwinSubcategoryPage />} />
        <Route path="/EnterpriseMimosaSubcategoryPage" element={<EnterpriseMimosaSubcategoryPage />} />
        <Route path="/EnterpriseAltaiSubcategoryPage" element={<EnterpriseAltaiSubcategoryPage />} />
        <Route path="/EnterpriseCategoryPageNavBar" element={<EnterpriseCategoryPageNavBar />} />
        <Route path="/EnterpriseContactUs" element={<EnterpriseContactUs />} />
        <Route path="/ComingSoon" element={<ComingSoon />} />
        <Route path="/EnterpriseCaseStudyPage" element={<EnterpriseCaseStudyPage />} />
        <Route path="/AxxelaCaseStudyPage" element={<AxxelaCaseStudyPage />} />
        <Route path="/IciciCaseStudyPage" element={<IciciCaseStudyPage />} />
        <Route path="/KarkinosCaseStudyPage" element={<KarkinosCaseStudyPage />} />
        <Route path="/EdelweissCaseStudyPage" element={<EdelweissCaseStudyPage />} />
        <Route path="/EnterpriseProductPage" element={<EnterpriseProductPage />} />
        <Route path="/EnterpriseSmallMediumBusinessLANAndWirelessCategoryPage" element={<EnterpriseSmallMediumBusinessLANAndWirelessCategoryPage />} />
        <Route path="/EnterpriseSwitchingSubcategoryPage" element={<EnterpriseSwitchingSubcategoryPage />} />
        <Route path="/EnterpriseWirelessSubcategoryPage" element={<EnterpriseWirelessSubcategoryPage />} />

        {/* Manufacturing pages */}
        <Route path="/ManufacturingLandingPage" element={<ManufacturingLandingPage />} />
        <Route path="/ManufacturingIndustrialEdgeConnectivityCategoryPage" element={<ManufacturingIndustrialEdgeConnectivityCategoryPage />} />
        <Route path="/ManufacturingIndustrialCommunicationAndComputingCategoryPage" element={<ManufacturingIndustrialCommunicationAndComputingCategoryPage />} />
        <Route path="/ManufacturingAnalyticsCategoryPage" element={<ManufacturingAnalyticsCategoryPage />} />
        <Route path="/ManufacturingOTSecurityCategoryPage" element={<ManufacturingOTSecurityCategoryPage />} />
        <Route path="/ManufacturingHelmholzSubcategoryPage" element={<ManufacturingHelmholzSubcategoryPage />} />
        <Route path="/ManufacturingAnybusSubcategoryPage" element={<ManufacturingAnybusSubcategoryPage />} />
        <Route path="/ManufacturingDalnexSubcategoryPage" element={<ManufacturingDalnexSubcategoryPage />} />
        <Route path="/ManufacturingMoxaSubcategoryPage" element={<ManufacturingMoxaSubcategoryPage />} />
        <Route path="/ManufacturingIndustrialSwitchesAndRoutersSubcategoryPage" element={<ManufacturingIndustrialSwitchesAndRoutersSubcategoryPage />} />
        <Route path="/ManufacturingSmartIoT4GGatewaySubcategoryPage" element={<ManufacturingSmartIoT4GGatewaySubcategoryPage />} />
        <Route path="/ManufacturingIndustrialWiFiSubcategoryPage" element={<ManufacturingIndustrialWiFiSubcategoryPage />} />
        <Route path="/ManufacturingPaloAltoSubcategoryPage" element={<ManufacturingPaloAltoSubcategoryPage />} />
        <Route path="/ManufacturingFortiGateSubcategoryPage" element={<ManufacturingFortiGateSubcategoryPage />} />
        <Route path="/ManufacturingSmartEnergyMetersSubcategoryPage" element={<ManufacturingSmartEnergyMetersSubcategoryPage />} />
        <Route path="/ManufacturingCiscoSubcategoryPage" element={<ManufacturingCiscoSubcategoryPage />} />
        <Route path="/ManufacturingTeltonikaSubcategoryPage" element={<ManufacturingTeltonikaSubcategoryPage />} />
        <Route path="/ManufacturingBlackbearSubcategoryPage" element={<ManufacturingBlackbearSubcategoryPage />} />
        <Route path="/ManufacturingZscalerSubcategoryPage" element={<ManufacturingZscalerSubcategoryPage />} />
        <Route path="/ManufacturingProductPage" element={<ManufacturingProductPage />} />
        <Route path="/ManufacturingContactUs" element={<ManufacturingContactUs />} />
        <Route path="/ManufacturingCaseStudyPage" element={<ManufacturingCaseStudyPage />} />
        <Route path="/TataCommunicationsCaseStudyPage" element={<TataCommunicationsCaseStudyPage />} />

        {/* Service Page */}
        <Route path="/ManufacturingWarehouseManagementSubservicePage" element={<ManufacturingWarehouseManagementSubservicePage />} />
        <Route path="/EnterpriseStructuredCablingSubservicePage" element={<EnterpriseStructuredCablingSubservicePage />} />
        <Route path="/EnterpriseWirelessPlanningServicesSubservicePage" element={<EnterpriseWirelessPlanningServicesSubservicePage />} />
        <Route path="/EnterpriseDataCenterConsultingSubServicePage" element={<EnterpriseDataCenterConsultingSubServicePage />} />
        <Route path="/EnterpriseServicePageNavBar" element={<EnterpriseServicePageNavBar />} />
        <Route path="/EnterpriseServicePage" element={<EnterpriseServicePage />} />
        <Route path="/ManufacturingServicePageNavBar" element={<ManufacturingServicePageNavBar />} />
        <Route path="/ManufacturingServicePage" element={<ManufacturingServicePage />} />
        <Route path="/ManufacturingWirelessPlanningSubservicePage" element={<ManufacturingWirelessPlanningSubservicePage />} />
        <Route path="/ManufacturingDevOpsSubservicePage" element={<ManufacturingDevOpsSubservicePage />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;

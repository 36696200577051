import React from "react";
import "./ManufacturingHero.css";
import play_icon from "../../assets/images/play_icon.png";
import pause_icon from "../../assets/images/pause_icon.png";

const ManufacturingHero = ({
  heroData,
  setHeroCount,
  heroCount,
  setPlayStatus,
  playStatus,
}) => {
  return (
    <div className="ManufacturingHero">
      <div className="ManufacturingHero-text">
        <p>{heroData.text1}</p>
        <p>{heroData.text2}</p>
      </div>
      <div className="ManufacturingHero-dot-play">
        <ul className="ManufacturingHero-dots">
          <li
            onClick={() => setHeroCount(0)}
            className={heroCount === 0 ? "hero-dot blue" : "hero-dot"}
          ></li>
          <li
            onClick={() => setHeroCount(1)}
            className={heroCount === 1 ? "hero-dot blue" : "hero-dot"}
          ></li>
          <li
            onClick={() => setHeroCount(2)}
            className={heroCount === 2 ? "hero-dot blue" : "hero-dot"}
          ></li>
        </ul>
        <div className="ManufacturingHero-play">
          <img
            onClick={() => setPlayStatus(!playStatus)}
            src={playStatus ? pause_icon : play_icon}
            alt=""
          />
          <p>Watch Video</p>
        </div>
      </div>
    </div>
  );
};

export default ManufacturingHero;

// EnterpriseStructuredCablingSubservicePage.js

import React, { useState } from "react";
import "./EnterpriseStructuredCablingSubservicePage.css";
import EnterpriseServicePageNavBar from "../EnterpriseServiceNavbarPage";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import Scalability from "../../../assets/images/Scalability.jpg";
import PerformanceAndReliability from "../../../assets/images/Performance-and-Reliability.png";
import OrganizationAndManagement from "../../../assets/images/Optimization-and-Efficiency-Improvement.jpg";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import StructuredCabling from "../../../assets/pdf/StructuredCabling.docx";
import EnquiryPopup from "../../../Common Components/EnquiryPopup";
import { Helmet } from 'react-helmet';


function EnterpriseStructuredCablingSubservicePage() {
  const toggleEnquiryPopup = () => {
    setIsEnquiryPopupOpen(!isEnquiryPopupOpen);
  };
  const [isEnquiryPopupOpen, setIsEnquiryPopupOpen] = useState(false);
  // const handleScrollTo = (elementId) => {
  //   const element = document.getElementById(elementId);
  //   if (element) {
  //     if (!("scrollBehavior" in document.documentElement.style)) {
  //       smoothScrollTo(element.offsetTop, 600);
  //     } else {
  //       element.scrollIntoView({ behavior: "smooth", block: "start" });
  //     }
  //   }
  // };

  // Smooth scroll function

  // const smoothScrollTo = (endX, duration) => {
  //   const startX = window.scrollY || window.pageYOffset;
  //   const distanceX = endX - startX;
  //   const startTime = new Date().getTime();

  //   const easeInOutQuart = (time, from, distance, duration) => {
  //     if ((time /= duration / 2) < 1)
  //       return (distance / 2) * time * time * time * time + from;
  //     return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
  //   };

  //   const timer = setInterval(() => {
  //     const time = new Date().getTime() - startTime;
  //     const newX = easeInOutQuart(time, startX, distanceX, duration);
  //     if (time >= duration) {
  //       clearInterval(timer);
  //     }
  //     window.scroll(0, newX);
  //   }, 1000 / 10); // 60 fps
  // };

  return (
    <div className="enterprise-service-page">
      <Helmet>
                <title>CalidTech-Enterprise Structure Cabling</title>
                <meta name="description" content="Your website description here" />
                <meta name="keywords" content="keyword1, keyword2, keyword3" />
                <meta name="author" content="Your Name or Company" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.yourwebsite.com" />
                <meta property="og:site_name" content="Your Website Name" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Your Page Title" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/EnterpriseStructuredCablingSubservicePage" />
            </Helmet>
      <EnterpriseHeader />
      <EnterpriseServicePageNavBar />
      <header className="enterprise-subservice-header-cable">
        <div className="enterprise-subheader-text">
          <h1 className="enterprise-subservice-header-heading">
            <span id="enterprise-subservice-header-subheading">
              {" "}
              Structured Cabling{" "}
            </span>
          </h1>
          <p className="enterprise-subservice-header-description">
            Structured cabling refers to the standardized and organized approach
            to designing, installing, and managing cabling systems within a
            building or campus environment.
          </p>
          <div className="button-container">
            <a
              href={StructuredCabling}
              download="StructuredCabling.docx"
              className="Downloadpdf"
            >
              Get PDF
            </a>
            <button onClick={toggleEnquiryPopup} className="servicesenquiry">
              Enquiry
            </button>
          </div>
        </div>
      </header>

      <div className="enterprise-servive-des">
        <h2 className="main-heading-entire-site">
          Network Backbone Solutions, Connectivity Framework Design, Scalable
          Wiring Infrastructure
        </h2>
        <p>
          Structured cabling is a standardized and organized approach to
          designing and installing a cabling infrastructure that supports
          multiple hardware uses and applications, such as data, voice, video,
          and security systems. This system uses a hierarchical design and
          carefully planned layout of components, including cables, connectors,
          distribution panels, and patch panels. The primary objectives of
          structured cabling are to ensure maximum efficiency, flexibility, and
          reliability of the network infrastructure by following set standards
          and best practices for design, installation, and maintenance.
          Structured cabling is crucial for modern business networks, enhancing
          network performance, providing flexibility and scalability, reducing
          downtime, and improving network management.
        </p>
      </div>

      <div className="enterprise-subservice-card-div-container">
        <div
          className="enterprise-subservice-card-div"
          style={{ "--clr": "#009688" }}
        >
          <div className="enterprise-subservice-card-img-box">
            <img src={Scalability} alt="Scalability" />
          </div>
          <div className="enterprise-subservice-card-div-content">
            <h2 className="enterprise-subservice-card-div-heading">
              Scalability
            </h2>
            <p className="enterprise-subservice-card-div-description">
              Structured cabling systems are designed to be easily scalable,
              allowing organizations to expand their network infrastructure as
              needed without requiring major overhauls.
            </p>
          </div>
        </div>
        <div
          className="enterprise-subservice-card-div"
          style={{ "--clr": "#FF3E7F" }}
        >
          <div className="enterprise-subservice-card-img-box">
            <img
              src={OrganizationAndManagement}
              alt="Organization And Management"
            />
          </div>
          <div className="enterprise-subservice-card-div-content">
            <h2 className="enterprise-subservice-card-div-heading">
              Organization
            </h2>
            <p className="enterprise-subservice-card-div-description">
              Structured cabling promotes tidy and organized cable management,
              reducing clutter and minimizing the risk of cable tangling,
              damage, or accidental disconnection.
            </p>
          </div>
        </div>
        <div
          className="enterprise-subservice-card-div"
          style={{ "--clr": "#03A9F4" }}
        >
          <div className="enterprise-subservice-card-img-box">
            <img
              src={PerformanceAndReliability}
              alt="Performance And Reliability"
            />
          </div>
          <div className="enterprise-subservice-card-div-content">
            <h2 className="enterprise-subservice-card-div-heading">
              Performance And Reliability
            </h2>
            <p className="enterprise-subservice-card-div-description">
              By adhering to industry standards and best practices, structured
              cabling systems optimize signal integrity and minimize signal
              degradation, resulting in consistent and reliable network
              performance.
            </p>
          </div>
        </div>
        <EnquiryPopup
          isOpen={isEnquiryPopupOpen}
          onClose={toggleEnquiryPopup}
        />
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
}

export default EnterpriseStructuredCablingSubservicePage;

// ManufacturingOTSecurityCategoryPage.js

import React from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ManufacturingOTSecurityCategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import heroprod from "../../../assets/images/ot_security.jpeg";
import heroprod1 from "../../../assets/images/otsecurity2.jpg";
import routersImage from "../../../assets/images/palo1.webp";
import switchesImage from "../../../assets/images/fortig.jpg";
import cisco from "../../../assets/images/cisco.jpg";
import zscaler from "../../../assets/images/zscaler-logo.png";
import blackbear from "../../../assets/images/blackbear.webp";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import SubCategory from "../../ManufacturingComponents/ManufacturingSubCategory";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";

const ManufacturingOTSecurityCategoryPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const subcategories = [
    {
      name: "Palo Alto",
      image: routersImage,
      path: "/ManufacturingPaloAltoSubcategoryPage",
      description: "",
    },
    {
      name: "FortiGate",
      image: switchesImage,
      path: "/ManufacturingFortiGateSubcategoryPage",
      description: "",
    },
    {
      name: "Cisco",
      image: cisco,
      path: "/ManufacturingCiscoSubcategoryPage",
      description: "",
    },
    {
      name: "Zscaler",
      image: zscaler,
      path: "/ManufacturingZscalerSubcategoryPage",
      description: "",
    },
    {
      name: "Blackbear",
      image: blackbear,
      path: "/ManufacturingBlackbearSubcategoryPage",
      description: "",
    },
  ];

  return (
    <div className="manufacturing-category">

      <Helmet>
        <title>CalidTech - FDT OT Security</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tata Communication Case Study" />
        <meta name="twitter:description" content="Discover how CalidTech delivers advanced Smart Energy Meters for Automatic Meter Reading (AMR). Our solutions streamline energy management and improve accuracy for efficient and reliable utility monitoring." />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />  

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://www.calidtech.com/ManufacturingOTSecurityCategoryPage" />
      </Helmet>


      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">OT Security</div>
      <div className="container-manufacturing mt-5">
        <Slider {...settings}>
          <div>
            <img
              src={heroprod}
              alt="Slide 1"
              style={{ width: "100%", display: "block" }}
            />
          </div>
          <div>
            <img
              src={heroprod1}
              alt="Slide 2"
              style={{ width: "100%", display: "block" }}
            />
          </div>
        </Slider>
        <br></br>
        <br></br>
        <h1 className="manufacturing-category-heading">
          <div class="main-heading-entire-site">
            OT Security
          </div>
        </h1>
        <p className="manufacturing-category-information">
          By implementing robust OT security measures, businesses can safeguard
          their operations, ensure the integrity of their systems, and maintain
          continuous production. Palo alto, Zscaler, and Cisco are leading providers
          of OT security solutions, offering a comprehensive range of products
          designed to meet the unique security needs of industrial environments.
        </p>
        <h2 className="manufacturing-subcategory-heading">
          <div class="main-heading-entire-site">Subcategories</div>
        </h2>
        <SubCategory subcategories={subcategories} />
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingOTSecurityCategoryPage;

import React from "react";
import { Link } from "react-router-dom";
import "./EnterpriseLandingPageCaseStudies.css";
const EnterpriseLandingPageCaseStudies = () => {
  return (
    <div className="LandingPageCaseStudiesDiv">
      <div className="custom-category-name">
        <div className="main-heading-entire-site">Recent Case studies</div>
      </div>{" "}
      <br />
      <div className="custom-card-category-1">
        <div className="custom-basic-card custom-basic-card-dark">
          <div className="custom-card-content">
            <span className="custom-card-title">ICICI Bank</span>
            <p className="custom-card-text">
              The core requirement of this project was to design, architect and
              deploy a stable and most importantly secure wireless network for
              the....
            </p>
          </div>
          <div className="custom-card-link">
            <a title="Read Full">
              <span>
                <Link to="/IciciCaseStudyPage">Read Full</Link>
              </span>
            </a>
          </div>
        </div>

        <div className="custom-basic-card  custom-basic-card-dark">
          <div className="custom-card-content">
            <span className="custom-card-title">
              Karkinos Healthcare Pvt Ltd
            </span>
            <p className="custom-card-text">
              Study the requirement and design the access network architecture
              and access methods on the wired and wireless network...
            </p>
          </div>
          <div className="custom-card-link">
            <a title="Read Full">
              <span>
                <Link to="/KarkinosCaseStudyPage">Read Full</Link>
              </span>
            </a>
          </div>
        </div>
        <div className="custom-basic-card  custom-basic-card-dark">
          <div className="custom-card-content">
            <span className="custom-card-title">Edelweiss Financial</span>
            <p className="custom-card-text">
              Edelweiss was rapidly growing and it felt a dire need to
              consolidate all its scattered efforts into one centralised entity
              that could govern and extend
            </p>
          </div>
          <div className="custom-card-link">
            <a title="Read Full">
              <span>
                <Link to="/EdelweissCaseStudyPage">Read Full</Link>
              </span>
            </a>
          </div>
        </div>
        <div className="custom-basic-card custom-basic-card-dark">
          <div className="custom-card-content">
            <span className="custom-card-title">
              Axxela Research and Analytics
            </span>
            <p className="custom-card-text">
              Analyse the requirement in detail – How to establish the
              connectivity amongst HQ, RO and DC and Traders network
            </p>
          </div>
          <div className="custom-card-link">
            <a title="Read Full">
              <span>
                <Link to="/AxxelaCaseStudyPage">Read Full</Link>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseLandingPageCaseStudies;

import React from "react";
import "./EntDividingIcons.css";
import FixedWirelessBroadband from "./assets/images/FixedWirelessBroadband.png";
import NetworkSecurity from "./assets/images/NetworkSecurity.png";
import EnterpriseLANandWireless from "./assets/images/EnterpriseLANandWireless.png";
import ServerandStorage from "./assets/images/ServerandStorage.png";
import { Link } from "react-router-dom";

const EntDividingIconsMirror = () => {
  return (
    <div className="DividingIcons">
      <div className="icons-Enterprise">
        <a href="#">
          <div className="DividingIcons-btn color-telegram">
            <Link to="./EnterpriseLANAndWirelessCategoryPage">
              <p className="order-1 google-font margin-instagram">
                Enterprise LAN and Wireless
              </p>
            </Link>
            <img src={EnterpriseLANandWireless} alt="WhatsApp" />
          </div>
        </a>

        <a href="#">
          <div className="DividingIcons-btn color-telegram">
            <Link to="./EnterpriseServerAndStorageSolutionCategoryPage">
              <p className="order-1 google-font margin-instagram">
                Server and Storage
              </p>
            </Link>
            <img src={ServerandStorage} alt="WhatsApp" />
          </div>
        </a>

        <a href="#">
          <div className="DividingIcons-btn color-telegram">
            <Link to="./EnterpriseNetworkAndCybersecurityCategoryPage">
              <p className="order-1 google-font margin-instagram">
                Network Security
              </p>
            </Link>
            <img src={NetworkSecurity} alt="Instagram" />
          </div>
        </a>

        <a href="#">
          <div className="DividingIcons-btn color-telegram">
            <Link to="./EnterprisePTPAndPtMPUBRCategoryPage">
              <p className="order-1 google-font margin-telgram">
                Fixed Wireless Broadband
              </p>
            </Link>
            <img src={FixedWirelessBroadband} alt="Telegram" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default EntDividingIconsMirror;

import React from "react";
import Axxela from "../../EntertrpriseComponents/EnterpriseCaseStudyComponents/Axxela";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import Whatsapp from "../../../Common Components/Whatsapp";
import { Helmet } from 'react-helmet';


function AxxelaCaseStudyPage() {
  return (
    <div>
      <Helmet>
                <title>Calidtech-Axxela CaseStudy</title>
                <meta name="description" content="Discover how Axxela Research and Analytics leveraged Cisco CBS350-24T, Cambium E510, Cisco C9300-48T-E, and Cisco C9200-24T-A to enhance their network infrastructure in our latest case study." />
                <meta name="keywords" content="Axxela CaseStudy Page,Cisco C9200-24T-A,Cisco C9300-48T-E,Cambium E510,Cisco CBS350-24T" />
                <meta name="author" content="Calid Technologies" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Axxela CaseStudy" />
                <meta property="og:description" content="Learn how Axxela Research and Analytics improved their network with Cisco CBS350-24T, Cambium E510, Cisco C9300-48T-E, and Cisco C9200-24T-A." />
                <meta property="og:image" content="public\favicon.ico" />
                <meta property="og:url" content="https://calidtech.com/AxxelaCaseStudyPage" />
                <meta property="og:site_name" content="Calid Technologies" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Learn how Axxela Research and Analytics improved their network with Cisco CBS350-24T, Cambium E510, Cisco C9300-48T-E, and Cisco C9200-24T-A." />
                <meta name="twitter:image" content="public\favicon.ico" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Axxela Research and Analytics Case Study" />
                <meta itemprop="description" content="Learn how Axxela Research and Analytics improved their network with Cisco CBS350-24T, Cambium E510, Cisco C9300-48T-E, and Cisco C9200-24T-A." />
                <meta itemprop="image" content="public\favicon.ico" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Axxela Case Study" />
                <meta name="application-name" content="Axxela Case Study" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="public\favicon.ico" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/AxxelaCaseStudyPage" />
            </Helmet>
      <EnterpriseHeader />
      <Axxela />
      <EnterpriseHeader />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
}

export default AxxelaCaseStudyPage;

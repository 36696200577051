import React from "react";
import "./ProductMain.css";
import FactoryDigitalTransformationCalidTech from "../../assets/pdf/FactoryDigitalTransformation_CalidTech.pdf";

function ProductmainMenu() {
  return (
    <header className="manu-header">
      <div className="service-header-text">
        <h1>
          Cutting Edge Solution
          <br />
          <span id="service-photography"></span>
        </h1>
        <p>
          Our product page offers a comprehensive range of industrial edge
          connectivity, communication, computing, analytics, and OT security
          solutions Explore our robust portfolio designed to optimize industrial
          operations and enhance cybersecurity across diverse sectors.
        </p>
        <a
          href={FactoryDigitalTransformationCalidTech}
          download="FactoryDigitalTransformation_CalidTech.pdf"
          className="prodbut center Downloadpdf"
        >
          Get PDF
        </a>
      </div>
    </header>
  );
}

function Productmainmanu() {
  return (
    <div className="ServiceApp">
      <ProductmainMenu />
    </div>
  );
}

export default Productmainmanu;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import ManufacturingCaseStudyCard from "../ManufacturingComponents/ManufacturingCaseStudyCard";
import ManufacturingHeader from "../ManufacturingComponents/ManufacturingHeader";
import SwitchtoEnterprise from "../ManufacturingComponents/SwitchtoEnterprise";
import ManufacturingFooter from "../ManufacturingComponents/ManufacturingFooter";
import Whatsapp from "../../Common Components/Whatsapp";
import TataCommunications from "../../assets/images/TataCommunications.jpg";

const ManufacturingCaseStudyPage = () => {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoaded(true);
  }, []);

  const caseStudies = [
    {
      title: "Tata Communications",
      description:
        "Calid tech is providing Smart meter technologies to Tata communications including the meter hardware and related software and support required to deliver the services to Tata Communications end customer - Wave Smart City Group(‘Wave Group’) for their smart meters requirement.",
      image: TataCommunications,
      link: "/TataCommunicationsCaseStudyPage",
    },
  ];

  const handleCardClick = (link) => {
    navigate(link);
  };

  return (
    <div className="casestudy" style={{}}>

      <Helmet>
        <title>CalidTech - Case Study</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        {/* <meta property="fb:app_id" content="Your Facebook App ID" /> */}

        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        {/* <meta name="twitter:title" content="Calid Technologies" /> */}
        {/* <meta name="twitter:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." /> */}
        {/* <meta name="twitter:image" content="https://calidtech.com/twitter-image.jpg" /> */}
        {/* <meta name="twitter:site" content="@CalidTech" /> */}
        {/* <meta name="twitter:creator" content="@CalidTech" /> */}

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        {/* <link rel="icon" href={favicon} /> */}
        {/* <link rel="apple-touch-icon" href="/apple-touch-icon.png" /> */}
        {/* <link rel="manifest" href={manifest} /> */}
        <link rel="canonical" href="https://calidtech.com/ManufacturingCaseStudyPage" />
      </Helmet>




      {/* <Hero /> */}
      {/* <div
        className="ImageBackgroundWrap Background-fixed"
        style={{
          backgroundImage: `url(${CaseStudyIndividualPage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="ImageInnerWrap" id="CaseStudyPageInnerDiv"></div>
      </div> */}

      {/* case study page start */}
      <div className={`case-study-page ${loaded ? "loaded" : ""}`}>
        <div className="headline" id="CaseStudyDv">
          <h1>Case Studies</h1>
          <p>Explore our latest case studies</p>
        </div>
        <div className="case-study-cards">
          {caseStudies.map((study, index) => (
            <ManufacturingCaseStudyCard
              key={index}
              title={study.title}
              description={study.description}
              image={study.image}
              onClick={() => handleCardClick(study.link)}
            />
          ))}
        </div>
      </div>
      <ManufacturingHeader />
      <Whatsapp />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingCaseStudyPage;

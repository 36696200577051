// EnterpriseDellSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseDellSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import PowerEdgeT150 from "../../../assets/pdf/PowerEdge-T150.pdf";
import PowerEdgeR650XS from "../../../assets/pdf/PowerEdge-R650XS.pdf";
import PowerEdgeR750XS from "../../../assets/pdf/PowerEdge-R750XS.pdf";
import PowerEdgeT440 from "../../../assets/pdf/PowerEdge-T440.pdf";
import PowerStore500T from "../../../assets/pdf/PowerStore-500T.pdf";
import PowerStore1200T from "../../../assets/pdf/PowerStore-1200T.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseDellSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  const productDataList1 = [
    //PowerEdge T150
    {
      images: [
        require("../../../assets/productImages/PowerEdge-T150-1.png"),
        require("../../../assets/productImages/PowerEdge-T150-2.png"),
        require("../../../assets/productImages/PowerEdge-T150-3.png"),
        require("../../../assets/productImages/PowerEdge-T150-4.png"),
      ],
      title: "PowerEdge T150",
      about:
        "The PowerEdge T150, powered by the Intel® Xeon® E-2300 processors, is an entry level tower server with an ideal balance of adaptability and affordability.",
      enterpriseFeature: (
        <>
          <li>
            Ideal for file/print, mail/messaging, point of sale, web hosting and
            collaboration.
          </li>
          <li>Offers enhanced thermal efficiency.</li>
        </>
      ),
      downloadLink: PowerEdgeT150,
      hyperlinks: [
        // { link: "https://example.com/case-study-1", name: "Case Study 1" },
      ],
    },

    //PowerEdge T440
    {
      images: [
        require("../../../assets/productImages/PowerEdge-T440.png"),
        require("../../../assets/productImages/PowerEdge-T440.png"),
        require("../../../assets/productImages/PowerEdge-T440.png"),
        require("../../../assets/productImages/PowerEdge-T440.png"),
      ],
      title: "PowerEdge T440",
      about:
        "PowerEdge T440 tower server delivers powerful 2-socket performance, expandability and quiet operation to your small-to mid-sized business (SMB) and remote office/branch office (ROBO) sites.",
      enterpriseFeature: (
        <>
          <li>
            Drive application response times faster with 33% more capacity for
            data-in-memory.
          </li>
          <li>Rely on PowerEdge with integrated security.</li>
        </>
      ),
      downloadLink: PowerEdgeT440,
      hyperlinks: [],
    },

    //PowerEdge R650XS
    {
      images: [
        require("../../../assets/productImages/PowerEdge-R650XS-1.png"),
        require("../../../assets/productImages/PowerEdge-R650XS-2.png"),
        require("../../../assets/productImages/PowerEdge-R650XS-3.png"),
        require("../../../assets/productImages/PowerEdge-R650XS-4.png"),
      ],
      title: "PowerEdge R650XS",
      about:
        "The Dell EMC PowerEdge R650xs, with 3rd generation Intel® Xeon® Scalable processors, designed to meet a specific set of requirements for medium-duty workloads with enterprise-class performance.",
      enterpriseFeature: (
        <>
          <li>
            Full-stack management integration with Microsoft, VMware,
            ServiceNow, Ansible and many other tools for multiple operating
            environments, from on-premises to cloud to edge.
          </li>
        </>
      ),
      downloadLink: PowerEdgeR650XS,
      hyperlinks: [],
    },

    //PowerEdge R750XS
    {
      images: [
        require("../../../assets/productImages/PowerEdge-R750XS-1.png"),
        require("../../../assets/productImages/PowerEdge-R750XS-2.png"),
        require("../../../assets/productImages/PowerEdge-R750XS-3.png"),
        require("../../../assets/productImages/PowerEdge-R750XS-4.png"),
      ],
      title: "PowerEdge R750XS",
      about:
        "The Dell EMC PowerEdge R750xs, with 3rd generation Intel® Xeon® Scalable processors, is purposefully designed with distinctive, , customer-defined requirements.",
      enterpriseFeature: (
        <>
          <li>
            Full-stack management integration with Microsoft, VMware,
            ServiceNow, Ansible and many other tools for on-premises, edge and
            cloud environments.
          </li>
        </>
      ),
      downloadLink: PowerEdgeR750XS,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //powerstore 500T
    {
      images: [
        require("../../../assets/productImages/PowerStore-500T.png"),
        require("../../../assets/productImages/PowerStore-500T.png"),
        require("../../../assets/productImages/PowerStore-500T.png"),
        require("../../../assets/productImages/PowerStore-500T.png"),
      ],
      title: "PowerStore 500T",
      about:
        "The ground-breaking Dell PowerStore enterprise storage appliance helps you achieve new levels of operational agility with advanced storage technologies.",
      enterpriseFeature: (
        <>
          <li>Connectivity.</li>
          <li>Back-end (Drive) connectivity.</li>
          <li>OE protocols and software facilities.</li>
        </>
      ),
      downloadLink: PowerStore500T,
      hyperlinks: [],
    },

    //powerstore 1200T
    {
      images: [
        require("../../../assets/productImages/PowerStore-1200T.png"),
        require("../../../assets/productImages/PowerStore-1200T.png"),
        require("../../../assets/productImages/PowerStore-1200T.png"),
        require("../../../assets/productImages/PowerStore-1200T.png"),
      ],
      title: "PowerStore 1200T",
      about:
        "The ground-breaking Dell PowerStore enterprise storage appliance helps you achieve new levels of operational agility  with advanced storage technologies.",
      enterpriseFeature: (
        <>
          <li>IPv6 certification .</li>
          <li>Native SHA2 certificate .</li>
          <li>Restriction of Hazardous Substances (RoHS) compliance.</li>
        </>
      ),
      downloadLink: PowerStore1200T,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="dell-enterprise-page">
      <Helmet>
                <title>CalidTech-Enterprise Dell</title>
                <meta name="description" content="Your website description here" />
                <meta name="keywords" content="keyword1, keyword2, keyword3" />
                <meta name="author" content="Your Name or Company" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="Your website description here" />
                <meta property="og:image" content="URL to your image" />
                <meta property="og:url" content="https://www.yourwebsite.com" />
                <meta property="og:site_name" content="Your Website Name" />
                <meta property="fb:app_id" content="Your Facebook App ID" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="Your website description here" />
                <meta name="twitter:image" content="URL to your image" />
                <meta name="twitter:site" content="@YourTwitterHandle" />
                <meta name="twitter:creator" content="@YourTwitterHandle" />

                <meta itemprop="name" content="Your Page Title" />
                <meta itemprop="description" content="Your website description here" />
                <meta itemprop="image" content="URL to your image" />

                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Your App Title" />
                <meta name="application-name" content="Your App Title" />

                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="URL to your tile image" />
                <meta name="theme-color" content="#ffffff" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="canonical" href="https://calidtech.com/EnterpriseDellSubcategoryPage" />
            </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        Server and Storage Solution - Dell
      </div>
      <div className="dell-enterprise-subproduct-nav">
        <button
          className={`dell-enterprise-subproduct-nav-button ${
            currentSubproduct === 1 ? "active-dell-enterprise-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Tower and Rackmount Servers
        </button>
        <button
          className={`dell-enterprise-subproduct-nav-button ${
            currentSubproduct === 2 ? "active-dell-enterprise-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(2)}
        >
          Storage
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="dell-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="dell-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-dell-enterprise" : ""
                  }
                >
                  <button
                    className="dell-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseDellSubcategoryPage;
